import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useHistory } from 'react-router';
import { Container } from './styles';
import { schema } from './utils';
import { useSession } from '../../context/SessionContext';
import getValidationErrors from '../../utils/validators/getValidationErrors';
import Title from '../../components/ui/Title';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import Link from '../../components/ui/Link/index';
import { auth } from '../../services/api/endpoints/auth/index';

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<any>(false);
  const { login } = useSession();
  const history = useHistory();

  const handleSubmit: SubmitHandler<FormData> = async formData => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await schema.validate(formData, {
        abortEarly: false,
      });

      const token = await auth(formData);
      login(token);

      history.push('/');
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log(err.response.status);
        toast.error('E-mail ou senha inválidos');
      } else {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <Title style={{ marginBottom: 0 }}>Painel do Colaborador</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="email"
          type="email"
          label="E-mail"
          placeholder="Informe seu e-mail"
        />
        <Input
          name="password"
          type="password"
          label="Senha"
          placeholder="Informe sua senha"
        />
        <Link
          to="/forgot-password"
          style={{ display: 'block', fontSize: '0.8rem', textAlign: 'right' }}
        >
          Esqueci minha senha
        </Link>
        <Button
          type="submit"
          block
          style={{ marginBottom: '1rem' }}
          loading={loading}
        >
          Entrar
        </Button>
      </Form>
      <div style={{ color: 'white' }}>Teste</div>
    </Container>
  );
};

export default Login;
