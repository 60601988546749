import api from '../..';
import { PAGE_LIMIT } from '../../../../utils/pagination';

const baseUrl = '/admin/trades';

export const getTrades = async (page = 1, filter = '') => {
  const { data } = await api.get(baseUrl, {
    params: {
      page,
      limit: PAGE_LIMIT,
      filter,
    },
  });
  return data;
};

export const refund = async (id: string) => {
  const { data } = await api.post(`/admin/orders/${id}/refund`);
  return data;
};

export const finishOrderStatus = async (id: string | number) => {
  const { data } = await api.post(`/admin/trades/${id}/finish`);
  return data;
};
