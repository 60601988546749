import api from '../../index';

export const fileUpload = async (
  type: 'image' | 'file',
  file: File,
  name?: string,
  trackUpload?: (progress: number, total: number) => void,
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append(type, file);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    let options;
    if (trackUpload) {
      options = {
        onUploadProgress: progressEvent =>
          trackUpload(progressEvent.loaded, progressEvent.total),
      };
    }

    const { data } = await api.post(`/files/upload/${type}`, formData, {
      headers,
      ...options,
      params: {
        name,
      },
    });

    return data;
  } catch (err) {
    throw new Error(`Failed to upload ${type}`);
  }
};
