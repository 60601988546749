/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Container = styled.div``;

interface IStyledInput {
  [string: string]: any;
}

export const InputLabel = styled.label`
  width: 50px;
  height: 25px;
  display: inline-block;
  position: relative;
  cursor: pointer;
`;


export const Slider = styled.div`
  background: ${({ theme }) => theme.gray.light};
  width: 100%;
  height: 100%;
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;

  &:before{
    content: '';
    position: absolute;
    transition: .2s;
    height: 95%;
    width: 49%;
    top: 2.5%;
    left: 2.5%;
    border-radius: 100%;
    background: ${({ theme }) => theme.white};
  }
`;

export const StyledInput = styled.input<IStyledInput>`
 -webkit-appearance: none;
 border: 0;
 width: 0;
 height: 0;

  &:disabled{
    cursor: not-allowed;
  }

  &:checked + ${Slider} {
    background: ${({ theme }) => theme.primary.base};

    &:before{
      left: 47%;
    }
  }
`;


interface ILabel {
  htmlFor: string;
}

export const Label = styled.span<ILabel>`
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.9rem;
  display: block;
  margin-bottom: .5rem;
`;



