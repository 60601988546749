import React from 'react';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Divisor from '../../components/ui/Divisor';
import Row from '../../components/ui/Row';

import Title from '../../components/ui/Title';
import AddressForm from './AddressForm';
import BankForm from './BankForm';

const style = {
  card: {
    height: '100%',
  },
};

const Settings: React.FC = () => {
  return (
    <Container>
      <Row colGap={16} rowGap={32}>
        <Col xs={12}>
          <Title>Configurações Gerais</Title>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Endereço</Title>
            <Divisor />
            <AddressForm />
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Conta Bancária</Title>
            <Divisor />
            <BankForm />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
