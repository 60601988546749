/* eslint-disable no-param-reassign */
import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { Container, Error, Label, StyledSelect, Wrapper } from './styles';
import IF from '../IF';

interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface IProps {
  name: string;
  label?: string;
  options: IOption[];
  onChange?: (e?: any) => void;
}

const Select: React.FC<IProps> = ({
  name,
  label,
  options,
  onChange,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleChange = (e: any) => {
    if (onChange) onChange(e.target.value);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <Wrapper htmlFor={fieldName}>
        <StyledSelect
          defaultValue={defaultValue}
          ref={selectRef}
          classNamePrefix="react-select"
          onChange={handleChange}
          {...rest}
        >
          {options?.map(item => (
            <option
              key={item.value}
              value={item.value}
              disabled={item.disabled}
            >
              {item.label}
            </option>
          ))}
        </StyledSelect>
      </Wrapper>
      <IF condition={!!error}>
        <Error>{error}</Error>
      </IF>
      <IF condition={!error}>
        <Error>
          <span>.</span>
        </Error>
      </IF>
    </Container>
  );
};

export default Select;
