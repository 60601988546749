/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Select from '../../components/ui/Select';
import Table from '../../components/ui/Table';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Paragraph from '../../components/ui/Paragraph';
import Paginate from '../../components/ui/Paginate';
import {
  finishOrderStatus,
  getTrades,
  refund,
} from '../../services/api/endpoints/trades';
import { formatPrice } from '../../utils/format';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import useDebounce from '../../hooks/useDebounce';
import { getCsv } from '../../services/api/endpoints/exportCsv';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';

const options = [
  {
    value: 'Em análise',
    label: 'Em análise',
  },
  {
    value: 'Entregue',
    label: 'Entregue',
  },
  {
    value: 'Em trânsito',
    label: 'Em trânsito',
  },
];

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');
  const searchRef = useRef<FormHandles>(null);

  const handleFilter = () => {
    console.log('');
  };

  const openChecklist = (url: string) => {
    if (!url) {
      toast.warning('Não há documento para ser aberto', {
        position: 'top-center',
      });
      return;
    }

    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('target', '_blank');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleSubmit = (param: string | number) => {
    console.log(param);
  };

  const cancelOrder = async (id: string) => {
    try {
      const cancel = window.confirm('Deseja efetuar o reembolso?');
      if (!cancel) {
        return;
      }
      await refund(id);
      toast.success(SUCCESS_MESSAGE);
      getData(page, filter);
    } catch (err) {
      if (err?.response?.data?.message) {
        toast.error(err?.response?.data?.message);
      }
      console.log(err);
    }
  };

  const createRender = (url: string) => {
    return (
      <Paragraph primary type="link" onClick={() => openChecklist(url)}>
        Abrir
      </Paragraph>
    );
  };

  const renderCancel = (id: string) => {
    return (
      <Paragraph danger type="link" onClick={() => cancelOrder(id)}>
        Reembolsar
      </Paragraph>
    );
  };

  const renderFinish = (id: string) => {
    return (
      <Paragraph danger type="link" onClick={() => finishOrder(id)}>
        Finalizar
      </Paragraph>
    );
  };

  // const renderStatus = (id: string | number) => {
  //   return (
  //     <Form onSubmit={handleSubmit}>
  //       <Select options={options} name="status" />
  //     </Form>
  //   );
  // };

  const finishOrder = async (id: string | number) => {
    try {
      const cancel = window.confirm('O pedido foi entregue?');
      if (!cancel) {
        return;
      }
      await finishOrderStatus(id);
      toast.success(SUCCESS_MESSAGE);
      getData(page, filter);
    } catch (err) {
      console.log(err);
    }
  };

  const renderFinishButton = (id: string | number) => {
    return (
      <Button
        onClick={() => {
          finishOrder(id);
        }}
      >
        Finalizar
      </Button>
    );
  };

  const handlePage = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const getData = async (p: number, f: string) => {
    try {
      const { data, pagination } = await getTrades(p, f);
      setTotalPage(pagination?.totalPages);

      data.map((item: any) => {
        item.id = item.bidtype;
        item.data = {
          idBid: {
            id: 0,
            label: item.idBid,
          },
          ownerId: {
            id: 1,
            label: item.ownerName,
          },
          buyerId: {
            id: 2,
            label: item.buyerName,
          },
          skuId: {
            id: 3,
            label: item.productName,
          },
          status: {
            id: 4,
            label: item.status,
          },
          date: {
            id: 5,
            label: item.date,
          },
          value: {
            id: 6,
            label: formatPrice(item.value, 2),
          },
          checklist: {
            id: 7,
            render: createRender(item?.checklist),
          },
          shipping1: {
            id: 8,
            render: item?.trackingCode || '-',
          },
          shipping2: {
            id: 9,
            render: item?.secondTrackingCode || '-',
          },
          techReport: {
            id: 10,
            render: createRender(item?.report),
          },
          finish: {
            id: 10,
            render:
              item?.status === 'Aprovado na certificação'
                ? renderFinish(item?.uuid)
                : '-',
          },
          cancel: {
            id: 10,
            render:
              item.status === 'Reembolsado' ? (
                <p>-</p>
              ) : (
                renderCancel(item?.uuid)
              ),
          },
        };
        return true;
      });

      setOrders(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData(page, filter);
  }, [page, filter]);

  const getFilteredData = (value: string) => {
    setFilter(value);
    setPage(1);
  };

  const debouncedFilter = useDebounce(getFilteredData, 500);

  const handleSearch = (value: any) => {
    debouncedFilter(value);
  };

  const [loadingCsv, setLoadingCsv] = useState<boolean>(false);

  const exportData = async () => {
    try {
      setLoadingCsv(true);
      const data = await getCsv('bids');
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      const csvURL = window.URL.createObjectURL(blob);
      window.open(csvURL);
    } catch (err) {
      toast.error(ERROR_MESSAGE);
    }
    setLoadingCsv(false);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12} md={8} lg={10}>
          <Title>Negociações</Title>
        </Col>
        <Col xs={12} md={4} lg={2}>
          <Button onClick={exportData} loading={loadingCsv}>
            Exportar CSV
          </Button>
        </Col>
        <Col xs={12}>
          <Card>
            <Form onSubmit={handleFilter} ref={searchRef}>
              <Input
                placeholder="Pesquise pelo id da negociação"
                name="search"
                onChange={handleSearch}
              />
            </Form>
            <Table header={header} body={orders} />
            <Paginate
              pageCount={totalPage}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              onPageChange={handlePage}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
