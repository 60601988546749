import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import Paragraph from '../../../components/ui/Paragraph';
import SingleUpload from '../../../components/ui/SingleUpload';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';
import {
  getTransaction,
  ITransactionType,
  updateTransaction,
} from '../../../services/api/endpoints/transactionSteps';
import { theme } from '../../../styles/theme';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

interface IProps {
  type: ITransactionType;
}

const TutorialForm: React.FC<IProps> = ({ type }) => {
  const formRef = useRef<FormHandles>(null);
  const [steps, setSteps] = useState<string[]>([]);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const response = await getTransaction(type);
      setData(response);
      const stepsAux: string[] = [];
      response.steps.map(item => stepsAux.push(item.description));
      formRef.current.setData({
        image: response.imageUrl,
        title: response.title,
        steps: response.stepsAux,
      });

      formRef.current.setFieldValue('steps[0]', stepsAux[0]);
      stepsAux.shift();
      setSteps(stepsAux);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      const { image } = formData;
      let content: any = {
        title: formData.title,
        steps: formData.steps,
      };

      if (image) {
        const { path: url } = await fileUpload('image', formData.image);
        content = {
          ...content,
          imageUrl: url,
        };
      } else {
        content = {
          ...content,
          imageUrl: data.imageUrl,
        };
      }

      await updateTransaction(type, content);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleStep = (value: any, index: number) => {
    setSteps(steps.map((item, i) => (i === index ? value : item)));
  };

  const addStep = () => {
    setSteps([...steps, '']);
  };

  const removeStep = (index: number) => {
    setSteps(steps.filter((item, i) => i !== index));
  };

  useEffect(() => {
    steps.map((item, i) =>
      formRef.current.setFieldValue(`steps[${i + 1}]`, item),
    );
  }, [steps]);

  const renderButton = (index: number) => {
    return (
      <Paragraph
        style={{ cursor: 'pointer', color: theme.gray.dark }}
        onClick={() => {
          removeStep(index);
        }}
      >
        x
      </Paragraph>
    );
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <SingleUpload label="Imagem" name="image" accept=".png, .jpg, .jpeg" />
      <Input
        name="title"
        label="Título de exibição"
        placeholder="Informe o título de exibição"
      />

      <Input name="steps[0]" label="Passo 1" placeholder="Informe o passo 1" />
      {steps.map((item, i) => (
        <Input
          key={Math.random()}
          name={`steps[${i + 1}]`}
          label={`Passo ${i + 2}`}
          placeholder={`Informe o passo ${i + 2}`}
          suffix={renderButton(i)}
          onBlur={(value: any) => {
            handleStep(value, i);
          }}
          defaultValue={item}
        />
      ))}
      <Button type="button" ghost onClick={addStep}>
        Novo Passo
      </Button>
      <br />
      <Button type="submit" loading={loading}>
        Salvar
      </Button>
    </Form>
  );
};

export default TutorialForm;
