/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { CSSProperties } from 'styled-components';
import { Container, Label, StyledInput, InputLabel, Slider } from './styles';
import IF from '../IF';

interface Props {
  name: string;
  label?: string;
  onChange?: (e?: any) => void;
  style?: CSSProperties;
  value?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const Switch: React.FC<InputProps> = ({
  name,
  label,
  onChange,
  style,
  value,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);
  const defaultChecked = defaultValue === value;

  const handleChange = (e: any) => {
    if (onChange) onChange(e);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked;
      },
      clearValue: ref => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, v) => {
        ref.current.checked = v;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container style={style}>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <InputLabel>
        <StyledInput
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          type="checkbox"
          onChange={handleChange}
          {...rest}
        />
        <Slider />
      </InputLabel>
    </Container>
  );
};

export default Switch;
