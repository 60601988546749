import React, { useEffect, useState } from 'react';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Divisor from '../../components/ui/Divisor';
import Row from '../../components/ui/Row';

import Title from '../../components/ui/Title';
import { getTerms, ITermTypes } from '../../services/api/endpoints/terms';
import DocumentForm from './DocumentForm';

const Legal: React.FC = () => {
  const [condition, setConditions] = useState<string>();
  const [privacy, setPrivacy] = useState<string>();
  const [purchase, setPurchase] = useState<string>();
  const [sale, setSale] = useState<string>();

  const getData = async ({ type }: ITermTypes) => {
    try {
      const { file } = await getTerms({ type });
      switch (type) {
        case 'condition':
          setConditions(file);
          return;
        case 'privacy':
          setPrivacy(file);
          return;
        case 'purchase':
          setPurchase(file);
          return;
        case 'sale':
          setSale(file);
          return;
        default:
          return;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData({ type: 'condition' });
    getData({ type: 'privacy' });
    getData({ type: 'purchase' });
    getData({ type: 'sale' });
  }, []);

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title>Jurídico</Title>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card>
            <Title level={3}>Termos e Condições</Title>
            <Divisor />
            <DocumentForm type="condition" initialData={condition} />
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card>
            <Title level={3}>Políticas de Privacidade</Title>
            <Divisor />
            <DocumentForm type="privacy" initialData={privacy} />
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card>
            <Title level={3}>Termos de Compra</Title>
            <Divisor />
            <DocumentForm type="purchase" initialData={purchase} />
          </Card>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Card>
            <Title level={3}>Termos de Venda</Title>
            <Divisor />
            <DocumentForm type="sale" initialData={sale} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Legal;
