import React from 'react';
import { CSSProperties } from 'styled-components';
import { MdClose } from 'react-icons/md';
import IF from '../IF';

import { Container, Overlay, Content, CloseButton } from './styles';

interface IProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  closable?: boolean;
  children?: React.ReactNode;
  style?: {
    content?: CSSProperties;
  };
}

const Modal: React.FC<IProps> = ({
  visible,
  setVisible,
  children,
  closable = true,
  style,
}) => {
  const handleClose = () => {
    if (closable) setVisible(false);
  };
  return (
    <IF condition={visible}>
      <Container>
        <Overlay onClick={handleClose} />
        <Content
          initial={{ opacity: 0, scale: 0.75 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          style={style?.content}
        >
          <CloseButton onClick={handleClose}>
            <MdClose />
          </CloseButton>

          {children}
        </Content>
      </Container>
    </IF>
  );
};

export default Modal;
