import styled from 'styled-components';

const Divisor = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.gray.separator};
  margin: 1rem 0;
`;

export default Divisor;
