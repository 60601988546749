import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { FormHandles, SubmitHandler } from '@unform/core';
import { useHistory } from 'react-router';
import { Container } from './styles';
import { schema } from './utils';
import api from '../../services/api';
import getValidationErrors from '../../utils/validators/getValidationErrors';
import Title from '../../components/ui/Title';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';

const { search } = window.location;
const params = new URLSearchParams(search);
const token = params.get('token');

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<any>(false);
  const history = useHistory();

  const handleSubmit: SubmitHandler<FormData> = async (data: any) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/restaurant-admin/password/reset', {
        token,
        password: data.password,
        confirmPassword: data.confirmPassword,
      });

      history.push('/login');
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      const errors = getValidationErrors(err);
      formRef.current?.setErrors(errors);

      if (err?.response?.status) {
        console.log(err);
        toast.error(ERROR_MESSAGE);
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <Title style={{ marginBottom: 0 }}>Redefinir Senha</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="password"
          type="password"
          label="Senha"
          placeholder="Informe sua senha"
        />
        <Input
          name="confirmPassword"
          type="password"
          label="Confirmar Senha"
          placeholder="Confirme sua senha"
        />
        <Button
          loading={loading}
          type="submit"
          block
          style={{ marginBottom: '1rem' }}
        >
          Redefinir Senha
        </Button>
      </Form>
    </Container>
  );
};

export default ResetPassword;
