import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import InputMask from '../../../components/ui/InputMask';
import {
  getAdmin,
  updateAdmin,
} from '../../../services/api/endpoints/admin/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const UpdateAdminForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const response = await getAdmin();
      formRef.current.setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      await updateAdmin(formData);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      toast.error(ERROR_MESSAGE);
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        name="name"
        label="Nome Completo"
        placeholder="Informe o seu nome"
      />
      <Input name="email" label="Email" placeholder="Informe o seu email" />
      <InputMask
        name="cpf"
        label="CPF"
        placeholder="Informe o seu CPF"
        mask="999.999.999-99"
      />
      <Button loading={loading} type="submit">
        Atualizar
      </Button>
    </Form>
  );
};

export default UpdateAdminForm;
