import api from '../..';
import { PAGE_LIMIT } from '../../../../utils/pagination';

const baseUrl = { regular: '/admin/users', block: '/admin/block/users' };

export const getUsers = async (
  type: 'block' | 'regular',
  page = 1,
  filter = '',
) => {
  const { data } = await api.get(baseUrl[type], {
    params: {
      page,
      limit: PAGE_LIMIT,
      filter,
    },
  });
  return data;
};

export const setBlockUsers = async (uuid: string) => {
  const { data } = await api.post(`${baseUrl.regular}/${uuid}`);
  return data;
};

export const deleteUser = async (uuid: string) => {
  const { data } = await api.delete(`${baseUrl.regular}/${uuid}`);
  return data;
};

export const getUserAddress = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl.regular}/${uuid}/address`);
  return data;
};
