/* eslint-disable react/require-default-props */
import React, { useRef, useEffect } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';

import { useField } from '@unform/core';

import { Container, Error, Label } from './styles';
import IF from '../IF';

interface Props extends InputProps {
  name: string;
  label?: string;
}

export default function InputMask({ name, label, ...rest }: Props) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <ReactInputMask ref={inputRef} defaultValue={defaultValue} {...rest} />
      <IF condition={!!error}>
        <Error>{error}</Error>
      </IF>
      <IF condition={!error}>
        <Error>
          <span>.</span>
        </Error>
      </IF>
    </Container>
  );
}
