/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Card } from '../../../ui/Card';
import IF from '../../../ui/IF';
import Input from '../../../ui/Input';
import Paragraph from '../../../ui/Paragraph';
import RadioGroup from '../../../ui/RadioGroup';

const style = {
  card: {
    marginBottom: '1rem',
  },
  switch: {
    marginBottom: '1rem',
  },
  button: {
    marginBottom: '1rem',
    display: 'block' as const,
  },
  id: {
    height: 0,
    overflowY: 'hidden' as const,
  },
};

const options = [
  {
    value: 'max',
    label: 'Máximo',
  },
  {
    value: 'min',
    label: 'Mínimo',
  },
];

type IProps = {
  onDelete: (params?: any) => void;
  index: number;
  getDefaultValue?: () => void;
  initialRadioValue?: 'max' | 'min' | (() => any);
  hasId?: boolean;
};

const Param: React.FC<IProps> = ({
  onDelete,
  index,
  getDefaultValue,
  initialRadioValue,
  hasId = false,
}) => {
  const [type, setType] = useState('máximo');
  const handleDelete = () => {
    onDelete();
  };

  useEffect(() => {
    if (getDefaultValue) {
      getDefaultValue();
    }
  }, [getDefaultValue]);

  const handleType = (value: any) => {
    if (value === 'min') {
      setType('mínimo');
    } else {
      setType('máximo');
    }
  };

  useEffect(() => {
    if (initialRadioValue) {
      handleType(initialRadioValue);
    }
  }, [initialRadioValue]);

  return (
    <Card style={style.card}>
      <IF condition={hasId}>
        <div style={style.id}>
          <Input name={`quantityParams[${index}].id`} label="ID" disabled />
        </div>
      </IF>

      <Input
        name={`quantityParams[${index}].name`}
        label="Nome do Parâmetro"
        placeholder="Informe o nome do parâmetro"
      />

      <Input
        name={`quantityParams[${index}].description`}
        label="Descrição do Parâmetro"
        placeholder="Informe a descrição do parâmetro"
      />

      <Paragraph>Tipo:</Paragraph>
      <RadioGroup
        name={`quantityParams[${index}].type`}
        onChange={handleType}
        options={options}
        initialValue={initialRadioValue}
      />

      <Input
        name={`quantityParams[${index}].value`}
        label={`Valor ${type} aceito`}
        placeholder={`Informe o valor ${type} aceito`}
        type="number"
      />

      <Paragraph danger onClick={handleDelete} type="link">
        Remover Parâmetro
      </Paragraph>
    </Card>
  );
};

export default Param;
