/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Card } from '../../../ui/Card';
import Col from '../../../ui/Col';
import IF from '../../../ui/IF';
import Input from '../../../ui/Input';
import Paragraph from '../../../ui/Paragraph';
import Row from '../../../ui/Row';
import SingleUpload from '../../../ui/SingleUpload';

const style = {
  card: {
    marginBottom: '1rem',
  },
  switch: {
    marginBottom: '1rem',
  },
  button: {
    marginBottom: '1rem',
    display: 'block' as const,
  },
  id: {
    height: 0,
    overflowY: 'hidden' as const,
  },
};

type IProps = {
  onDelete: (params?: any) => void;
  index: number;
  getDefaultValue?: () => void;
  hasId?: boolean;
};

const Param: React.FC<IProps> = ({
  onDelete,
  index,
  getDefaultValue,
  hasId,
}) => {
  const handleDelete = () => {
    onDelete();
  };

  useEffect(() => {
    if (getDefaultValue) {
      getDefaultValue();
    }
  }, [getDefaultValue]);

  return (
    <Card style={style.card}>
      <IF condition={hasId}>
        <div style={style.id}>
          <Input name={`qualityParams[${index}].id`} label="ID" disabled />
        </div>
      </IF>

      <Input
        name={`qualityParams[${index}].name`}
        label="Nome do Parâmetro"
        placeholder="Informe o nome do parâmetro"
      />

      <Row colGap={16} rowGap={16}>
        <Col xs={6} md={3}>
          <SingleUpload name={`qualityParams[${index}].bad`} label="Ruim" />
        </Col>
        <Col xs={6} md={3}>
          <SingleUpload
            name={`qualityParams[${index}].reasonable`}
            label="Razoável"
          />
        </Col>
        <Col xs={6} md={3}>
          <SingleUpload name={`qualityParams[${index}].good`} label="Bom" />
        </Col>
        <Col xs={6} md={3}>
          <SingleUpload
            name={`qualityParams[${index}].excellent`}
            label="Excelente"
          />
        </Col>
      </Row>

      <Paragraph danger onClick={handleDelete} type="link">
        Remover Parâmetro
      </Paragraph>
    </Card>
  );
};

export default Param;
