/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Card } from '../../../ui/Card';
import IF from '../../../ui/IF';
import Input from '../../../ui/Input';
import Paragraph from '../../../ui/Paragraph';

const style = {
  card: {
    marginBottom: '1rem',
  },
  switch: {
    marginBottom: '1rem',
  },
  button: {
    marginBottom: '1rem',
    display: 'block' as const,
  },
  id: {
    height: 0,
    overflowY: 'hidden' as const,
  },
};

type IProps = {
  onDelete: (params?: any) => void;
  index: number;
  getDefaultValue?: () => void;
  hasId?: boolean;
};

const Param: React.FC<IProps> = ({
  onDelete,
  index,
  getDefaultValue,
  hasId = false,
}) => {
  const handleDelete = () => {
    onDelete();
  };

  useEffect(() => {
    if (getDefaultValue) {
      getDefaultValue();
    }
  }, [getDefaultValue]);

  return (
    <Card style={style.card}>
      <IF condition={hasId}>
        <div style={style.id}>
          <Input name={`functionalParams[${index}].id`} label="ID" disabled />
        </div>
      </IF>

      <Input
        name={`functionalParams[${index}].name`}
        label="Funcionalidade"
        placeholder="Informe a funcionalidade"
      />

      <Paragraph danger onClick={handleDelete} type="link">
        Remover Parâmetro
      </Paragraph>
    </Card>
  );
};

export default Param;
