import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: block;
  background: ${({ theme }) => theme.gray.extraLight};
  color: ${({ theme }) => theme.gray.dark};
  border-radius: 3px;
  margin-bottom: 1rem;
  transition: 0;
  width: 100%;
  border: 1px solid transparent;

  div.DraftEditor-root {
    height: 100px;
    overflow-y: auto;
    padding: 10px 0;
    cursor: text;
  }
`;
export const Controls = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  padding: 2px 0;
  border-bottom: 1px solid ${({ theme }) => theme.gray.light};
`;

interface IButton {
  active: boolean;
}

export const ControlButton = styled.div<IButton>`
  cursor: pointer;
  color: ${({ theme }) => theme.gray.mediumDark};
  padding: 0 5px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.gray.light};
    `}
`;
