/* eslint-disable prettier/prettier */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Table from '../../components/ui/Table/index';
import Paginate from '../../components/ui/Paginate';
import Input from '../../components/ui/Input';
import { theme } from '../../styles/theme';
import Button from '../../components/ui/Button';
import Paragraph from '../../components/ui/Paragraph';
import { deleteChecklist, getChecklists } from '../../services/api/endpoints/checklist';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';
import useDebounce from '../../hooks/useDebounce';
import { PAGE_LIMIT } from '../../utils/pagination';
import Link from '../../components/ui/Link';

const style = {
  link: {
    color: theme.primary.base,
    cursor: 'pointer' as const,
  },
  title: {
    marginBottom: '.5rem',
  },
  routerLink: {
    display: 'inline-block' as const
  }
};

const Checklist: React.FC = () => {
  const [checklists, setChecklists] = useState<any[]>([]);
  const searchRef = useRef<FormHandles>(null);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');

  const getData = async (p: number, f: string) => {
    const { data, pagination } = await getChecklists({ page:p, filter: f, limit: PAGE_LIMIT });

    setTotalPage(pagination?.totalPages);
    data.map(item => {
      item.data = {
        delete: { render: createRender(item.uuid) },
        update: { render: renderUpdate(item.uuid) },
        name: {
          label: item.name,
        },
      }
      return true;
    });
    setChecklists(data);
  }

  const handleFilter = () => {
    console.log('');
  };

  const handlePage = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const createRender = (uuid: string) => {
    return (
      <Paragraph danger type="link" onClick={() => { handleDelete(uuid) }}>
        Excluir
      </Paragraph>
    );
  };

  const renderUpdate = (uuid: string) => {
    return (
      <Link style={style.link} to={`/checklists/edit/${uuid}`}>
        Editar
      </Link>
    );
  };

  const handleDelete = async (uuid: string) => {
    try {
      await deleteChecklist(uuid);
      getData(page, filter);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  }

  useEffect(() => {
    getData(page, filter);
  }, [page, filter]);

  const getFilteredData = (value: string) => {
    setFilter(value);
    setPage(1);
  };

  const debouncedFilter = useDebounce(getFilteredData, 500);

  const handleSearch = (value: any) => {
    debouncedFilter(value);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title style={style.title}>Checklists</Title>
          <RouterLink to="/checklists/new" style={style.routerLink}>
            <Button primary>Novo Checklist</Button>
          </RouterLink>
        </Col>
        <Col xs={12}>
          <Card>
            <Form onSubmit={handleFilter} ref={searchRef}>
              <Input
                placeholder="Buscar por nome"
                name="name"
                onChange={handleSearch}
              />
            </Form>
            <Table header={header} body={checklists} />
            <Paginate
              pageCount={totalPage}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              onPageChange={handlePage}
            />
          </Card>
        </Col>

      </Row>
    </Container>
  );
};

export default Checklist;
