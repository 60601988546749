import React, { useEffect, useState } from 'react';
import { getAttributeItems } from '../../../services/api/endpoints/attributes';
import Col from '../../ui/Col';
import Row from '../../ui/Row';
import NewSKU from './NewSKU/index';
import SKUList from './SKUList';
import { getSkus } from '../../../services/api/endpoints/skus/index';
import IF from '../../ui/IF';

interface IProps {
  attributes: any[];
  uuid: string;
}

const ProductSkus: React.FC<IProps> = ({ attributes, uuid }) => {
  const [newSku, setNewSku] = useState<boolean>(false);
  const [skus, setSkus] = useState<any[]>([false]);
  const getSkusData = async () => {
    try {
      const response = await getSkus(uuid);
      setSkus(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSkusData();
  }, []);

  const getData = async (id: string) => {
    try {
      const response = await getAttributeItems(id);
      return response.items;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (attributes) {
      const getItems = async () => {
        const promise = attributes.map(async item => {
          const items = await getData(item.uuid);
          item.items = items.map(attr => ({
            label: attr.name,
            value: attr.uuid,
          }));
          return true;
        });
        await Promise.all(promise);
        setNewSku(true);
      };
      getItems();
    }
  }, [attributes]);

  return (
    <Row colGap={16} rowGap={16}>
      <Col xs={12} sm={6} md={4}>
        <IF condition={newSku}>
          <NewSKU attributes={attributes} uuid={uuid} getData={getSkusData} />
        </IF>
      </Col>
      <Col xs={12} sm={6} md={8}>
        <SKUList skus={skus} attributes={attributes} getData={getSkusData} />
      </Col>
    </Row>
  );
};

export default ProductSkus;
