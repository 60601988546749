/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { CSSProperties } from 'styled-components';
import { AiOutlinePaperClip } from 'react-icons/ai';
import {
  Container,
  Error,
  Label,
  StyledInput,
  InputLabel,
  Loading,
  Filename,
} from './styles';
import IF from '../IF';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';
import Paragraph from '../Paragraph';

interface Props {
  name: string;
  label?: string;
  accept?: string;
  uploadOnChange?: boolean;
  onUpload?: (path: string) => void;
  uploadType?: 'file' | 'image';
  style?: {
    input?: CSSProperties;
    label?: CSSProperties;
  };
}

interface ILoading {
  status: boolean;
  progress: number;
  error?: boolean;
  type?: 'image' | 'file';
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const SingleUpload: React.FC<InputProps> = ({
  name,
  label,
  style,
  accept = '*',
  uploadOnChange = false,
  type = 'image',
  uploadType = 'image',
  onUpload,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [filename, setFilename] = useState<string>(defaultValue);
  const [loading, setLoading] = useState<ILoading>(null);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      setPreview(null);
    }
    if (uploadOnChange) {
      handleUpload(file);
    }
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setFilename(file?.name);
      setPreview(previewURL);
    }
  }, []);

  const trackUpload = (progress: number, total: number) => {
    setLoading({
      progress: (progress * 100) / total,
      status: true,
      error: false,
    });
  };

  const handleUpload = async (file: any) => {
    try {
      const { path } = await fileUpload(uploadType, file, '', trackUpload);
      if (onUpload) {
        onUpload(path);
      }
    } catch (err) {
      console.log(err);

      setLoading({
        progress: 0,
        status: false,
        error: true,
      });
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref: HTMLInputElement) {
        ref.value = '';
        setPreview(null);
      },
      setValue(_: HTMLInputElement, value: string) {
        setPreview(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <InputLabel
        className={error || loading?.error ? 'error' : ''}
        type={type}
        style={{
          ...style?.label,
          backgroundImage: type === 'image' && `url('${preview}')`,
        }}
      >
        <StyledInput
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          style={style?.input}
          type="file"
          accept={accept}
          onChange={handlePreview}
          {...rest}
        />
        <IF condition={!preview}>+</IF>
        <IF condition={uploadType === 'file' && !!preview && !!defaultValue}>
          <Filename
            style={{
              overflow: 'hidden',
              padding: 5,
              width: '100%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              margin: 0,
              display: 'block',
            }}
          >
            {defaultValue}
          </Filename>
        </IF>
        <IF condition={!!loading?.status}>
          <Loading style={{ width: `${loading?.progress}%` }} />
        </IF>
      </InputLabel>

      <IF condition={!!error}>
        <Error>{error}</Error>
      </IF>
      <IF condition={!error}>
        <Error>
          <span>.</span>
        </Error>
      </IF>
    </Container>
  );
};

export default SingleUpload;
