import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import { FormHandles, SubmitHandler } from '@unform/core';
import { Container } from './styles';
import { schema } from './utils';
import api from '../../services/api';
import getValidationErrors from '../../utils/validators/getValidationErrors';
import Title from '../../components/ui/Title';
import Input from '../../components/ui/Input';
import Button from '../../components/ui/Button';
import Link from '../../components/ui/Link';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit: SubmitHandler<FormData> = async (data: any) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('/restaurant-admin/password/forgot', {
        email: data.email,
      });
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      const errors = getValidationErrors(err);
      formRef.current?.setErrors(errors);
      if (err?.response?.status) {
        console.log(err);
        toast.error(ERROR_MESSAGE);
      }
    }
    setLoading(false);
  };

  return (
    <Container>
      <Title style={{ marginBottom: 0 }}>Esqueci Minha Senha</Title>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="email"
          type="email"
          label="E-mail"
          placeholder="Informe seu e-mail"
        />
        <Link
          to="/"
          style={{ display: 'block', fontSize: '0.8rem', textAlign: 'right' }}
        >
          Voltar
        </Link>
        <Button
          loading={loading}
          type="submit"
          block
          style={{ marginBottom: '1rem' }}
        >
          Recuperar Senha
        </Button>
      </Form>
    </Container>
  );
};

export default ForgotPassword;
