import styled from 'styled-components';

export const TopMenu = styled.header`
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: ${({ theme }) => theme.gray.dark};
  padding: 15px;
  box-shadow: 0px 0px 15px ${({ theme }) => theme.blackOpacity(0.5)};
  display: flex;
  align-items: center;
`;

export const LogoBox = styled.div`
  padding: 1rem;
  img {
    height: 20px;
  }
`;

export const MenuButton = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Hamburguer = styled.header`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.gray.light};
  border-radius: 2px;
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.gray.light};
    border-radius: 2px;
  }

  &:after {
    top: -6px;
  }

  &:before {
    top: 6px;
  }
`;

export const SideMenu = styled.aside`
  position: relative;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  background: ${({ theme }) => theme.gray.dark};
  box-shadow: 0px 0px 15px ${({ theme }) => theme.blackOpacity(0.5)};
  overflow: auto;
  padding: 50px 0 1rem 0;

  &.hide {
    width: 0;
    overflow: hidden;

    ul {
      display: none;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 200px;
  }

  @media screen and (max-width: 700px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    overflow: hidden;
    ul {
      display: none;
    }
    &.hide {
      width: 200px;
      overflow: auto;

      ul {
        display: block;
      }
    }
  }
`;

export const Menu = styled.ul`
  list-style: none;
`;

export const MenuItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.whiteOpacity(0.1)};
  color: ${({ theme }) => theme.gray.mediumLight};
  font-size: 0.9rem;
  a {
    padding: 0.7rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:hover,
  &.selected {
    background: ${({ theme }) => theme.gray.mediumDark};
    color: ${({ theme }) => theme.gray.ultraLight};
  }
`;
