export const header = [
  {
    id: 1,
    label: 'Data',
    key: 'date',
  },
  {
    id: 3,
    label: 'Banco',
    key: 'bank',
  },
  {
    id: 4,
    label: 'Agência',
    key: 'agency',
  },
  {
    id: 5,
    label: 'Conta Corrente',
    key: 'account',
  },
  {
    id: 6,
    label: 'Valor',
    key: 'value',
  },
];

export const body: any[] = [
  {
    id: 0,
    data: {
      date: {
        id: 1,
        label: '01/01/21',
      },
      bank: {
        id: 3,
        label: 'Itaú - 155',
      },
      agency: {
        id: 4,
        label: '1565',
      },
      account: {
        id: 5,
        label: '13246-4',
      },
      value: {
        id: 6,
        label: 'R$ 900,00',
      },
    },
  },
];
