import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';

import { useHistory } from 'react-router-dom';
import api from '../services/api';

interface SessionContextData {
  session: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const SessionContext = createContext<SessionContextData>(
  {} as SessionContextData,
);

const SessionProvider: React.FC = ({ children }) => {
  const initialSession = (): boolean => {
    const token = localStorage.getItem('@biddiToken');
    return !!token;
  };

  const [session, setSession] = useState<boolean>(initialSession());

  const history = useHistory();

  const logout = useCallback(() => {
    setSession(false);
    localStorage.removeItem('@biddiToken');
    if (history) history?.push('/login');
  }, [history]);

  const login = useCallback(
    (token: string) => {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      localStorage.setItem('@biddiToken', token);
      setSession(true);
      history?.push('/');
    },
    [history],
  );

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error?.response?.status === 401 && session) {
        logout();
      }
      return Promise.reject(error);
    },
  );

  api.interceptors.request.use((config: any) => {
    const token = localStorage.getItem('@biddiToken');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  useEffect(() => {
    const token = localStorage.getItem('@biddiToken');
    if (token) {
      login(token);
    } else {
      logout();
    }
  }, [login, logout]);

  return (
    <SessionContext.Provider
      value={{
        session,
        login,
        logout,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

function useSession(): SessionContextData {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error('useSession must be within an SessionProvider');
  }
  return context;
}

export { SessionProvider, useSession };
