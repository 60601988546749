import api from '../..';

const baseUrl = '/admin/bank-account';

export const getBankAccount = async () => {
  const { data } = await api.get(baseUrl);
  return data;
};

export const setBankAccount = async (body: any) => {
  const { data } = await api.put(baseUrl, body);
  return data;
};
