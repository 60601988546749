import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Link from '../../components/ui/Link';
import { Card } from '../../components/ui/Card';
import { header } from './constants';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row/index';
import Title from '../../components/ui/Title/index';
import {
  deleteAttributeItem,
  deletePermanentlyAttributeItem,
  getAttributeItems,
  updateAttributeItem,
} from '../../services/api/endpoints/attributes';
import { theme } from '../../styles/theme';
import Table from '../../components/ui/Table/index';
import Divisor from '../../components/ui/Divisor/index';
import NewAttributeItemForm from './NewAttributeItemForm/index';
import Paragraph from '../../components/ui/Paragraph';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';
import Input from '../../components/ui/Input';

const style = {
  link: {
    color: theme.primary.base,
    cursor: 'pointer' as const,
  },
  title: {
    marginBottom: '1rem',
  },
  backButton: {
    marginBottom: '1rem',
    display: 'inline-block' as const,
  },
};

const EditAttribute: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [isColor, setIsColor] = useState<boolean>(false);
  const { id }: any = useParams();

  const getData = async () => {
    try {
      const response = await getAttributeItems(id);
      response.items.map(item => {
        item.data = {
          name: {
            label: item.name,
          },
          colorCode: {
            label: item.colorCode
              ? renderColorInput(item.uuid, item.colorCode)
              : '-',
          },
          delete: {
            render: renderDelete(item.uuid),
          },
          permanentlyDelete: {
            render: renderPermantlyDelete(item.uuid),
          },
        };
        return true;
      });

      setData(response.items);
      setIsColor(response.iscolor);
    } catch (err) {
      console.log(err);
    }
  };

  const renderDelete = (uuid: string | number) => {
    const deleteItem = async () => {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm('Deseja excluir esse item?');
      if (!confirm) {
        return;
      }
      try {
        await deleteAttributeItem(uuid);
        getData();
        toast.success(SUCCESS_MESSAGE);
      } catch (err) {
        console.log(err);
        toast.error(ERROR_MESSAGE);
      }
    };
    return (
      <Paragraph danger type="link" onClick={deleteItem} style={{ margin: 0 }}>
        Excluir
      </Paragraph>
    );
  };

  const updateItem = async (uuid, code) => {
    try {
      await updateAttributeItem(uuid, code);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderColorInput = (uuid: string | number, code: string) => {
    return (
      <Form onSubmit={() => '.'} initialData={{ [uuid]: code }}>
        <Input
          name={`${uuid}`}
          placeholder="#ffffff"
          style={{
            input: { maxWidth: 120 },
            label: { maxWidth: 120 },
          }}
          onBlur={value => updateItem(uuid, value)}
        />
      </Form>
    );
  };

  const renderPermantlyDelete = (uuid: string | number) => {
    const deleteItem = async () => {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(
        'Deseja excluir esse item PERMANENTEMENTE?',
      );
      if (!confirm) {
        return;
      }
      try {
        await deletePermanentlyAttributeItem(uuid);
        getData();
        toast.success(SUCCESS_MESSAGE);
      } catch (err) {
        console.log(err);
        toast.error(ERROR_MESSAGE);
      }
    };
    return (
      <Paragraph danger type="link" onClick={deleteItem} style={{ margin: 0 }}>
        Excluir Permanente
      </Paragraph>
    );
  };

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title style={style.title}>Editar Atributo</Title>
          <Link to="/attributes" style={style.backButton}>
            Voltar
          </Link>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <Title level={3}>Novo Item</Title>
            <Divisor />
            <NewAttributeItemForm
              getData={getData}
              uuid={id}
              isColor={isColor}
            />
          </Card>
        </Col>
        <Col xs={12} md={8}>
          <Card>
            <Table header={header} body={data} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditAttribute;
