import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import { updatePassword } from '../../../services/api/endpoints/admin/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const UpdatePassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      await updatePassword(formData);
      formRef.current.reset();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        name="oldPassword"
        label="Senha Atual"
        placeholder="Informe sua senha atual"
        type="password"
      />
      <Input
        name="password"
        label="Nova Senha"
        placeholder="Informe sua nova senha"
        type="password"
      />
      <Input
        name="confirmPassword"
        label="Confirmar Senha"
        placeholder="Confirme sua nova senha"
        type="password"
      />
      <Button loading={loading} type="submit">
        Atualizar
      </Button>
    </Form>
  );
};

export default UpdatePassword;
