import api from '../..';

const baseUrl = '/admin/biddi/highlight';

export const getHighlight = async () => {
  const { data } = await api.get(baseUrl);
  return data;
};

export const setHighlight = async (body: any) => {
  try {
    const { data } = await api.put(baseUrl, body);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
