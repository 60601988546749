export const header = [
  {
    id: 0,
    label: 'Pedido',
    key: 'order',
  },
  {
    id: 1,
    label: 'Comprador',
    key: 'buyerId',
  },
  {
    id: 2,
    label: '2 Via',
    key: 'ticketOne',
  },
  {
    id: 3,
    label: 'Vendedor',
    key: 'sellerId',
  },
  {
    id: 4,
    label: '2 Via',
    key: 'ticketTwo',
  },
];

export const body: any[] = [
  {
    id: 0,
    data: {
      order: {
        id: 1,
        label: '234567',
      },
      buyerId: {
        id: 2,
        label: '124324334',
      },
      sellerId: {
        id: 3,
        label: '745634523',
      },
      date: {
        id: 5,
        label: '01/01/2021',
      },
      label: {
        id: 6,
        label: 'Imprimir',
      },
    },
  },
  {
    id: 1,
    data: {
      order: {
        id: 1,
        label: '234567',
      },
      buyerId: {
        id: 2,
        label: '124324334',
      },
      sellerId: {
        id: 3,
        label: '745634523',
      },
      date: {
        id: 5,
        label: '01/01/2021',
      },
      label: {
        id: 6,
        label: 'Imprimir',
      },
    },
  },
  {
    id: 2,
    data: {
      order: {
        id: 1,
        label: '234567',
      },
      buyerId: {
        id: 2,
        label: '124324334',
      },
      sellerId: {
        id: 3,
        label: '745634523',
      },
      date: {
        id: 5,
        label: '01/01/2021',
      },
      label: {
        id: 6,
        label: 'Imprimir',
      },
    },
  },
];
