export const header = [
  {
    id: 0,
    label: 'id',
    key: 'id',
  },
  {
    id: 0,
    label: 'Tipo',
    key: 'bidtype',
  },
  {
    id: 1,
    label: 'Status',
    key: 'status',
  },
  {
    id: 2.1,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 3,
    label: 'Lançamento',
    key: 'date',
  },
  {
    id: 4,
    label: 'Tempo em Aberto',
    key: 'openTime',
  },
  {
    id: 5,
    label: 'Tempo para Expirar',
    key: 'expiretime',
  },
  {
    id: 6,
    label: 'SKU',
    key: 'skuId',
  },
  {
    id: 7,
    label: 'Atributos',
    key: 'attributes',
  },
  {
    id: 8,
    label: 'Checklist',
    key: 'checklist',
  },
  {
    id: 9,
    label: 'Valor',
    key: 'value',
  },
];

export const filter = [
  {
    id: 0,
    label: 'Tipo',
    value: 'type',
  },
  {
    id: 1,
    label: 'Status',
    value: 'status',
  },
  {
    id: 3,
    label: 'Lançamento',
    value: 'date',
  },
  {
    id: 4,
    label: 'Tempo em Aberto',
    value: 'opentime',
  },
  {
    id: 5,
    label: 'Tempo para Expirar',
    value: 'expirationTime',
  },
  {
    id: 8,
    label: 'Valor',
    value: 'value',
  },
];
