/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { CSSProperties } from 'styled-components';
import { Container, Error, Label, StyledInput, InputLabel } from './styles';
import IF from '../IF';
import Editor from './Editor';

interface Props {
  name: string;
  label?: string;
  onChange?: (value: string | number) => void;
  style?: {
    input?: CSSProperties;
    label?: CSSProperties;
  };
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const TextEditor: React.FC<InputProps> = ({
  name,
  label,
  style,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleChange = (value: string) => {
    inputRef.current.value = value;
    if (onChange) onChange(value);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <InputLabel className={error ? 'error' : ''} style={style?.label}>
        <StyledInput
          id={fieldName}
          ref={inputRef}
          style={style?.input}
          {...rest}
        />
        <Editor onChange={handleChange} defaultValue={defaultValue} />
      </InputLabel>

      <IF condition={!!error}>
        <Error>{error}</Error>
      </IF>
      <IF condition={!error}>
        <Error>
          <span>.</span>
        </Error>
      </IF>
    </Container>
  );
};

export default TextEditor;
