import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Title from '../../components/ui/Title';
import ProductDetail from '../../components/ProductForms/ProductDetail';
import ProductSkus from '../../components/ProductForms/ProductSkus';
import ProductExtraItems from '../../components/ProductForms/ProductExtraItems';
import Row from '../../components/ui/Row';
import Link from '../../components/ui/Link';
import {
  updateProduct,
  getProduct,
  getProductAttributes,
} from '../../services/api/endpoints/products';

const style = {
  title: {
    marginBottom: '1rem',
  },
  backButton: {
    marginBottom: '1rem',
    display: 'inline-block' as const,
  },
  card: {
    height: '100%',
  },
};

const EditProduct: React.FC = () => {
  const [product, setProduct] = useState<any>();
  const { id }: any = useParams();

  const getData = async () => {
    try {
      const response = await getProduct(id);
      const attributes = await getProductAttributes(id);
      attributes.map(item => {
        item.items = [];
        return true;
      });
      setProduct({ ...response, attributes });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleUpdateProduct = async (body: any) => {
    await updateProduct(id, body);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title style={style.title}>Editar Produto</Title>
          <Link to="/products" style={style.backButton}>
            Voltar
          </Link>
        </Col>
      </Row>

      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Card style={style.card}>
            <ProductDetail
              onSubmit={handleUpdateProduct}
              initialData={product}
              type="edit"
            />
          </Card>
        </Col>

        <Col xs={12}>
          <Card style={style.card}>
            <ProductSkus attributes={product?.attributes} uuid={id} />
          </Card>
        </Col>

        <Col xs={12}>
          <Card style={style.card}>
            <ProductExtraItems uuid={id} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EditProduct;
