export const header = [
  {
    id: 0,
    label: 'ID',
    key: 'uuid',
  },
  {
    id: 1,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 2,
    label: 'Preço',
    key: 'price',
  },
  {
    id: 3,
    label: 'Excluir',
    key: 'delete',
  },
];
