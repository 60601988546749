import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';
import Divisor from '../../../components/ui/Divisor';
import Modal from '../../../components/ui/Modal';
import Title from '../../../components/ui/Title';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import {
  createBrand,
  getBrand,
  updateBrand,
} from '../../../services/api/endpoints/brands';

interface IProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  onClose: () => void;
  brand?: any;
}

const BrandModal: React.FC<IProps> = ({
  visible,
  setVisible,
  brand,
  onClose,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const response = await getBrand(brand);
      formRef.current.setData({
        name: response.name,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (brand) {
      getData();
    }
  }, [brand]);

  const handleSubmit = async (formData: FormData) => {
    try {
      setLoading(true);
      if (brand) {
        await updateBrand(brand, formData);
      } else {
        await createBrand(formData);
      }
      onClose();
      setVisible(false);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Title level={3}>
        {`${brand || brand >= 0 ? 'Editar' : 'Nova'} Marca`}
      </Title>
      <Divisor />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="name"
          label="Nome da Marca"
          placeholder="Por favor, informe o nome da marca"
        />
        <Button type="submit" block loading={loading}>
          {brand || brand >= 0 ? 'Atualizar' : 'Adicionar'}
        </Button>
      </Form>
    </Modal>
  );
};

export default BrandModal;
