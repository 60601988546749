import styled from 'styled-components';

export const Container = styled.div``;
export const DataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 5px;
`;

export const Label = styled.label`
  display: block;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.gray.dark};
  margin-bottom: 0.5rem;
`;

interface IData {
  type: 'positive' | 'negative';
}

export const Data = styled.span<IData>`
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 1.7rem;
  color: ${({ theme, type }) =>
    type === 'positive' ? theme.primary.base : theme.danger.base};
`;

export const Prefix = styled.span<IData>`
  font-weight: bolder;
  color: ${({ theme, type }) =>
    type === 'positive' ? theme.primary.base : theme.danger.base};
`;
export const Suffix = styled.span<IData>`
  font-weight: bolder;
  color: ${({ theme, type }) =>
    type === 'positive' ? theme.primary.base : theme.danger.base};
`;
