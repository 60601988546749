import * as Yup from 'yup';

export const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Sua senha deve conter no mínimo 8 caracteres, com pelo menos 1 maiúscula, 1 minúscula, 1 número e 1 caractere especial (@$!%*#?&)',
    )
    .required('Por favor, informe sua nova senha'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'As senhas não conferem!',
  ),
});
