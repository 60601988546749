/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { ChangeEvent, useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { CSSProperties } from 'styled-components';
import { Container, Error, Label, StyledInput, InputLabel } from './styles';
import IF from '../IF';
import { formatPrice } from '../../../utils/format';

interface Props {
  name: string;
  label?: string;
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  onChange?: (value: string | number) => void;
  onBlur?: (value: string | number) => void;
  style?: {
    input?: CSSProperties;
    label?: CSSProperties;
  };
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const Input: React.FC<InputProps> = ({
  name,
  label,
  prefix,
  suffix,
  style,
  onChange,
  onBlur,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(target.value);
  };

  const handleBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(target.value);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <InputLabel className={error ? 'error' : ''} style={style?.label}>
        <IF condition={!!prefix}>{prefix}</IF>
        <StyledInput
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          style={style?.input}
          onChange={handleChange}
          onBlur={handleBlur}
          {...rest}
        />
        <IF condition={!!suffix}>{suffix}</IF>
      </InputLabel>

      <IF condition={!!error}>
        <Error>{error}</Error>
      </IF>
      <IF condition={!error}>
        <Error>
          <span>.</span>
        </Error>
      </IF>
    </Container>
  );
};

export default Input;
