import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { fileUpload } from '../../../../services/api/endpoints/fileUpload';
import { createSku } from '../../../../services/api/endpoints/skus';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../utils/toastMessage';
import Button from '../../../ui/Button';
import Divisor from '../../../ui/Divisor';
import MultipleUpload from '../../../ui/MultipleUpload';

import Select from '../../../ui/Select';
import Textarea from '../../../ui/Textarea';
import TextEditor from '../../../ui/TextEditor';
import Title from '../../../ui/Title';

interface IProps {
  attributes: any[];
  uuid: string;
  getData: () => void;
}

const NewSKU: React.FC<IProps> = ({ attributes, uuid, getData }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const images = [];
      const promise = Object.keys(data.files).map(async item => {
        const { path } = await fileUpload('image', data.files[item]);
        images.push(path);
      });

      await Promise.all(promise);

      delete data.files;
      const body: any = {};
      body.attributes = data.attributes.map(item => ({ uuid: item }));
      body.images = images;
      body.technicalDescription = data.technicalDescription;
      await createSku(uuid, body);
      formRef.current.setFieldValue('files', []);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Title level={3}>Novo SKU</Title>
      <Divisor />

      {attributes?.map((item, i) => (
        <Select
          key={item.uuid}
          name={`attributes[${i}]`}
          label={item.name}
          options={item?.items}
        />
      ))}
      <TextEditor name="technicalDescription" label="Ficha Técnica" />
      <MultipleUpload name="files" label="Imagens" />
      <Button type="submit" loading={loading}>
        Adicionar
      </Button>
    </Form>
  );
};

export default NewSKU;
