import api from '../..';

const baseUrl = '/admin';

export const getAdmin = async () => {
  try {
    const { data } = await api.get(`${baseUrl}/account`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getAdmins = async () => {
  try {
    const { data } = await api.get(`${baseUrl}/accounts`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAdmin = async (content: any) => {
  try {
    const { data } = await api.put(`${baseUrl}/account`, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updatePassword = async (content: any) => {
  try {
    const { data } = await api.put(`${baseUrl}/account/password`, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createAdmin = async (content: any) => {
  try {
    const { data } = await api.post(`${baseUrl}/account/register`, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteAdmin = async (id: string) => {
  try {
    const { data } = await api.delete(`${baseUrl}/account/${id}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
