import React, { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Table from '../../components/ui/Table';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Paragraph from '../../components/ui/Paragraph';
import Paginate from '../../components/ui/Paginate';
import Input from '../../components/ui/Input';
import {
  getUsers,
  setBlockUsers,
  deleteUser,
} from '../../services/api/endpoints/users';
import IF from '../../components/ui/IF';
import UserAddress from './UserAddress/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';
import Button from '../../components/ui/Button';
import useDebounce from '../../hooks/useDebounce';
import { getCsv } from '../../services/api/endpoints/exportCsv';

const Users: React.FC = () => {
  const [userData, setUserData] = useState<any[]>([]);
  const [blockedData, setBlockedData] = useState<any[]>([]);
  const [usersTotalPage, setUsersTotalPage] = useState<number>(1);
  const [usersPage, setUsersPage] = useState<number>(1);
  const [usersFilter, setUsersFilter] = useState<string>('');
  const [blockedTotalPage, setBlockedTotalPage] = useState<number>(1);
  const [blockedPage, setBlockedPage] = useState<number>(1);
  const [blockedFilter, setBlockedFilter] = useState<string>('');

  const getData = async (
    type: 'block' | 'regular',
    page = 1,
    filter: string,
  ) => {
    try {
      const { data, pagination } = await getUsers(type, page, filter);

      data.map((item: any) => {
        item.data = {
          ...item.json_build_object,
          id: {
            label: item.uuid,
          },
          address: {
            render: <UserAddress uuid={item.uuid} />,
          },
          products: {
            render: renderProducts(item.uuid),
          },
          bankData: {
            render: renderBankData(item.uuid),
          },
          block: {
            render:
              type === 'regular'
                ? renderBlock(item.uuid, 'Bloquear', 'danger')
                : renderBlock(item.uuid, 'Desbloquear', 'primary'),
          },
          delete: {
            render: renderDelete(item.uuid),
          },
        };

        delete item.json_build_object;
        return true;
      });
      if (type === 'regular') {
        setUserData(data);
        setUsersTotalPage(pagination?.totalPages);
      } else {
        setBlockedData(data);
        setBlockedTotalPage(pagination?.totalPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData('regular', usersPage, usersFilter);
  }, [usersPage, usersFilter]);

  useEffect(() => {
    getData('block', blockedPage, blockedFilter);
  }, [blockedPage, blockedFilter]);

  const handleBankData = (param: string | number) => {
    console.log(param);
  };

  const handleProducts = (param: string | number) => {
    console.log(param);
  };

  const handleBlock = async (param: string) => {
    try {
      await setBlockUsers(param);
      getData('regular', usersPage, usersFilter);
      getData('block', blockedPage, blockedFilter);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };
  const handleDelete = async (param: string) => {
    try {
      await deleteUser(param);
      getData('regular', usersPage, usersFilter);
      getData('block', blockedPage, blockedFilter);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderBankData = (id: string | number) => {
    return (
      <Paragraph primary type="link" onClick={() => handleBankData(id)}>
        Ver Mais
      </Paragraph>
    );
  };
  const renderProducts = (id: string | number) => {
    return (
      <Paragraph primary type="link" onClick={() => handleProducts(id)}>
        Ver Mais
      </Paragraph>
    );
  };

  const renderBlock = (
    id: string,
    label: 'Bloquear' | 'Desbloquear',
    type: 'danger' | 'primary',
  ) => {
    return (
      <Paragraph
        danger={type === 'danger'}
        primary={type === 'primary'}
        type="link"
        onClick={() => handleBlock(id)}
      >
        {label}
      </Paragraph>
    );
  };

  const renderDelete = (id: string) => {
    return (
      <Paragraph danger type="link" onClick={() => handleDelete(id)}>
        Excluir
      </Paragraph>
    );
  };

  const handleUsersPage = ({ selected }: { selected: number }) => {
    setUsersPage(selected + 1);
  };

  const handleBlockedPage = ({ selected }: { selected: number }) => {
    setBlockedPage(selected + 1);
  };

  const getUsersFilteredData = (value: string) => {
    setUsersFilter(value);
    setUsersPage(1);
  };

  const getBLockedFilteredData = (value: string) => {
    setBlockedFilter(value);
    setBlockedPage(1);
  };

  const debouncedUsersFilter = useDebounce(getUsersFilteredData, 500);
  const debouncedBlockedFilter = useDebounce(getBLockedFilteredData, 500);

  const handleUsersSearch = (value: any) => {
    debouncedUsersFilter(value);
  };

  const handleBlockedSearch = (value: any) => {
    debouncedBlockedFilter(value);
  };

  const [loadingCsv, setLoadingCsv] = useState<boolean>(false);

  const exportData = async () => {
    try {
      setLoadingCsv(true);
      const data = await getCsv('users');
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      const csvURL = window.URL.createObjectURL(blob);
      window.open(csvURL);
    } catch (err) {
      toast.error(ERROR_MESSAGE);
    }
    setLoadingCsv(false);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12} md={8} lg={10}>
          <Title>Usuários</Title>
        </Col>
        <Col xs={12} md={4} lg={2}>
          <Button onClick={exportData} loading={loadingCsv}>
            Exportar CSV
          </Button>
        </Col>
        <Col xs={12}>
          <Card>
            <Form onSubmit={handleUsersSearch}>
              <Input
                name="search"
                placeholder="Buscar por ID, Nome ou CPF"
                onChange={handleUsersSearch}
              />
            </Form>
            <Table header={header} body={userData} />
            <IF condition={userData.length > 0}>
              <Paginate
                pageCount={usersTotalPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                initialPage={usersPage - 1}
                onPageChange={handleUsersPage}
              />
            </IF>
          </Card>
        </Col>
        <Col xs={12}>
          <Title>Usuários Bloqueados</Title>
        </Col>
        <Col xs={12}>
          <Card>
            <Form onSubmit={handleBlockedSearch}>
              <Input
                name="search"
                placeholder="Buscar por nome ou CPF"
                onChange={handleBlockedSearch}
              />
            </Form>
            <Table header={header} body={blockedData} />
            <IF condition={blockedData.length > 0}>
              <Paginate
                pageCount={blockedTotalPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                initialPage={blockedPage - 1}
                onPageChange={handleBlockedPage}
              />
            </IF>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Users;
