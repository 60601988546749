import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Home from '../pages/Home';
import Bids from '../pages/Bids';
import Orders from '../pages/Orders';
import Users from '../pages/Users';
import Admins from '../pages/Admins';
import Platform from '../pages/Platform';
import Legal from '../pages/Legal';
import Settings from '../pages/Settings';
import Finances from '../pages/Finances';
import Evaluation from '../pages/Evaluation';
import Categories from '../pages/Categories';
import Products from '../pages/Products';
import EditProduct from '../pages/EditProduct';
import NewProduct from '../pages/NewProduct';
import Labels from '../pages/Labels';
import Checklist from '../pages/Checklist';
import NewChecklist from '../pages/NewChecklist';
import EditChecklist from '../pages/EditChecklist';
import Attributes from '../pages/Attributes';
import EditAttribute from '../pages/EditAttribute';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />

      <Route exact path="/" isPrivate component={Home} />
      <Route exact path="/bids" isPrivate component={Bids} />
      <Route exact path="/orders" isPrivate component={Orders} />
      <Route exact path="/users" isPrivate component={Users} />
      <Route exact path="/admins" isPrivate component={Admins} />
      <Route exact path="/platform" isPrivate component={Platform} />
      <Route exact path="/legal" isPrivate component={Legal} />
      <Route exact path="/settings" isPrivate component={Settings} />
      <Route exact path="/finances" isPrivate component={Finances} />
      <Route exact path="/evaluation" isPrivate component={Evaluation} />
      <Route exact path="/categories" isPrivate component={Categories} />
      <Route exact path="/labels" isPrivate component={Labels} />
      <Route exact path="/products" isPrivate component={Products} />
      <Route exact path="/products/new" isPrivate component={NewProduct} />
      <Route
        exact
        path="/products/edit/:id"
        isPrivate
        component={EditProduct}
      />
      <Route exact path="/checklists" isPrivate component={Checklist} />
      <Route exact path="/checklists/new" isPrivate component={NewChecklist} />
      <Route
        exact
        path="/checklists/edit/:id"
        isPrivate
        component={EditChecklist}
      />
      <Route exact path="/attributes" isPrivate component={Attributes} />
      <Route
        exact
        path="/attributes/edit/:id"
        isPrivate
        component={EditAttribute}
      />
      {/* <Route path="/" component={Page404} /> */}
    </Switch>
  );
};

export default Routes;
