import React from 'react';
import { ReactPaginateProps } from 'react-paginate';
import { GrPrevious, GrNext } from 'react-icons/gr';
import { StyledPaginate, Container } from './styles';

const Paginate: React.FC<ReactPaginateProps> = ({
  pageCount,
  pageRangeDisplayed,
  marginPagesDisplayed,
  initialPage,
  ...rest
}) => {
  return (
    <Container>
      <StyledPaginate
        pageCount={pageCount}
        pageRangeDisplayed={pageRangeDisplayed}
        marginPagesDisplayed={marginPagesDisplayed}
        initialPage={initialPage}
        previousLabel={<GrPrevious />}
        nextLabel={<GrNext />}
        {...rest}
      />
    </Container>
  );
};

export default Paginate;
