/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Title from '../../components/ui/Title';
import QualityParams from '../../components/ChecklistForms/QualityParams';
import ChecklistDetail from '../../components/ChecklistForms/ChecklistDetail';
import Row from '../../components/ui/Row';
import Link from '../../components/ui/Link';
import Button from '../../components/ui/Button/index';
import FunctionalParams from '../../components/ChecklistForms/FunctionalParams/index';
import QuantityParams from '../../components/ChecklistForms/QuantityParams/index';
import { fileUpload } from '../../services/api/endpoints/fileUpload';
import {
  getChecklist,
  updateChecklists,
} from '../../services/api/endpoints/checklist';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';

const style = {
  title: {
    marginBottom: '1rem',
  },
  backButton: {
    marginBottom: '1rem',
    display: 'inline-block' as const,
  },
  card: {
    height: '100%',
  },
};

const EditChecklist: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>();
  const [functionalParamError, setFunctionalParamError] =
    useState<boolean>(false);
  const [quantityParamError, setQuantityParamError] = useState<boolean>(false);
  const [qualityParamError, setQualityParamError] = useState<boolean>(false);
  const { id }: any = useParams();

  const getData = async () => {
    try {
      const data = await getChecklist(id);
      formRef.current.setFieldValue('name', data.name);
      setInitialData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleSubmit = async (data: any) => {
    let error = false;
    setFunctionalParamError(false);
    setQualityParamError(false);
    setQuantityParamError(false);
    if (!data?.functionalParams || data?.functionalParams?.length === 0) {
      error = true;
      setFunctionalParamError(true);
    }
    if (!data?.qualityParams || data?.qualityParams?.length === 0) {
      error = true;
      setQualityParamError(true);
    }
    if (!data?.quantityParams || data?.quantityParams?.length === 0) {
      error = true;
      setQuantityParamError(true);
    }

    if (error) {
      return;
    }
    try {
      setLoading(true);

      const qualityParams = [];
      const promise = data.qualityParams.map(async item => {
        qualityParams.push({
          name: item.name,
          bad: item.bad && (await fileUpload('image', item.bad)),
          excellent:
            item.excellent && (await fileUpload('image', item.excellent)),
          good: item.good && (await fileUpload('image', item.good)),
          reasonable:
            item.reasonable && (await fileUpload('image', item.reasonable)),
        });
      });

      await Promise.all(promise);

      await updateChecklists(id, {
        ...data,
        qualityParams,
      });

      toast.success(SUCCESS_MESSAGE);
      await getData();
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title style={style.title}>Editar Checklist</Title>
          <Link to="/checklists" style={style.backButton}>
            Voltar
          </Link>
        </Col>
      </Row>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Row colGap={16} rowGap={16}>
          <Col xs={12}>
            <Card style={{ marginBottom: '1rem' }}>
              <ChecklistDetail />
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card style={style.card}>
              <QualityParams
                initialData={initialData?.qualityparams}
                formRef={formRef}
                error={qualityParamError}
              />
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card style={style.card}>
              <FunctionalParams
                initialData={initialData?.functionalparams}
                formRef={formRef}
                error={functionalParamError}
              />
            </Card>
          </Col>
          <Col xs={12} lg={4}>
            <Card style={style.card}>
              <QuantityParams
                initialData={initialData?.quantityparams}
                formRef={formRef}
                error={quantityParamError}
              />
            </Card>
          </Col>

          <Col xs={12}>
            <Button type="submit" loading={loading}>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default EditChecklist;
