import React from 'react';

import {
  Container,
  Label,
  Data,
  DataContainer,
  Prefix,
  Suffix,
} from './styles';

interface IStatistic {
  label: string;
  data: string | number | React.ReactNode;
  type?: 'positive' | 'negative';
  prefix?: string;
  suffix?: string;
}

const Statistic: React.FC<IStatistic> = ({
  data,
  label,
  type = 'positive',
  prefix,
  suffix,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <DataContainer>
        <Prefix type={type}>{prefix}</Prefix>
        <Data type={type}>{data}</Data>
        <Suffix type={type}>{suffix}</Suffix>
      </DataContainer>
    </Container>
  );
};

export default Statistic;
