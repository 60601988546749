import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../components/ui/Button';
import IF from '../../../components/ui/IF';
import Input from '../../../components/ui/Input';
import { createAttributeItem } from '../../../services/api/endpoints/attributes';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

interface IProps {
  isColor: boolean;
  uuid: string;
  getData: () => void;
}

const NewAttributeItemForm: React.FC<IProps> = ({ getData, isColor, uuid }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<FormHandles>();

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);

      if (e.colorCode) {
        e.colorCode = `#${e.colorCode}`;
      }

      await createAttributeItem(uuid, e);
      getData();
      ref.current.setFieldValue('name', null);
      ref.current.setFieldValue('colorCode', null);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={ref} onSubmit={handleSubmit}>
      <Input
        name="name"
        label="Nome do Item"
        placeholder="Informe o nome do item"
      />

      <IF condition={isColor}>
        <Input
          prefix="#"
          name="colorCode"
          label="Código Hexadecimal da Cor"
          placeholder="Informe o hexadecimal da cor"
        />
      </IF>

      <Button type="submit" loading={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

export default NewAttributeItemForm;
