/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Table from '../../components/ui/Table';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Paragraph from '../../components/ui/Paragraph';
import Paginate from '../../components/ui/Paginate';
import Input from '../../components/ui/Input';
import { getBids } from '../../services/api/endpoints/bids/index';
import { getCsv } from '../../services/api/endpoints/exportCsv/index';
import { formatPrice } from '../../utils/format';
import Button from '../../components/ui/Button';
import useDebounce from '../../hooks/useDebounce';
import { ERROR_MESSAGE } from '../../utils/toastMessage';

const Bids: React.FC = () => {
  const [bids, setBids] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const searchRef = useRef<FormHandles>(null);
  const [filter, setFilter] = useState<string>('');

  const handleFilter = () => {
    console.log('');
  };

  const openChecklist = (url: string) => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('target', '_blank');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const createRender = (url: string) => {
    return (
      <Paragraph primary type="link" onClick={() => openChecklist(url)}>
        Abrir
      </Paragraph>
    );
  };

  const getData = async (p: number, f: string) => {
    try {
      const { data, pagination } = await getBids(p, f);
      setTotalPage(pagination?.totalPages);
      data.map((item: any) => {
        // item.id = item.uuid;
        item.responsetype = 'bids';
        item.data = {
          id: {
            id: 0,
            label: item.id,
          },
          bidtype: {
            id: 0,
            label: item.bidtype,
          },
          status: {
            id: 1,
            label: item.status,
          },
          clientid: {
            id: 2,
            label: item.clientId,
          },
          attributes: {
            id: 2,
            label: item.attributes,
          },
          name: {
            id: 2.1,
            label: item.name,
          },
          date: {
            id: 3,
            label: item.date,
          },
          openTime: {
            id: 4,
            label: `${item.opentime} dias`,
          },
          expiretime: {
            id: 5,
            label: `${item.expiretime} dias`,
          },
          skuId: {
            id: 6,
            label: item.skuId,
          },
          checklist: {
            id: 7,
            render: createRender(item.checklist),
          },
          value: {
            id: 8,
            label: formatPrice(item.value, 2),
          },
        };
        return true;
      });

      setBids(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData(page, filter);
  }, [page, filter]);

  const handlePage = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const getFilteredData = (value: string) => {
    setFilter(value);
    setPage(1);
  };

  const debouncedFilter = useDebounce(getFilteredData, 500);

  const handleSearch = (value: any) => {
    debouncedFilter(value);
  };

  const [loadingCsv, setLoadingCsv] = useState<boolean>(false);
  const [loadingUploadCsv, setLoadingUploadCsv] = useState<boolean>(false);

  const exportData = async () => {
    try {
      setLoadingCsv(true);
      const data = await getCsv('orders');
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      const csvURL = window.URL.createObjectURL(blob);
      window.open(csvURL);
    } catch (err) {
      toast.error(err, {
        position: 'top-center',
        autoClose: 3000,
      });
    }
    setLoadingCsv(false);
  };

  const uploadCsv = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = async () => {
      const file = input.files?.[0];
      if (file) {
        setLoadingUploadCsv(true);
        const formData = new FormData();
        formData.append('file', file);

        toast.info(
          'Recebemos o arquivo, estamos processando os dados, aguarde...',
          {
            position: 'top-center',
          },
        );

        setTimeout(async () => {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/admin/bidCSV`,
            {
              method: 'PUT',
              body: formData,
            },
          );

          const data = await response.json();
          const { messagem } = data;
          if ([200, 201, 203].includes(response.status)) {
            toast.success(messagem, {
              position: 'top-center',
            });
            setTimeout(() => {
              setLoadingUploadCsv(false);
              window.location.reload();
            }, 3500);
          } else {
            setLoadingUploadCsv(false);
            toast.warn(messagem, {
              position: 'top-center',
              autoClose: false,
            });
          }
        }, 4000);
      }
    };
    input.click();
  };

  return (
    <>
      <Container>
        <Row colGap={16} rowGap={16}>
          <Col xs={12} md={8} lg={10}>
            <Title>Ordens</Title>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ marginRight: 8 }}>
                <Button
                  ghost
                  onClick={() => uploadCsv()}
                  loading={loadingUploadCsv}
                >
                  Importar CSV
                </Button>
              </div>
              <Button onClick={exportData} loading={loadingCsv}>
                Exportar CSV
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <Card>
              <Form onSubmit={handleFilter} ref={searchRef}>
                <Input
                  placeholder="Buscar por ID ou Nome do cliente"
                  name="search"
                  onChange={handleSearch}
                />
              </Form>
              <Table header={header} body={bids} />
              <Paginate
                pageCount={totalPage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                initialPage={page - 1}
                onPageChange={handlePage}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Bids;
