import api from '../..';

const baseUrl = '/admin/product';

export const getSkus = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl}/${uuid}/skus`);

  return data;
};

export const createSku = async (uuid: string, body: any) => {
  const { data } = await api.post(`${baseUrl}/${uuid}/sku`, body);
  return data;
};

export const deleteSku = async (uuid: string) => {
  const { data } = await api.delete(`/admin/sku/${uuid}`);
  return data;
};
