import styled from 'styled-components';

export const Container = styled.main`
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const ImageColumn = styled.div`
  height: 100vh;
  width: 50%;
  background-image: url('https://images.unsplash.com/photo-1550029402-226115b7c579?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=701&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (max-width: 766px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 50%;
  height: 100vh;
  padding: 5% 10%;
  overflow-y: auto;
  @media screen and (max-width: 766px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
