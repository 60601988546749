/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Button from '../../ui/Button';
import Divisor from '../../ui/Divisor';
import IF from '../../ui/IF';
import Paragraph from '../../ui/Paragraph';
import Title from '../../ui/Title';
import Param from './Param';

interface IProps {
  initialData?: any;
  formRef?: any;
  error: boolean;
}

const FunctionalParams: React.FC<IProps> = ({
  initialData,
  formRef,
  error,
}) => {
  const [items, setItems] = useState<number>(0);
  const [deletedItems, setDeletedItems] = useState<any>();

  const addItem = () => {
    setItems(items + 1);
  };

  const deleteItem = (index: number) => {
    if (initialData) {
      setDeletedItems({ ...deletedItems, [index]: true });
    }
    setItems(items - 1);
  };

  useEffect(() => {
    if (initialData) {
      setItems(initialData?.length);
    }
  }, [initialData]);

  const getDefaultValue = (index: number) => {
    if (deletedItems?.[index]) {
      return;
    }
    if (initialData && initialData?.[index]) {
      const item = initialData?.[index];
      formRef?.current?.setFieldValue(
        `functionalParams[${index}].name`,
        item?.name,
      );
      formRef?.current?.setFieldValue(
        `functionalParams[${index}].id`,
        item?.id,
      );
    }
  };

  return (
    <>
      <Title level={3}>Parâmetros Funcionais</Title>
      <Divisor />
      {[...Array(items)].map((item, i) => (
        <Param
          key={`${i}`}
          index={i}
          onDelete={() => deleteItem(i)}
          getDefaultValue={() => getDefaultValue(i)}
          hasId={!!initialData}
        />
      ))}
      <IF condition={error}>
        <Paragraph danger>Informe ao menos um parâmetro</Paragraph>
      </IF>
      <Button type="button" ghost onClick={addItem}>
        Adicionar Parâmetro
      </Button>
    </>
  );
};

export default FunctionalParams;
