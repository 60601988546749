/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Divisor from '../../../ui/Divisor';
import Paragraph from '../../../ui/Paragraph';
import Table from '../../../ui/Table';
import Title from '../../../ui/Title';
import { deleteSku } from '../../../../services/api/endpoints/skus/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../utils/toastMessage';

interface IProps {
  skus: any[];
  attributes: any[];
  getData: () => void;
}

const SKUList: React.FC<IProps> = ({ skus, attributes, getData }) => {
  const [data, setData] = useState<any[]>([]);
  const [header, setHeader] = useState<any[]>([]);

  const deleteItem = async (uuid: string) => {
    try {
      await deleteSku(uuid);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderDelete = (id: string) => {
    return (
      <Paragraph danger type="link" onClick={() => deleteItem(id)}>
        Excluir
      </Paragraph>
    );
  };

  useEffect(() => {
    if (skus) {
      skus?.map(sku => {
        const tableData: any = {};
        tableData.delete = { render: renderDelete(sku.uuid) };
        tableData.id = { label: sku.uuid };

        sku?.items?.map(item =>
          Object.keys(item)?.map(key => {
            tableData[key] = { label: item[key] };
          }),
        );

        if (sku) {
          sku.data = tableData;
        }
      });
      setData(skus);
    }
  }, [skus]);

  useEffect(() => {
    if (attributes) {
      const idHeader = {
        id: 4,
        label: 'ID',
        key: 'id',
      };
      const deleteHeader = {
        id: 3,
        label: 'Excluir',
        key: 'delete',
      };
      attributes.map(item => {
        item.id = item.uuid;
        item.label = item.name;
        item.key = item.name;
        return true;
      });
      setHeader([idHeader, ...attributes, deleteHeader]);
    }
  }, [attributes]);

  return (
    <>
      <Title level={3}>SKUs</Title>
      <Divisor />
      <Table header={header} body={data} />
    </>
  );
};

export default SKUList;
