import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Title from '../../components/ui/Title';
import ProductDetail from '../../components/ProductForms/ProductDetail';
import Row from '../../components/ui/Row';
import Link from '../../components/ui/Link';
import { createProduct } from '../../services/api/endpoints/products';

const style = {
  title: {
    marginBottom: '1rem',
  },
  backButton: {
    marginBottom: '1rem',
    display: 'inline-block' as const,
  },
  card: {
    height: '100%',
  },
};

const NewProduct: React.FC = () => {
  const history = useHistory();
  const handleNewProduct = async (body: any) => {
    const { uuid } = await createProduct(body);
    history.push(`/products/edit/${uuid}`);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title style={style.title}>Cadastrar Produto</Title>
          <Link to="/products" style={style.backButton}>
            Voltar
          </Link>
        </Col>
      </Row>

      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Card style={style.card}>
            <ProductDetail onSubmit={handleNewProduct} type="new" />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewProduct;
