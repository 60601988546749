import React from 'react';
import { MdDelete } from 'react-icons/md';
import { theme } from '../../../../styles/theme';
import Paragraph from '../../../ui/Paragraph';

const style = {
  paragraph: {
    display: 'flex' as const,
    alingItems: 'center' as const,
    marginBottom: '1rem',
  },
  icon: {
    color: theme.feedback.negative,
    marginLeft: '1rem',
    cursor: 'pointer' as const,
  },
};

interface IProps {
  label: string;
  value: string;
  onRemove: (value: string) => void;
}

const ProductAttribute: React.FC<IProps> = ({ label, value, onRemove }) => {
  const handleRemove = () => {
    onRemove(value);
  };

  return (
    <Paragraph style={style.paragraph}>
      {label}
      <MdDelete onClick={handleRemove} style={style.icon} />
    </Paragraph>
  );
};

export default ProductAttribute;
