// export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
//   style: 'currency',
//   currency: 'BRL',
//   maximumFractionDigits: 0,
//   minimumFractionDigits: 0,
// });

export const formatPrice = (value: number, fixed = 0) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: fixed,
    minimumFractionDigits: fixed,
  }).format(value);
};
