/* eslint-disable prettier/prettier */
import styled, { keyframes, css } from 'styled-components';

export const Container = styled.div``;

interface IStyledInput {
  [string: string]: any;
}
interface IInputLabel {
  type?: 'image' | 'file';
  [string: string]: any;
}

export const InputLabel = styled.label<IInputLabel>`
  width: 70px;
  height: 70px;
  background: ${({ theme }) => theme.gray.extraLight};
  color: ${({ theme }) => theme.gray.mediumDark};
  border-radius: 3px;
  border: 1px dashed ${({ theme }) => theme.gray.mediumDark};
  font-size: 1.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  position: relative;

  ${({ type }) => type === 'file' && css`
    width: 100%;
    height: auto;
  `};


  &.error {
    border-color: ${({ theme }) => theme.danger.base};
    color: ${({ theme }) => theme.danger.base};
  }
`;



export const Filename = styled.span`
  font-size: 1rem;
  width: 100%;
  text-align: left;
  padding: 2px 5px;
  display: flex;
  align-items: center;
`;

export const StyledInput = styled.input<IStyledInput>`
  display: none;
`;

interface ILabel {
  htmlFor: string;
}

export const Label = styled.span<ILabel>`
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.9rem;
  display: block;
  margin-bottom: .5rem;
`;

const fadeError = keyframes`
  from{
    opacity: 0;
    transform: translateY(-5px);
  }

  to{
    opacity: 1;
    transform: translateY(0);
  }
`

export const Error = styled.span`
  color: ${({ theme }) => theme.danger.base};
  font-size: 0.9rem;
  font-weight: 400;
  transition: .2s;
  animation: ${fadeError} linear 500ms;
  display: block;
  margin-bottom: 0.5rem;

  span{
    color: transparent;
  }
`;

export const Loading = styled.div`
  position: absolute;
  width: 100%;
  bottom: -7px;
  left: 0;
  height: 3px;
  border-radius: 2px;
  background: ${({ theme }) => theme.feedback.positive};
`;
