/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import styled, { keyframes } from 'styled-components';
import { VscLoading } from 'react-icons/vsc';

interface IContainer {
  block: boolean | undefined;
  danger: boolean | undefined;
  ghost: boolean | undefined;
  loading: boolean | undefined;
  neutral: boolean | undefined;
}

export const Container = styled.button<IContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 24px;
  border-radius: 50px;
  border: 0;
  font-size: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  font-weight: 500;
  transition: 0.2s;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  background-color: ${({ ghost, danger, theme }) =>
    ghost ? 'transparent' : danger ? theme.danger.base : theme.primary.base};
  border: 2px solid transparent;
  border-color: ${({ neutral, ghost, danger, theme }) =>
    neutral
      ? 'transparent'
      : !ghost
        ? 'transparent'
        : danger
          ? theme.danger.base
          : theme.primary.base};
  color: ${({ ghost, danger, theme }) =>
    !ghost ? '#fff' : danger ? theme.danger.base : theme.primary.base};
  opacity: ${({ loading }) => (loading ? 0.8 : 1)};

  a {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const spin = keyframes`
    from {
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
`;

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: scale(0);
    }
    to{
      opacity: 1;
      transform: scale(1);
    }
`;

export const Loading = styled(VscLoading)`
  animation: ${spin} infinite 1s linear, ${fadeIn} 0.2s ease-in;
  margin-right: 10px;
  opacity: 0.8;
`;
