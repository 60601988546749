import api from '../..';

const baseUrl = '/admin/categories';

interface IGetCategories {
  page?: number;
  limit?: number;
}

export const getCategories = async ({ page, limit }: IGetCategories) => {
  try {
    const { data } = await api.get(baseUrl, {
      params: {
        page,
        limit,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCategory = async (uuid: string) => {
  try {
    const { data } = await api.delete(`${baseUrl}/${uuid}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getCategory = async (uuid: string) => {
  try {
    const { data } = await api.get(`${baseUrl}/${uuid}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateCategory = async (uuid: string, content: any) => {
  try {
    const { data } = await api.put(`${baseUrl}/${uuid}`, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createCategory = async (content: any) => {
  try {
    const { data } = await api.post(baseUrl, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
