import React from 'react';
import { CSSProperties } from 'styled-components';

import { Container } from './styles';

interface IProps {
  style?: CSSProperties;
  children: React.ReactNode;
  to: string;
}

const Link: React.FC<IProps> = ({ children, style, to }) => {
  return (
    <Container style={style} to={to}>
      {children}
    </Container>
  );
};

export default Link;
