import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineChevronRight } from 'react-icons/hi';

import {
  TopMenu,
  LogoBox,
  SideMenu,
  MenuButton,
  Hamburguer,
  Menu,
  MenuItem,
} from './styles';

import logo from '../../assets/img/logo.svg';
import { useSession } from '../../context/SessionContext';

interface IProps {
  hide: boolean;
  toggleMenu: () => void;
}

const menuItems = [
  {
    link: '/',
    label: 'Estatísticas',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/evaluation',
    label: 'Avaliações',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/categories',
    label: 'Categorias',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/attributes',
    label: 'Atributos',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/products',
    label: 'Produtos',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/bids',
    label: 'Ordens',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/orders',
    label: 'Negociações',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/labels',
    label: 'Etiquetas',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/users',
    label: 'Usuários',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/admins',
    label: 'Administradores',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/platform',
    label: 'Plataforma',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/legal',
    label: 'Jurídico',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/checklists',
    label: 'Checklists',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/settings',
    label: 'Configurações',
    icon: <HiOutlineChevronRight />,
  },
  {
    link: '/finances',
    label: 'Financeiro',
    icon: <HiOutlineChevronRight />,
  },
];

const Navbar: React.FC<IProps> = ({ hide, toggleMenu }) => {
  const location = useLocation();
  const path = location.pathname.split('/');
  const [currentPath, setCurrentPath] = useState('/');
  const { logout } = useSession();

  useEffect(() => {
    setCurrentPath(path[1]);
  }, [path]);

  return (
    <>
      <TopMenu>
        <MenuButton onClick={toggleMenu}>
          <Hamburguer />
        </MenuButton>
        <LogoBox>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </LogoBox>
      </TopMenu>
      <SideMenu className={hide ? 'hide' : ''}>
        <Menu>
          {menuItems.map(item => (
            <MenuItem
              key={item.label}
              className={item.link === `/${currentPath}` && 'selected'}
            >
              <Link to={item.link}>
                {item.label}
                {item.icon}
              </Link>
            </MenuItem>
          ))}
          <MenuItem onClick={logout}>
            <Link to="/login">Sair</Link>
          </MenuItem>
        </Menu>
      </SideMenu>
    </>
  );
};

export default Navbar;
