import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/ui/Button';
import IF from '../../../components/ui/IF';
import Input from '../../../components/ui/Input';
import SingleUpload from '../../../components/ui/SingleUpload';
import Textarea from '../../../components/ui/Textarea/index';
import {
  getCommunity,
  setCommunity,
} from '../../../services/api/endpoints/community/index';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const CommunityForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const response = await getCommunity();
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      formRef.current.setFieldValue('image', data?.community?.imageUrl);
    }
  }, [data]);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      const { image } = formData;
      let content: any = {
        videoCode: formData.videoCode,
        community: {
          title: formData.title,
          description: formData.description,
          labelLink: formData.labelLink,
          link: formData.link,
        },
      };

      if (image) {
        const { path: url } = await fileUpload('image', formData.image);
        content = {
          ...content,
          community: {
            ...content.community,
            imageUrl: url,
          },
        };
      } else {
        content = {
          ...content,
          community: {
            ...content.community,
            imageUrl: data.imageUrl,
          },
        };
      }

      await setCommunity(content);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <IF condition={data !== null}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          videoCode: data?.videoCode,
          title: data?.community.title,
          description: data?.community.description,
          labelLink: data?.community.labelLink,
          link: data?.community.link,
        }}
      >
        <SingleUpload label="Imagem" name="image" accept=".png, .jpg, .jpeg" />

        <Input
          name="videoCode"
          label="Vídeo"
          prefix="https://youtu.be/"
          placeholder="video_id"
        />
        <Input
          name="title"
          label="Título de exibição"
          placeholder="Informe o título de exibição"
        />
        <Textarea
          name="description"
          label="Descrição"
          placeholder="Informe a descrição"
        />
        <Input
          name="labelLink"
          label="Label do Botão"
          placeholder="Informe a label do botão de redirecionamento"
        />
        <Input
          name="link"
          label="Link do Botão"
          placeholder="Informe o link do botão de redirecionamento"
        />
        <Button type="submit" loading={loading}>
          Salvar
        </Button>
      </Form>
    </IF>
  );
};

export default CommunityForm;
