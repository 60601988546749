import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import InputMask from '../../../components/ui/InputMask';
import { createAdmin } from '../../../services/api/endpoints/admin';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const NewAdminForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      await createAdmin(formData);
      formRef.current.reset();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input
        name="name"
        label="Nome Completo"
        placeholder="Informe o nome do colaborador"
      />
      <Input
        name="email"
        label="Email"
        placeholder="Informe o email do colaborador"
      />
      <InputMask
        name="cpf"
        label="CPF"
        placeholder="Informe o CPF do colaborador"
        mask="999.999.999-99"
      />
      <Button loading={loading} type="submit">
        Cadastrar
      </Button>
    </Form>
  );
};

export default NewAdminForm;
