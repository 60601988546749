import styled from 'styled-components';
import { MdDragHandle } from 'react-icons/md';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';

export const Container = styled.div`
  box-shadow: 0 0 5px ${({ theme }) => theme.blackOpacity(0.1)};
  border-radius: 2px;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 5px;
`;

export const Description = styled.p`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  margin: 0;
  max-width: 100%;
  white-space: nowrap;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.gray.dark};
`;

export const DragIcon = styled(MdDragHandle)`
  fill: ${({ theme }) => theme.gray.mediumLight};
  cursor: move;
`;

export const UpdateIcon = styled(AiOutlineEdit)`
  fill: ${({ theme }) => theme.feedback.positive};
  cursor: pointer;
`;

export const DeleteIcon = styled(AiOutlineDelete)`
  fill: ${({ theme }) => theme.feedback.negative};
  cursor: pointer;
`;
