import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/ui/Button';
import SingleUpload from '../../../components/ui/SingleUpload';
import {
  ITermTypes,
  setTerms,
} from '../../../services/api/endpoints/terms/index';
import { fileUpload } from '../../../services/api/endpoints/fileUpload/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

interface IProps extends ITermTypes {
  initialData?: string;
}

const DocumentForm: React.FC<IProps> = ({ type, initialData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (initialData) {
      formRef.current.setData({ file: initialData });
    }
  }, [initialData]);

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      let name;
      switch (type) {
        case 'condition':
          name = 'terms-conditions';
          break;
        case 'privacy':
          name = 'privacy';
          break;
        case 'purchase':
          name = 'terms-buy';
          break;
        case 'sale':
          name = 'terms-sell';
          break;
        default:
          break;
      }

      const { path } = await fileUpload('file', data?.file, name);
      await setTerms({ type }, path);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{ file: initialData }}
    >
      <SingleUpload
        name="file"
        label="Insira o arquivo"
        accept=".pdf"
        type="file"
        uploadType="file"
      />
      <Button loading={loading} type="submit">
        Salvar
      </Button>
    </Form>
  );
};

export default DocumentForm;
