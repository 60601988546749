/* eslint-disable prettier/prettier */
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  input{
    width: 100%;
    background: ${({ theme }) => theme.gray.extraLight};
    padding: 7px 15px;
    color: ${({ theme }) => theme.gray.dark};
    border-radius: 3px;
    -webkit-appearance: none;
    border: 1px solid transparent;
    font-size: 1rem;

    &.error {
      border: 1px solid ${({ theme }) => theme.danger.base};
    }
  }
`;


interface ILabel {
  htmlFor: string;
}

export const Label = styled.span<ILabel>`
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.9rem;
  display: block;
  margin-bottom: .5rem;
`;

const fadeError = keyframes`
  from{
    opacity: 0;
    transform: translateY(-5px);
  }

  to{
    opacity: 1;
    transform: translateY(0);
  }
`

export const Error = styled.span`
  color: ${({ theme }) => theme.danger.base};
  font-size: 0.9rem;
  font-weight: 400;
  transition: .2s;
  animation: ${fadeError} linear 500ms;
  display: block;
  margin-bottom: 0.5rem;

  span{
    color: transparent;
  }
`;
