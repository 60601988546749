import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { header } from '../constants';
import Paragraph from '../../../components/ui/Paragraph';
import Paginate from '../../../components/ui/Paginate';
import Table from '../../../components/ui/Table';
import { deleteAdmin, getAdmins } from '../../../services/api/endpoints/admin';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const AdminsTable: React.FC = () => {
  const [data, setData] = useState<any[]>([]);

  const getData = async () => {
    try {
      const response = await getAdmins();
      response.map((item: any) => {
        item.id = item.uuid;
        item.data = {
          name: { label: item.name },
          email: { label: item.email },
          delete: { render: renderDelete(item.uuid) },
        };
        return true;
      });
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const openDelete = async (param: string) => {
    try {
      await deleteAdmin(param);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderDelete = (id: string) => {
    return (
      <Paragraph danger type="link" onClick={() => openDelete(id)}>
        Excluir
      </Paragraph>
    );
  };

  const handlePage = ({ selected }: { selected: number }) => {
    console.log(selected);
  };

  return (
    <>
      <Table header={header} body={data} />

      {/* <Paginate
        pageCount={10}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        initialPage={0}
        onPageChange={handlePage}
      /> */}
    </>
  );
};

export default AdminsTable;
