import React from 'react';
import { Wrapper, ImageColumn, Container, Content } from './styles';

interface IProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<IProps> = ({ children }) => {
  return (
    <Container>
      <ImageColumn />
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </Container>
  );
};

export default AuthLayout;
