import React, { useCallback } from 'react';
import update from 'immutability-helper';
import { DNDCard } from './DNDCard';

export interface Item {
  uuid: any;
  primaryText: string;
}

export interface ContainerState {
  cards: Item[];
}

export interface IProps {
  items: Item[];
  onChange: (items: Item[]) => void;
  editable?: boolean;
  deletable?: boolean;
  onEdit?: (id: string | number) => void;
  onDelete?: (id: string | number) => void;
}

const DNDList: React.FC<IProps> = ({
  items,
  onChange,
  editable = false,
  deletable = false,
  onDelete,
  onEdit,
}) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = items[dragIndex];
      onChange(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [items],
  );

  const renderCard = (
    card: { uuid: any; primaryText: string },
    index: number,
  ) => {
    return (
      <DNDCard
        key={card.uuid}
        index={index}
        id={card.uuid}
        text={card.primaryText}
        moveCard={moveCard}
        editable={editable}
        deletable={deletable}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    );
  };

  return (
    <>
      <div>{items.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};

export default DNDList;
