import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GlobalStyle from './styles';
import Routes from './routes';
import { theme } from './styles/theme';
import { SessionProvider } from './context/SessionContext';

const App: React.FC = () => {
  return (
    <>
      <SessionProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
              <GlobalStyle />
              <ToastContainer autoClose={3000} />
              <Routes />
            </DndProvider>
          </ThemeProvider>
        </Router>
      </SessionProvider>
    </>
  );
};

export default App;
