import api from '../..';

const baseUrl = '/admin/attribute';

interface IGetAttributes {
  page?: number;
  limit?: number;
}

export const getAttributes = async ({ page, limit }: IGetAttributes) => {
  const { data } = await api.get('/admin/attributes', {
    params: {
      page,
      limit,
    },
  });
  return data;
};

export const createAttributes = async (body: any) => {
  const { data } = await api.post(baseUrl, body);
  return data;
};

export const getAttributeItems = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl}/${uuid}/items`);
  return data;
};

export const createAttributeItem = async (uuid: string, body: any) => {
  const { data } = await api.post(`${baseUrl}/${uuid}/items`, body);
  return data;
};

export const deleteAttribute = async (uuid: string | number) => {
  const { data } = await api.delete(`${baseUrl}/${uuid}`);
  return data;
};

export const deletePermanentlyAttribute = async (uuid: string | number) => {
  const { data } = await api.delete(`${baseUrl}/${uuid}/permanent`);
  return data;
};

export const deleteAttributeItem = async (uuid: string | number) => {
  const { data } = await api.delete(`${baseUrl}/items/${uuid}`);
  return data;
};

export const deletePermanentlyAttributeItem = async (uuid: string | number) => {
  const { data } = await api.delete(`${baseUrl}/items/${uuid}/permanent`);
  return data;
};

export const updateAttributeItem = async (
  uuid: string | number,
  code: string,
) => {
  const { data } = await api.put(`${baseUrl}/items/${uuid}`, { code });
  return data;
};
