/* eslint-disable prettier/prettier */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { MdStar, MdStarBorder } from 'react-icons/md';
import { Link as RouterLink } from 'react-router-dom';

import { toast } from 'react-toastify';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Table from '../../components/ui/Table/index';
import Paginate from '../../components/ui/Paginate';
import Input from '../../components/ui/Input';
import Link from '../../components/ui/Link';
import { theme } from '../../styles/theme';
import Button from '../../components/ui/Button';
import { getProducts, handleFeaturedProduct, deleteProduct } from '../../services/api/endpoints/products/index';
import useDebounce from '../../hooks/useDebounce';
import Paragraph from '../../components/ui/Paragraph';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';

const style = {
  link: {
    color: theme.primary.base,
    cursor: 'pointer' as const
  },
  title: {
    marginBottom: '.5rem'
  },
  routerLink: {
    display: 'inline-block' as const
  }
};

const Products: React.FC = () => {
  const [productsData, setProductsData] = useState<any[]>([]);
  const searchRef = useRef<FormHandles>(null);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');

  const handleFilter = () => {
    console.log('');
  };

  const getProductsData = async (p: number, f: string) => {
    try {
      const { data, pagination } = await getProducts(p, f);
      setTotalPage(pagination?.totalPages);
      data.map(item => {
        item.data = {
          id: {
            id: 0,
            label: item.uuid,
          },
          name: {
            id: 1,
            label: item.name,
          },
          category: {
            id: 2,
            label: item.category,
          },
          brand: {
            id: 3,
            label: item.brand,
          },
          skus: {
            id: 4,
            label: item?.skusAmount,
          },
          bids: {
            id: 5,
            label: item.bidsamount,
          },
          offers: {
            id: 6,
            label: item.offersamount,
          },
          featured1: {
            id: 7,
            render: renderFeatured(item.uuid, item.featuredprimary, 'primary'),
          },
          featured2: {
            id: 8,
            render: renderFeatured(item.uuid, item.featuredsecondary, 'secondary'),
          },
          edit: {
            id: 9,
            render: renderEdit(item.uuid),
          },
          delete: {
            id: 10,
            render: renderDelete(item.uuid),
          },
        }
        return true;
      })

      setProductsData(data);
    } catch (err) {
      console.log(err);
    }
  }

  const handlePage = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const renderEdit = (id: string | number) => {
    return (
      <Link style={style.link} to={`/products/edit/${id}`}>
        Editar
      </Link>
    );
  };

  const handleDelete = async (id: string | number) => {
    try {
      await deleteProduct(`${id}`);
      toast.success(SUCCESS_MESSAGE);
      getProductsData(page, filter);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderDelete = (id: string | number) => {
    return (
      <Paragraph style={{marginBottom: 0}} type="link" danger onClick={()=> handleDelete(id)}>
        Excluir
      </Paragraph>
    );
  };

  const handleFeatured = async (uuid: string | number, type: 'primary' | 'secondary') => {
    try {
      await handleFeaturedProduct(uuid, type);
      getProductsData(page, filter);
    } catch (err) {
      console.log(err)
    }
  }

  const renderFeatured = (id: string | number, label: boolean, type: 'primary' | 'secondary') => {
    return (
      <div onClick={() => { handleFeatured(id, type) }}>
        {label ? <MdStar style={style.link} /> : <MdStarBorder style={style.link} />}
      </div>
    )
  };

  useEffect(() => {
    getProductsData(page, filter);
  }, [page, filter]);

  const getFilteredProducts = (value: string) => {
    setFilter(value);
    setPage(1);
  };

  const debouncedFilter = useDebounce(getFilteredProducts, 500);

  const handleSearch = (value: any) => {
    debouncedFilter(value);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12} md={8} lg={10}>
          <Title style={style.title}>Produtos</Title>
          <RouterLink to="/products/new" style={style.routerLink}>
            <Button primary>Novo Produto</Button>
          </RouterLink>
        </Col>
        <Col xs={12}>
          <Card>
            <Form onSubmit={handleFilter} ref={searchRef}>
              <Input placeholder="Buscar por nome" name="search" onChange={handleSearch} />
            </Form>
            <Table header={header} body={productsData} />
            <Paginate
              pageCount={totalPage}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              onPageChange={handlePage}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
