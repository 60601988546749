import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../../../components/ui/Button';
import Divisor from '../../../../components/ui/Divisor';
import Modal from '../../../../components/ui/Modal';
import SingleUpload from '../../../../components/ui/SingleUpload';
import TextEditor from '../../../../components/ui/TextEditor';
import Title from '../../../../components/ui/Title';
import { fileUpload } from '../../../../services/api/endpoints/fileUpload';
import {
  createBanner,
  updateBanner,
} from '../../../../services/api/endpoints/homeBanner';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../utils/toastMessage';
import {
  newBannerSchema,
  updateBannerSchema,
} from '../../../../utils/validators/banner';

interface IProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  getData: () => void;
  banner?: any;
}

const BannerModal: React.FC<IProps> = ({
  visible,
  setVisible,
  banner,
  getData,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (banner) {
      formRef.current.setData({
        image: banner?.media.url,
        primaryText: banner?.primaryText,
        secundaryText: banner?.secundaryText || banner?.secondaryText,
      });
    }
  }, [banner]);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      const { primaryText, secundaryText } = formData;
      formRef.current.setErrors({});

      if (banner) {
        await updateBannerSchema.validate(formData, {
          abortEarly: false,
        });

        let content: any = {
          primaryText,
          secundaryText,
        };
        if (formData.image) {
          const { path: url } = await fileUpload('image', formData.image);
          content = {
            ...content,
            media: { type: 'image', url },
          };
        } else {
          content = {
            ...content,
            media: banner.media,
          };
        }

        await updateBanner(content, banner.uuid);
      } else {
        await newBannerSchema.validate(formData, {
          abortEarly: false,
        });

        const { path: url } = await fileUpload('image', formData.image);

        await createBanner({
          primaryText,
          secundaryText,
          media: {
            type: 'image',
            url,
          },
        });
      }
      getData();
      setVisible(false);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(ERROR_MESSAGE);
      }
    }
    setLoading(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Title level={3}>{`${banner ? 'Editar' : 'Novo'} Banner`}</Title>
      <Divisor />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          primaryText: banner?.primaryText,
          secundaryText: banner?.secundaryText || banner?.secondaryText,
        }}
      >
        <SingleUpload label="Imagem" name="image" accept=".jpg, .jpeg, .png" />
        <TextEditor name="primaryText" label="Texto Primário" />
        <TextEditor name="secundaryText" label="Texto Secundário" />

        <Button type="submit" block loading={loading}>
          {banner || banner >= 0 ? 'Atualizar' : 'Adicionar'}
        </Button>
      </Form>
    </Modal>
  );
};

export default BannerModal;
