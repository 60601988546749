import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Chart from '../../components/Chart';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import Paragraph from '../../components/ui/Paragraph';
import Row from '../../components/ui/Row';
import Statistic from '../../components/ui/Statistic';
import Title from '../../components/ui/Title';
import { Container } from './styles';
import {
  getProductBidsStats,
  getProductOfferStats,
  getCategoryOfferStats,
  getCategoryBidsStats,
  getStats,
  getBestSellers,
} from '../../services/api/endpoints/stats/index';
import IF from '../../components/ui/IF';
import createChartData from '../../utils/createChartData/index';
import Button from '../../components/ui/Button';
import { ERROR_MESSAGE } from '../../utils/toastMessage';
import { getCsv } from '../../services/api/endpoints/exportCsv';
import { formatPrice } from '../../utils/format';

const style = {
  title: {
    marginBottom: '1.5rem',
  },
  cardTitle: {
    marginBottom: '1.5rem',
    textAlign: 'center' as const,
  },
  card: {
    height: '100%',
  },
};

interface IXAxis {
  categories: string[];
}
interface ISerie {
  name: string;
  data: number[];
}

interface IChart {
  xaxis: IXAxis;
  series: ISerie[];
}

const Home: React.FC = () => {
  const [stats, setStats] = useState<any>();
  const [productOfferStatsData, setProductOfferStatsData] = useState<IChart>();
  const [productBidStatsData, setProductBidStatsData] = useState<IChart>();
  const [categoryOfferStatsData, setCategoryOfferStatsData] =
    useState<IChart>();
  const [categoryBidStatsData, setCategoryBidStatsData] = useState<IChart>();
  const [bestSellersData, setBestSellersData] = useState<IChart>();

  const getData = async () => {
    const statsData = await getStats();
    const productOfferStats = await getProductOfferStats();
    const productBidsStats = await getProductBidsStats();
    const categoryOfferStats = await getCategoryOfferStats();
    const categoryBidsStats = await getCategoryBidsStats();
    const bestSellers = await getBestSellers();
    setStats(statsData);
    setBestSellersData(
      createChartData(bestSellers.xAxis, bestSellers.yAxis, 'Quantidade'),
    );
    setProductOfferStatsData(
      createChartData(
        productOfferStats.xAxis,
        productOfferStats.yAxis,
        'Quantidade',
      ),
    );
    setProductBidStatsData(
      createChartData(
        productBidsStats.xAxis,
        productBidsStats.yAxis,
        'Quantidade',
      ),
    );
    setCategoryOfferStatsData(
      createChartData(
        categoryOfferStats.xAxis,
        categoryOfferStats.yAxis,
        'Quantidade',
      ),
    );
    setCategoryBidStatsData(
      createChartData(
        categoryBidsStats.xAxis,
        categoryBidsStats.yAxis,
        'Quantidade',
      ),
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const [loadingCsv, setLoadingCsv] = useState<boolean>(false);

  const exportData = async () => {
    try {
      setLoadingCsv(true);
      const data = await getCsv('stats');
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      const csvURL = window.URL.createObjectURL(blob);
      window.open(csvURL);
    } catch (err) {
      toast.error(ERROR_MESSAGE);
    }
    setLoadingCsv(false);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12} md={8} lg={10}>
          <Title style={style.title}>Visão Geral</Title>
        </Col>
        <Col xs={12} md={4} lg={2}>
          <Button onClick={exportData} loading={loadingCsv}>
            Exportar CSV
          </Button>
        </Col>
      </Row>
      <Row colGap={16} rowGap={16}>
        <Col xs={12} md={11} xl={10} xxl={9}>
          <Row colGap={16} rowGap={16}>
            <Col xs={12} md={6}>
              <Card style={style.card}>
                <Paragraph style={style.cardTitle} level={3}>
                  Ofertas Cadastradas por Produto
                </Paragraph>
                <IF condition={!!productOfferStatsData}>
                  <Chart
                    type="bar"
                    series={productOfferStatsData?.series}
                    xaxis={productOfferStatsData?.xaxis}
                  />
                </IF>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card style={style.card}>
                <Paragraph style={style.cardTitle} level={3}>
                  Ofertas Cadastradas por Categoria
                </Paragraph>
                <IF condition={!!categoryOfferStatsData}>
                  <Chart
                    type="donut"
                    series={categoryOfferStatsData?.series}
                    xaxis={categoryOfferStatsData?.xaxis}
                  />
                </IF>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card style={style.card}>
                <Paragraph style={style.cardTitle} level={3}>
                  Lances Cadastrados por Produto
                </Paragraph>
                <IF condition={!!productBidStatsData}>
                  <Chart
                    type="bar"
                    series={productBidStatsData?.series}
                    xaxis={productBidStatsData?.xaxis}
                  />
                </IF>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card style={style.card}>
                <Paragraph style={style.cardTitle} level={3}>
                  Lances Cadastrados por Categoria
                </Paragraph>
                <IF condition={!!categoryBidStatsData}>
                  <Chart
                    type="donut"
                    series={categoryBidStatsData?.series}
                    xaxis={categoryBidStatsData?.xaxis}
                  />
                </IF>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card style={style.card}>
                <Paragraph style={style.cardTitle} level={3}>
                  Itens mais Vendidos
                </Paragraph>
                <IF condition={!!bestSellersData}>
                  <Chart
                    type="pie"
                    series={bestSellersData?.series}
                    xaxis={bestSellersData?.xaxis}
                  />
                </IF>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={1} xl={2} xxl={3}>
          <Row colGap={16} rowGap={16}>
            <Col xs={12} xxl={6}>
              <Card style={style.card}>
                <Statistic
                  label="Tempo Médio de Match"
                  suffix={stats?.matchTime?.label}
                  data={stats?.matchTime?.value}
                />
              </Card>
            </Col>
            <Col xs={12} xxl={6}>
              <Card style={style.card}>
                <Statistic
                  data={formatPrice(stats?.averagePrice?.value).replace(
                    'R$',
                    '',
                  )}
                  prefix="R$"
                  label="Ticket Médio"
                />
              </Card>
            </Col>
            <Col xs={12} xxl={6}>
              <Card style={style.card}>
                <Statistic
                  data={stats?.returnRate?.value}
                  suffix="%"
                  label="Taxa de Devolução"
                  type="negative"
                />
              </Card>
            </Col>
            <Col xs={12} xxl={6}>
              <Card style={style.card}>
                <Statistic
                  data={stats?.offerCancels?.value}
                  suffix="%"
                  label="Abandono de Ofertas"
                  type="negative"
                />
              </Card>
            </Col>
            <Col xs={12} xxl={6}>
              <Card style={style.card}>
                <Statistic
                  data={stats?.bidCancels?.value}
                  suffix="%"
                  label="Abandono de Lances"
                  type="negative"
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
