import styled, { css } from 'styled-components';

interface IParagraph {
  type?: 'link' | 'text';
  bold?: boolean;
  danger?: boolean;
  primary?: boolean;
  [key: string]: any;
}

const Paragraph = styled.p<IParagraph>`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.gray.dark};

  ${({ type }) =>
    type === 'link'
      ? css`
          color: ${({ theme }) => theme.gray.mediumDark};
          text-decoration: underline;
          cursor: pointer;
          display: inline-block;
          &:hover {
            opacity: 0.8;
          }
        `
      : ``};

  ${({ bold }) =>
    bold &&
    css`
      font-weight: bolder;
    `};

  ${({ danger }) =>
    danger &&
    css`
      color: ${({ theme }) => theme.feedback.negative};
    `};
  ${({ primary }) =>
    primary &&
    css`
      color: ${({ theme }) => theme.primary.base};
    `};
`;

export default Paragraph;
