import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import Divisor from '../../../components/ui/Divisor';
import Modal from '../../../components/ui/Modal';
import Title from '../../../components/ui/Title';
import SingleUpload from '../../../components/ui/SingleUpload';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import {
  getCategory,
  createCategory,
  updateCategory,
} from '../../../services/api/endpoints/categories/index';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

interface IProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  onClose: () => void;
  category?: any;
}

const CategoryModal: React.FC<IProps> = ({
  visible,
  setVisible,
  category,
  onClose,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const response = await getCategory(category);
      formRef.current.setData({
        name: response.name,
        image: response.imageUrl,
      });
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (category) {
      getData();
    }
  }, [category]);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      const { name, image } = formData;
      let content: any = { name };

      if (image) {
        const { path: imageUrl } = await fileUpload('image', image);
        content = { ...content, imageUrl };
      } else {
        content = { ...content, imageUrl: data.imageUrl };
      }
      if (category) {
        await updateCategory(category, content);
      } else {
        await createCategory(content);
      }
      onClose();
      setVisible(false);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Title level={3}>
        {`${category || category >= 0 ? 'Editar' : 'Nova'} Categoria`}
      </Title>
      <Divisor />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <SingleUpload label="Imagem" name="image" accept=".jpg, .jpeg, .png" />
        <Input
          name="name"
          label="Nome da Categoria"
          placeholder="Por favor, informe o nome da categoria"
        />
        <Button type="submit" block loading={loading}>
          {category || category >= 0 ? 'Atualizar' : 'Adicionar'}
        </Button>
      </Form>
    </Modal>
  );
};

export default CategoryModal;
