export const header = [
  {
    id: 0,
    label: 'ID',
    key: 'idBid',
  },
  {
    id: 4,
    label: 'SKU',
    key: 'skuId',
  },
  {
    id: 5,
    label: 'Status',
    key: 'status',
  },
  {
    id: 1,
    label: 'Vendedor',
    key: 'ownerId',
  },
  {
    id: 10,
    label: 'Rastreio',
    key: 'shipping1',
  },
  {
    id: 3,
    label: 'Comprador',
    key: 'buyerId',
  },
  {
    id: 1,
    label: 'Rastreio',
    key: 'shipping2',
  },
  {
    id: 2,
    label: 'Data',
    key: 'date',
  },
  {
    id: 7,
    label: 'Checklist',
    key: 'checklist',
  },
  {
    id: 8,
    label: 'Laudo',
    key: 'techReport',
  },
  {
    id: 9,
    label: 'Reembolsar',
    key: 'cancel',
  },
  {
    id: 12,
    label: 'Finalizar',
    key: 'finish',
  },
];

export const filter = [
  {
    id: 1,
    label: 'Status',
    value: 'status',
  },
  {
    id: 4,
    label: 'Data',
    value: 'date',
  },
  {
    id: 8,
    label: 'Valor',
    value: 'value',
  },
];
