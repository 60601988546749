import api from '../..';

const baseUrl = '/admin/evaluations';

export const getEvaluations = async () => {
  const { data } = await api.get(baseUrl);
  return data;
};

export const approveEvaluation = async (uuid: string) => {
  const { data } = await api.post(`${baseUrl}/${uuid}/approve`);
  return data;
};

export const rejectEvaluation = async (uuid: string) => {
  const { data } = await api.post(`${baseUrl}/${uuid}/reject`);
  return data;
};

export const setEvaluationReport = async (uuid: string, report: string) => {
  const { data } = await api.post(`${baseUrl}/${uuid}/report`, { report });
  return data;
};
