import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import api from '../../../services/api';

import Divisor from '../../../components/ui/Divisor';
import Modal from '../../../components/ui/Modal';
import Title from '../../../components/ui/Title';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import Select from '../../../components/ui/Select';

import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';
import { Content } from '../../../components/ui/Modal/styles';

interface IProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  onClose: () => void;
  bids?: any;
}

const BidsModal: React.FC<IProps> = ({
  visible,
  setVisible,
  bids,
  onClose,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarVisible, setCalendarVisible] = useState<boolean>(false);

  const statusMap = {
    canceled: 'Cancelado',
    available: 'Disponível',
    reject: 'Rejeitado',
    expired: 'Expirado',
    'pre-match': 'Aguardando confirmação',
    match: 'Match',
  };

  const getDay = (days: string) => {
    const date = new Date();
    date.setDate(date.getDate() + Number(days));
    return format(date, 'dd/MM/yyyy');
  };

  const updateBid = async (bid, statusRef, dateRef, valueRef) => {
    if (statusRef && dateRef && valueRef) {
      const findStatus = Object.keys(statusMap).find(
        key => statusMap[key] === statusRef,
      );

      const date = dateRef.split('/');
      const fn = new Date(date[2], date[1] - 1, date[0]);
      const getDate = fn.getTime();

      const isoDate = new Date(getDate).toISOString();

      const getValue = Number(valueRef.replace('.', '').replace(',', ''));

      try {
        await api.put(`admin/bid`, {
          bidId: bid.uuid,
          status: findStatus,
          expiration_date: isoDate,
          value: getValue,
          oldValue: bid.value,
        });
        toast.success('Atualizado com sucesso', {
          position: 'top-center',
          autoClose: 2300,
        });
        window.location.reload();
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data || 'Falha ao atualizar', {
          position: 'top-center',
          autoClose: 2300,
        });
      }
    }
  };

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      const { status, dtExpiracao, Valor } = formData;
      await updateBid(bids, status, dtExpiracao, Valor);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE, {
        position: 'top-center',
        autoClose: 2300,
      });
    }
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    const day = format(date, 'dd/MM/yyyy');
    formRef.current?.setFieldValue('dtExpiracao', day);
    setCalendarVisible(false);
  };

  return (
    <>
      <Modal visible={visible} setVisible={setVisible}>
        <Title level={3}>
          {`${bids || bids >= 0 ? 'Editar' : 'Nova'} Ordem: #${bids?.id} | ${
            bids?.skuId
          } | ${bids?.bidtype}`}
        </Title>
        <Divisor />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Select
            name="status"
            label="Status"
            options={[
              {
                value: bids?.status,
                label: bids?.status,
              },
              {
                value: 'Disponível',
                label: 'Disponível',
              },
              {
                value: 'Cancelado',
                label: 'Cancelado',
              },
              {
                value: 'Rejeitado',
                label: 'Rejeitado',
              },
              {
                value: 'Match',
                label: 'Match',
              },
              {
                value: 'Expirado',
                label: 'Expirado',
              },
              {
                value: 'Aguardando confirmação',
                label: 'Aguardando confirmação',
              },
            ]}
          />
          <Input
            name="lancamento"
            label="Data de Lançamento"
            placeholder="dd/mm/aaaa"
            defaultValue={bids.date}
            disabled
          />
          <Input
            name="opentime"
            label="Tempo em Aberto"
            placeholder="dd/mm/aaaa"
            defaultValue={`${bids.opentime} dias`}
            disabled
          />
          <Input
            name="dtExpiracao"
            label="Data de expiração"
            placeholder="dd/mm/aaaa"
            defaultValue={getDay(bids.expiretime || '0')}
            onClick={() => setCalendarVisible(true)}
          />
          <Input name="Valor" label="Valor" defaultValue={bids?.value} />
          <Button type="submit" block loading={loading}>
            {bids || bids >= 0 ? 'Atualizar' : 'Adicionar'}
          </Button>
        </Form>
      </Modal>
      <Modal visible={calendarVisible} setVisible={setCalendarVisible}>
        <Content
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'transparent',
          }}
        >
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            locale="pt-BR"
          />
        </Content>
      </Modal>
    </>
  );
};

export default BidsModal;
