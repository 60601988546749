/* eslint-disable prettier/prettier */
import { ApexOptions } from 'apexcharts';
import React from 'react';
import ChartComponent from 'react-apexcharts';
import { palette } from '../../styles/theme';
import IF from '../ui/IF';

const options: ApexOptions = {
  chart: {
    id: 'basic-bar',
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: 'smooth'
  },
  colors: [palette.green.main, palette.green.shades[0], ...palette.green.shades.reverse(), palette.green.main, ...palette.green.shades.reverse()],
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: 'solid',
  },
};

interface ISeries {
  name?: string;
  data: number[];
}
interface IXAxis {
  categories: string[];
}

interface IProps {
  series: ISeries[];
  xaxis: IXAxis;
  type?:
  | 'line'
  | 'area'
  | 'bar'
  | 'histogram'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'treemap'
  | 'boxPlot'
  | 'candlestick'
  | 'radar'
  | 'polarArea'
  | 'rangeBar';
}


const Chart: React.FC<IProps> = ({ series, xaxis, type }) => {
  return (
    <>
      <IF condition={!!series}>
        <ChartComponent
          options={{ ...options, labels: xaxis.categories }}
          series={type === 'donut' || type === 'pie' ? series[0].data : series}
          type={type}
          width="100%"
          height={300}
        />
      </IF>
    </>
  );
};

export default Chart;
