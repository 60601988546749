export const header = [
  {
    id: 0,
    label: 'ID',
    key: 'id',
  },
  {
    id: 1,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 2,
    label: 'CPF',
    key: 'cpf',
  },
  {
    id: 3,
    label: 'Celular',
    key: 'phone',
  },
  // {
  //   id: 5,
  //   label: 'Endereço',
  //   key: 'address',
  // },
  // {
  //   id: 6,
  //   label: 'Dados Bancários',
  //   key: 'bankData',
  // },
  {
    id: 7,
    label: 'Negociações',
    key: 'bidsAmount',
  },
  // {
  //   id: 8,
  //   label: 'Produtos',
  //   key: 'products',
  // },
  {
    id: 9,
    label: 'Data de Início',
    key: 'startDate',
  },
  {
    id: 10,
    label: 'Bloquear',
    key: 'block',
  },
  {
    id: 11,
    label: 'Excluir',
    key: 'delete',
  },
];

export const filter = [
  {
    id: 1,
    label: 'Nome',
    value: 'name',
  },
  {
    id: 2,
    label: 'Negociações',
    value: 'orders',
  },
  {
    id: 3,
    label: 'Data de Início',
    value: 'startTime',
  },
];
