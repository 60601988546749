import api from '../..';

const baseUrl = '/admin/banner';

export const getBanners = async () => {
  try {
    const { data } = await api.get(baseUrl);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createBanner = async (body: any) => {
  try {
    const { data } = await api.post(baseUrl, body);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateBanner = async (body: any, uuid: string) => {
  try {
    const { data } = await api.put(`${baseUrl}/${uuid}`, body);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const setBannersOrder = async (body: any) => {
  try {
    const { data } = await api.put(`${baseUrl}/order`, body);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteBanner = async (uuid: string) => {
  try {
    const { data } = await api.delete(`${baseUrl}/${uuid}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
