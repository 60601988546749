import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';

import Title from '../../components/ui/Title';
import {
  approveEvaluation,
  getEvaluations,
  rejectEvaluation,
} from '../../services/api/endpoints/evaluations';
import EvaluationCard from './EvaluationCard/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';
import IF from '../../components/ui/IF';
import Paragraph from '../../components/ui/Paragraph';

const Evaluation: React.FC = () => {
  const [evaluations, setEvaluations] = useState<any>([]);

  const getData = async () => {
    try {
      const data = await getEvaluations();
      setEvaluations(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const openInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const handleApprove = async (uuid: string) => {
    try {
      const { url } = await approveEvaluation(uuid);
      openInNewTab(url);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const handleDecline = async (uuid: string) => {
    try {
      const { url } = await rejectEvaluation(uuid);
      openInNewTab(url);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title>Avaliações Pendentes</Title>
        </Col>
        {evaluations.map(item => (
          <Col xs={12} md={6} lg={4} xl={3} xxl={2} key={item.id}>
            <EvaluationCard
              data={item}
              onApprove={handleApprove}
              onDecline={handleDecline}
            />
          </Col>
        ))}
        <IF condition={!evaluations || evaluations?.length === 0}>
          <Col xs={12}>
            <Paragraph>Não existem avaliações pendentes</Paragraph>
          </Col>
        </IF>
      </Row>
    </Container>
  );
};

export default Evaluation;
