import api from '../..';

const baseUrl = '/admin/address';

export const getAddress = async () => {
  try {
    const { data } = await api.get(baseUrl);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateAddress = async (content: any) => {
  try {
    const { data } = await api.put(baseUrl, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
