/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';
import { CSSProperties } from 'styled-components';
import { AiOutlinePaperClip } from 'react-icons/ai';
import {
  Container,
  Error,
  Label,
  StyledInput,
  InputLabel,
  Loading,
  Filename,
  DeleteFile,
} from './styles';
import IF from '../IF';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';

interface Props {
  name: string;
  label?: string;
  accept?: string;
  uploadOnChange?: boolean;
  uploadType?: 'file' | 'image';
  style?: {
    input?: CSSProperties;
    label?: CSSProperties;
  };
}

interface ILoading {
  status: boolean;
  progress: number;
  error?: boolean;
  type?: 'image' | 'file';
}

interface IPreview {
  name: string;
  url: string;
  file: any;
  progress?: number;
  status?: boolean;
  error?: boolean;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const MultipleUpload: React.FC<InputProps> = ({
  name,
  label,
  style,
  accept = '*',
  uploadOnChange,
  uploadType = 'image',
  type = 'image',
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue = [], registerField, error } = useField(name);
  const [filenames, setFilenames] = useState<IPreview[]>(defaultValue);
  const [loading, setLoading] = useState<ILoading>(null);

  const handlePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files.length === 0) {
      setFilenames([]);
      return;
    }
    if (uploadOnChange) {
      handleUpload(files);
    }

    const newFiles = Object.keys(files).map(index => ({
      name: files[index].name,
      file: files[index],
      url: URL.createObjectURL(files[index]),
      progress: 0,
    }));

    setFilenames([...filenames, ...newFiles]);
  }, []);

  const trackUpload = (progress: number, total: number) => {
    setLoading({
      progress: (progress * 100) / total,
      status: true,
      error: false,
    });
  };

  const handleUpload = async (file: any) => {
    try {
      await fileUpload(uploadType, file, '', trackUpload);
    } catch (err) {
      console.log(err);

      setLoading({
        progress: 0,
        status: false,
        error: true,
      });
    }
  };

  const handleRemove = (file: string) => {
    const index = filenames.findIndex(item => item.name === file);
    if (index >= 0) {
      const files = [...filenames];

      files.splice(index, 1);

      setFilenames(files);
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files',
      clearValue(ref: HTMLInputElement) {
        ref.value = '[]';
        setFilenames([]);
      },
      setValue(_: HTMLInputElement, value: IPreview[]) {
        setFilenames(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <IF condition={!!label}>
        <Label htmlFor={fieldName}>{label}</Label>
      </IF>
      <InputLabel
        className={error || loading?.error ? 'error' : ''}
        type={type}
        style={style?.label}
      >
        <StyledInput
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          style={style?.input}
          type="file"
          accept={accept}
          onChange={handlePreview}
          multiple
          {...rest}
        />
        + Adicionar Arquivos
      </InputLabel>

      {filenames.map((file, i) => (
        <Filename key={`${file.name}-${i}`}>
          <a href={file.url} target="_blank" rel="noreferrer">
            <AiOutlinePaperClip />
            {` ${file.name}`}
          </a>
          <DeleteFile onClick={() => handleRemove(file.name)} />
        </Filename>
      ))}

      <IF condition={!!error}>
        <Error>{error}</Error>
      </IF>
      <IF condition={!error}>
        <Error>
          <span>.</span>
        </Error>
      </IF>
    </Container>
  );
};

export default MultipleUpload;
