import React, { useState, useEffect } from 'react';

import Paragraph from '../../../components/ui/Paragraph';

// import { Container } from './styles';
import Modal from '../../../components/ui/Modal/index';

import { getUserAddress } from '../../../services/api/endpoints/users';

interface IProps {
  uuid: string;
}

const UserAddress: React.FC<IProps> = ({ uuid }) => {
  const [show, setShow] = useState<boolean>(false);

  const getData = async () => {
    try {
      const data = await getUserAddress(uuid);
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (show) {
      getData();
    }
  }, [show]);

  return (
    <>
      <Paragraph primary type="link" onClick={() => setShow(true)}>
        Ver Mais
      </Paragraph>
      <Modal visible={show} setVisible={setShow}>
        {uuid}
      </Modal>
    </>
  );
};

export default UserAddress;
