import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import InputMask from '../../../components/ui/InputMask';
import Row from '../../../components/ui/Row';
import Col from '../../../components/ui/Col';
import Select from '../../../components/ui/Select';
import { bankListOptions } from '../../../utils/bankList';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';
import {
  getBankAccount,
  setBankAccount,
} from '../../../services/api/endpoints/bankAccount';

const BankForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const data = await getBankAccount();
      formRef.current.setData({ ...data });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      await setBankAccount(formData);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Select
        name="bankCode"
        label="Selecione o Banco"
        options={bankListOptions}
      />
      <InputMask
        name="cnpj"
        label="CNPJ"
        placeholder="Informe o CNPJ"
        mask="99.999.999/9999-99"
      />
      <Input
        name="name"
        label="Nome do Titular"
        placeholder="Informe o nome do titular"
      />
      <Input name="agency" label="Agência" placeholder="Informe a agência" />
      <Row colGap={16} rowGap={16}>
        <Col xs={8}>
          <Input
            name="accountCode"
            label="Conta Corrente"
            placeholder="Informe a conta corrente"
          />
        </Col>
        <Col xs={4}>
          <Input name="accountCV" label="Dígito" placeholder="Dígito" />
        </Col>
      </Row>

      <Button type="submit" loading={loading}>
        Salvar
      </Button>
    </Form>
  );
};

export default BankForm;
