import React from 'react';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';

import Title from '../../components/ui/Title';

import NewAdminForm from './NewAdminForm/index';
import UpdateAdminForm from './UpdateAdminForm/index';
import Divisor from '../../components/ui/Divisor/index';
import UpdatePassword from './UpdatePassword/index';
import AdminsTable from './AdminsTable/index';

const style = {
  card: { height: '100%' },
};

const Admins: React.FC = () => {
  return (
    <Container>
      <Row colGap={16} rowGap={32}>
        <Col xs={12}>
          <Title>Minha Conta</Title>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Meu Perfil </Title>
            <Divisor />
            <UpdateAdminForm />
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Alterar Senha </Title>
            <Divisor />
            <UpdatePassword />
          </Card>
        </Col>
        <Col xs={12}>
          <Title>Administradores</Title>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Novo Administrador</Title>
            <Divisor />
            <NewAdminForm />
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Administadrores da Plataforma</Title>
            <Divisor />
            <AdminsTable />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Admins;
