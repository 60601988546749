export const palette = {
  black: {
    main: '#121212',
    shades: ['#262626', '#7B7B7B'],
  },
  gray: {
    main: '#BFBFBF',
    shades: ['#D9D9D9', '#F2F2F2', '#F9F9F9'],
  },
  green: {
    main: '#11D608',
    shades: ['#BDED01', '#10aa07', '#3DD449', '#B9F3B7'],
  },
  red: {
    main: '#f84453',
    shades: ['#FF6875'],
  },
};

export const theme = {
  primary: {
    base: palette.green.main,
    opacity: (number: number) => `rgba(17, 214, 8, ${number})`,
    variations: {
      one: palette.green.shades[0],
      two: palette.green.shades[1],
      three: palette.green.shades[2],
      four: palette.green.shades[3],
    },
  },
  danger: {
    base: palette.red.main,
    variations: {
      one: palette.red.shades[0],
    },
  },
  black: palette.black.main,
  gray: {
    dark: palette.black.shades[0],
    mediumDark: palette.black.shades[1],
    mediumLight: palette.gray.main,
    light: palette.gray.shades[0],
    extraLight: palette.gray.shades[1],
    ultraLight: palette.gray.shades[2],
    separator: palette.gray.shades[0],
  },
  feedback: {
    positive: palette.green.main,
    negative: palette.red.main,
  },
  toast: {
    success: palette.green.shades[0],
    error: palette.red.shades[0],
  },
  transparent: '#00000000',
  white: '#FFFFFF',
  blackOpacity: (number: number) => `rgba(0, 0, 0, ${number})`,
  whiteOpacity: (number: number) => `rgba(255, 255, 255, ${number})`,
  borderRadius: '50px',
};
