import React, { useEffect, useState } from 'react';

import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Divisor from '../../components/ui/Divisor';
import Row from '../../components/ui/Row';

import {
  getAdminBalance,
  getAdminBalanceHistory,
} from '../../services/api/endpoints/balance';
import Title from '../../components/ui/Title';
import Balance from './Balance';
import BalanceForm from './BalanceForm';
import BalanceHistory from './BalanceHistory';

const style = {
  card: {
    height: '100%',
  },
};

const Finances: React.FC = () => {
  const [balance, setBalance] = useState<any>();
  const [history, setHistory] = useState<any[]>([]);

  const getData = async () => {
    try {
      const balanceData = await getAdminBalance();
      setBalance(balanceData);
      const historyData = await getAdminBalanceHistory();
      setHistory(historyData.history);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Row colGap={16} rowGap={32}>
        <Col xs={12}>
          <Title>Financeiro</Title>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Meu Saldo</Title>
            <Divisor />
            <Balance balance={balance} />
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card style={style.card}>
            <Title level={3}>Novo Saque</Title>
            <Divisor />
            <BalanceForm getData={getData} balance={balance} />
          </Card>
        </Col>
        <Col xs={12}>
          <Card style={style.card}>
            <Title level={3}>Histórico de Saques</Title>
            <Divisor />
            <BalanceHistory history={history} />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Finances;
