import api from '../..';

const baseUrl = '/admin/biddi';

export type ITransactionType = 'buy' | 'sell';

export const getTransaction = async (type: ITransactionType) => {
  try {
    const { data } = await api.get(`${baseUrl}/${type}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateTransaction = async (type: ITransactionType, body: any) => {
  try {
    const { data } = await api.put(`${baseUrl}/${type}`, body);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
