/* eslint-disable array-callback-return */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../ui/Button';
import Col from '../../ui/Col';
import Divisor from '../../ui/Divisor';
import Input from '../../ui/Input';
import Row from '../../ui/Row';
import Select from '../../ui/Select';
import SingleUpload from '../../ui/SingleUpload';
import Switch from '../../ui/Switch';
import Textarea from '../../ui/Textarea';
import TextEditor from '../../ui/TextEditor';
import Title from '../../ui/Title';
import { getCategories } from '../../../services/api/endpoints/categories/index';
import { getBrands } from '../../../services/api/endpoints/brands';
import { getChecklists } from '../../../services/api/endpoints/checklist';
import { getAttributes } from '../../../services/api/endpoints/attributes/index';
import ProductAttribute from './ProductAttribute/index';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const style = {
  switch: {
    marginBottom: '1rem',
  },
};

interface IProps {
  onSubmit: (body: any) => void;
  initialData?: any;
  type: 'new' | 'edit';
}

const ProductDetail: React.FC<IProps> = ({ onSubmit, initialData, type }) => {
  const formRef = useRef<FormHandles>(null);
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [brands, setBrands] = useState<any[]>([]);
  const [checklists, setChecklists] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<any[]>([]);

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      if (data.highlight) {
        const { path: highlightImage } = await fileUpload(
          'image',
          data.highlight,
        );
        delete data.highlight;
        data.highlightImage = highlightImage;
      }

      if (data.cover) {
        const { path: coverImage } = await fileUpload('image', data.cover);
        delete data.cover;
        data.coverImage = coverImage;
      }

      data.attributes = selectedAttributes.map(item => item.value);
      data.shippingFee = Number(data.shippingFee.split(',').join('.')) * 100;
      data.dimensions = {
        width: data.width,
        height: data.height,
        length: data.length,
        weight: data.weight,
      };
      await Promise.all([onSubmit(data)]);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  const handleAttribute = (value: any) => {
    if (value === 'default') return;
    const attribute = attributes.find(item => item.value === value);
    setSelectedAttributes([...selectedAttributes, attribute]);
  };

  const handleRemove = (value: any) => {
    setSelectedAttributes(
      selectedAttributes.filter(item => item.value !== value),
    );
  };

  const getCategoriesData = async () => {
    try {
      const categoriesData = await getCategories({});
      const auxCategories = [];
      categoriesData.data.map(item =>
        auxCategories.push({
          value: item.uuid,
          label: item.data.name.label,
        }),
      );

      setCategories(auxCategories);
    } catch (err) {
      console.log(err);
    }
  };

  const getBrandsData = async () => {
    try {
      const brandsData = await getBrands({});
      const auxBrands = [];
      brandsData.data.map(item =>
        auxBrands.push({
          value: item.uuid,
          label: item.data.name.label,
        }),
      );

      setBrands(auxBrands);
    } catch (err) {
      console.log(err);
    }
  };

  const getChecklistsData = async () => {
    try {
      const checklistsData = await getChecklists({});

      const auxChecklists = [];
      checklistsData.data.map(item =>
        auxChecklists.push({
          value: item.uuid,
          label: item.name,
        }),
      );

      setChecklists(auxChecklists);
    } catch (err) {
      console.log(err);
    }
  };

  const getAttributesData = async () => {
    try {
      const { data } = await getAttributes({});
      const aux = [
        {
          value: 'default',
          label: 'Selecionar',
        },
      ];
      data.map(item =>
        aux.push({
          value: item.uuid,
          label: item.name,
        }),
      );
      setAttributes(aux);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getCategoriesData();
    getBrandsData();
    getChecklistsData();
    getAttributesData();
  }, []);

  useEffect(() => {
    if (initialData) {
      formRef.current.setData({
        ...initialData,
        ...initialData.dimensions,
        brand: initialData?.brand?.uuid,
        category: initialData?.category?.uuid,
        checklist: initialData?.checklist?.uuid,
        cover: initialData.coverImage,
        highlight: initialData.highlightImage,
        description: initialData?.description,
        shippingFee: `${(initialData.shippingFee / 100).toFixed(2)}`,
      });
      initialData.attributes.map(item => {
        item.value = item.uuid;
      });
      setSelectedAttributes(initialData.attributes);
    }
  }, [initialData]);

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        attributeList: '',
        description: initialData?.description,
      }}
    >
      <Row colGap={16} rowGap={16}>
        <Col xs={12} lg={6}>
          <Title level={3}>Detalhes do Produto</Title>
          <Divisor />
          <Switch style={style.switch} name="published" label="Publicado" />
          <Row colGap={16} rowGap={16}>
            <Col xs={12} sm={6}>
              <SingleUpload name="cover" label="Imagem de Capa" />
            </Col>
            <Col xs={12} sm={6}>
              <SingleUpload name="highlight" label="Imagem de Destaque" />
            </Col>
          </Row>

          <Input
            name="name"
            label="Nome do Produto"
            placeholder="Informe o nome do produto"
          />
          <Input
            name="ticker"
            label="Ticker do Produto"
            placeholder="Informe o ticker do produto"
          />

          <TextEditor name="description" label="Descrição do Produto" />

          <Select name="category" label="Categoria" options={categories} />
          <Select name="brand" label="Marca" options={brands} />
          <Select name="checklist" label="Checklist" options={checklists} />
        </Col>
        <Col xs={12} lg={6}>
          <Title level={3}>Informações de Envio</Title>
          <Divisor />
          <Input
            name="width"
            label="Largura da Embalagem (cm)"
            placeholder="Informe a largura da embalagem"
            suffix="cm"
          />
          <Input
            name="height"
            label="Altura da Embalagem (cm)"
            placeholder="Informe a altura da embalagem"
            suffix="cm"
          />
          <Input
            name="length"
            label="Comprimento da Embalagem (cm)"
            placeholder="Informe o comprimento da embalagem"
            suffix="cm"
          />
          <Input
            name="weight"
            label="Peso da Embalagem (kg) - máx. 30kg"
            placeholder="Informe o peso da embalagem"
            suffix="kg"
          />
          <Input
            name="shippingFee"
            label="Taxa de Frete"
            placeholder="0,00"
            prefix="R$"
            type="number"
          />
          <Title level={3}>Atributos do Produto</Title>
          <Divisor />
          <Select
            name="attributeList"
            label="Atributos"
            options={attributes}
            onChange={handleAttribute}
          />
          {selectedAttributes.map(item => (
            <ProductAttribute
              key={item.value}
              value={item.value}
              label={item.label}
              onRemove={handleRemove}
            />
          ))}
          <Button type="submit" loading={loading}>
            Salvar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductDetail;
