import React, { useEffect, useState } from 'react';
import Paginate from '../../../components/ui/Paginate';
import Table from '../../../components/ui/Table/index';
import { formatPrice } from '../../../utils/format';
import { header, body } from './constants';

interface IProps {
  history: any[];
}

const BalanceHistory: React.FC<IProps> = ({ history }) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    history?.map((item: any) => {
      item.data = {
        date: {
          id: 1,
          label: item.date,
        },
        bank: {
          id: 3,
          label: item.bankCode,
        },
        agency: {
          id: 4,
          label: item.agency,
        },
        account: {
          id: 5,
          label: item.accountCode,
        },
        value: {
          id: 6,
          label: formatPrice(item.value / 100, 2),
        },
      };
      return true;
    });
    setData(history);
  }, [history]);

  return (
    <>
      <Table header={header} body={data} />
      {/* <Paginate
        pageCount={10}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        initialPage={0}
        onPageChange={handlePage}
      /> */}
    </>
  );
};

export default BalanceHistory;
