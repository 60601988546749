/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { header } from './constants';
import Divisor from '../../../ui/Divisor';
import Paragraph from '../../../ui/Paragraph';
import Table from '../../../ui/Table';
import Title from '../../../ui/Title';
import { deleteSku } from '../../../../services/api/endpoints/skus/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../utils/toastMessage';
import { deleteExtraItem } from '../../../../services/api/endpoints/productExtraItem';
import { formatPrice } from '../../../../utils/format';

interface IProps {
  items: any[];
  getData: () => void;
}

const ExtraItemsList: React.FC<IProps> = ({ items, getData }) => {
  const [data, setData] = useState<any[]>([]);

  const deleteItem = async (uuid: string) => {
    try {
      await deleteExtraItem(uuid);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderDelete = (id: string) => {
    return (
      <Paragraph danger type="link" onClick={() => deleteItem(id)}>
        Excluir
      </Paragraph>
    );
  };

  useEffect(() => {
    items.map((item: any) => {
      item.data = {
        uuid: {
          label: item.uuid,
        },
        name: {
          render: item.name,
        },
        price: {
          render: formatPrice(item.price / 100, 2),
        },
        delete: {
          render: renderDelete(item.uuid),
        },
      };
      return true;
    });
    setData(items);
  }, [items]);

  return (
    <>
      <Title level={3}>Acessórios</Title>
      <Divisor />
      <Table header={header} body={data} />
    </>
  );
};

export default ExtraItemsList;
