import React, { useEffect, useState } from 'react';
import Button from '../../../components/ui/Button';
import DNDList from '../../../components/ui/DNDList/index';
import {
  getBanners,
  setBannersOrder,
  deleteBanner,
} from '../../../services/api/endpoints/homeBanner';
import BannerModal from './BannerModal';

const BannerForm: React.FC = () => {
  const [items, setItems] = useState([]);
  const [banner, setBanner] = useState<any>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const getData = async () => {
    try {
      const data = await getBanners();
      setItems(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = async (data: any) => {
    setItems(data);
    try {
      const body = [];
      data.map(item => body.push(item.uuid));
      await setBannersOrder(body);
    } catch (err) {
      console.log(err);
    }
  };
  const handleEdit = (uuid: any) => {
    const findBanner = items.find(item => item.uuid === uuid);
    setVisible(true);
    setBanner(findBanner);
  };
  const handleDelete = async (data: any) => {
    try {
      await deleteBanner(data);
      getData();
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewBanner = () => {
    setVisible(true);
    setBanner(null);
  };

  return (
    <>
      <DNDList
        items={items}
        editable
        deletable
        onEdit={handleEdit}
        onDelete={handleDelete}
        onChange={handleChange}
      />
      <Button onClick={handleNewBanner}>Novo Banner</Button>
      <BannerModal
        getData={getData}
        visible={visible}
        setVisible={setVisible}
        banner={banner}
      />
    </>
  );
};

export default BannerForm;
