/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { Container, Label, StyledRadio } from './styles';

interface IOptions {
  value: string;
  label: string;
}

interface Props {
  name: string;
  options: IOptions[];
  layout?: 'vertical' | 'horizontal';
  onChange?: (params?: any) => void;
  [key: string]: any;
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const RadioGroup: React.FC<InputProps> = ({
  name,
  options,
  layout = 'vertical',
  onChange,
  initialValue,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fieldName, registerField, error } = useField(name);

  const handleChange = (e: any) => {
    if (inputRef.current) inputRef.current.value = e.target.value;
    if (onChange) onChange(e.target.value);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container ref={inputRef} layout={layout}>
      {options.map(option => (
        <Label key={option.value}>
          <StyledRadio
            name={name}
            className={error ? 'error' : ''}
            id={fieldName}
            value={option.value}
            type="radio"
            onChange={handleChange}
            defaultChecked={initialValue === option.value}
            {...rest}
          />
          {option.label}
        </Label>
      ))}
    </Container>
  );
};

export default RadioGroup;
