/* eslint-disable prettier/prettier */
import styled, { keyframes } from 'styled-components';

export const Container = styled.div``;

interface IStyledSelect {
  [string: string]: any;
}

export const Wrapper = styled.label`
  position: relative;
  &:after{
    position: absolute;
    content: '';
    right: 7px;
    top: 3px;
    width: 7px;
    height: 7px;
    /* border-bottom: 2px solid ${({ theme }) => theme.primary.base};
    border-right: 2px solid ${({ theme }) => theme.primary.base}; */
    border-bottom: 2px solid ${({ theme }) => theme.gray.mediumDark};
    border-right: 2px solid ${({ theme }) => theme.gray.mediumDark};
    transform: rotate(45deg);
  }
`;

export const StyledSelect = styled.select<IStyledSelect>`
  width: 100%;
  padding: 7px 15px;
  color: ${({ theme }) => theme.gray.dark};
  -webkit-appearance: none;
  /* border: 1px solid ${({ theme }) => theme.gray.dark}; */
  font-size: 1rem;
  /* text-transform: uppercase; */
  position: relative;


  background: ${({ theme }) => theme.gray.extraLight};
  border-radius: 3px;
  border: 1px solid transparent;

  &.error {
    border: 1px solid ${({ theme }) => theme.danger.base};
  }
`;

interface ILabel {
  htmlFor: string;
}

export const Label = styled.span<ILabel>`
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.9rem;
  display: block;
  margin-bottom: .5rem;
`;

const fadeError = keyframes`
  from{
    opacity: 0;
    transform: translateY(-5px);
  }

  to{
    opacity: 1;
    transform: translateY(0);
  }
`

export const Error = styled.span`
  color: ${({ theme }) => theme.danger.base};
  font-size: 0.9rem;
  font-weight: 400;
  transition: .2s;
  animation: ${fadeError} linear 500ms;
  display: block;
  margin-bottom: 0.5rem;

  span{
    color: transparent;
  }
`;
