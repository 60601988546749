import React from 'react';
import { Card } from '../../../components/ui/Card';
import Col from '../../../components/ui/Col';

// import { Container } from './styles';
import Row from '../../../components/ui/Row/index';
import Statistic from '../../../components/ui/Statistic';
import { formatPrice } from '../../../utils/format';

const style = {
  card: {
    height: '100%',
  },
};

interface IProps {
  balance: any;
}

const Balance: React.FC<IProps> = ({ balance }) => {
  return (
    <Row colGap={16} rowGap={16}>
      <Col xs={12} lg={6}>
        <Card style={style.card}>
          <Statistic
            data={formatPrice(balance?.onHold / 100, 2)}
            label="À Liberar"
            type="negative"
          />
        </Card>
      </Col>

      <Col xs={12} lg={6}>
        <Card style={style.card}>
          <Statistic
            data={formatPrice(balance?.available / 100, 2)}
            label="Disponível para Saque"
          />
        </Card>
      </Col>

      <Col xs={12}>
        <Card style={style.card}>
          <Statistic
            data={formatPrice(balance?.total / 100, 2)}
            label="Saldo Total"
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Balance;
