export const filter = [
  {
    id: 1,
    label: 'Nome',
    value: 'name',
  },
  {
    id: 2,
    label: 'Categoria',
    value: 'category',
  },
  {
    id: 3,
    label: 'Marca',
    value: 'brand',
  },
  {
    id: 4,
    label: 'SKUs',
    value: 'skus',
  },
  {
    id: 5,
    label: 'Lances',
    value: 'bids',
  },
  {
    id: 6,
    label: 'Ofertas',
    value: 'offers',
  },
];

export const header = [
  {
    id: 1,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 2,
    label: 'Categoria',
    key: 'category',
  },
  {
    id: 3,
    label: 'Marca',
    key: 'brand',
  },
  {
    id: 6,
    label: 'SKUs',
    key: 'skus',
  },
  {
    id: 7,
    label: 'Lances',
    key: 'bids',
  },
  {
    id: 8,
    label: 'Ofertas',
    key: 'offers',
  },
  {
    id: 4,
    label: 'Destaques 1',
    key: 'featured1',
  },
  {
    id: 5,
    label: 'Destaques 2',
    key: 'featured2',
  },
  {
    id: 9,
    label: 'Editar',
    key: 'edit',
  },
  {
    id: 10,
    label: 'Excluir',
    key: 'delete',
  },
];
