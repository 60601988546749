import * as Yup from 'yup';
import { FILE_SIZE_MESSAGE, MAX_FILE_SIZE } from '../../constants';

export const newBannerSchema = Yup.object().shape({
  primaryText: Yup.string().required(),
  secundaryText: Yup.string().required(),
  image: Yup.mixed()
    .test('fileSize', FILE_SIZE_MESSAGE, value => value.size <= MAX_FILE_SIZE)
    .required(),
});

export const updateBannerSchema = Yup.object().shape({
  primaryText: Yup.string().required(),
  secundaryText: Yup.string().required(),
});
