import React, { useEffect, useRef, useState } from 'react';
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import { markdownToDraft } from 'markdown-draft-js';
import 'draft-js/dist/Draft.css';
import { FaBold, FaItalic } from 'react-icons/fa';
import { Container, Controls, ControlButton } from './styles';

const STYLE_TYPE = [
  {
    style: 'BOLD',
    label: <FaBold />,
  },
  {
    style: 'ITALIC',
    label: <FaItalic />,
  },
];

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
];

interface IProps {
  onChange?: (value: string) => void;
  defaultValue?: string;
}

const TextEditor: React.FC<IProps> = ({ onChange, defaultValue }) => {
  const ref = useRef(null);
  const rawData = markdownToDraft(defaultValue);
  const contentState = convertFromRaw(rawData);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState),
  );
  const selection = editorState.getSelection();

  useEffect(() => {
    if (defaultValue) {
      const data = JSON.parse(defaultValue);
      const content = convertFromRaw(data);
      setEditorState(EditorState.createWithContent(content));
    }
  }, [defaultValue]);

  useEffect(() => {
    const content = editorState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const stringData = JSON.stringify(rawObject);
    if (onChange) onChange(stringData);
  }, [editorState]);

  const handleKeyCommand = (command: any, state: EditorState) => {
    const newState = RichUtils.handleKeyCommand(state, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  };
  const handleStyle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };
  const handleBlock = (style: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, style));
  };

  const renderBlock = (item: any) => {
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <ControlButton
        active={item.style === blockType}
        key={item.style}
        onMouseDown={e => {
          e.preventDefault();
          handleBlock(item.style);
        }}
      >
        {item.label}
      </ControlButton>
    );
  };

  const renderStyle = (item: any) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
      <ControlButton
        key={item.style}
        active={currentStyle.has(item.style)}
        onMouseDown={e => {
          e.preventDefault();
          handleStyle(item.style);
        }}
      >
        {item.label}
      </ControlButton>
    );
  };

  return (
    <Container>
      <Controls>
        {STYLE_TYPE.map(item => renderStyle(item))}
        {BLOCK_TYPES.map(item => renderBlock(item))}
      </Controls>
      <Editor
        ref={ref}
        editorState={editorState}
        onChange={setEditorState}
        handleKeyCommand={handleKeyCommand}
      />
    </Container>
  );
};

export default TextEditor;
