import React from 'react';
import { CSSProperties } from 'styled-components';
import IF from '../IF';

import { Container, Loading } from './styles';

interface IProps {
  children: React.ReactNode;
  onClick?: (params?: any) => void;
  loading?: boolean;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
  block?: boolean;
  danger?: boolean;
  ghost?: boolean;
  neutral?: boolean;
  style?: CSSProperties;
  [string: string]: any;
}

const Button: React.FC<IProps> = ({
  children,
  loading = false,
  onClick,
  type = 'button',
  block = false,
  disabled = false,
  danger = false,
  neutral = false,
  ghost = false,
  style,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <Container
      disabled={disabled || loading}
      block={block}
      type={type}
      onClick={handleClick}
      danger={danger}
      ghost={ghost}
      neutral={neutral}
      loading={loading || undefined}
      style={style}
    >
      <IF condition={loading}>
        <Loading />
      </IF>
      {children}
    </Container>
  );
};

export default Button;
