import api from '../..';

export const getZipcode = async (zipcode: string) => {
  try {
    const { data } = await api.get(
      `https://brasilapi.com.br/api/cep/v1/${zipcode}`,
    );
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
