/* eslint-disable prettier/prettier */
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Card } from '../../../components/ui/Card';
import Paragraph from '../../../components/ui/Paragraph';

import { Container } from './styles';
import SingleUpload from '../../../components/ui/SingleUpload';
import Divisor from '../../../components/ui/Divisor';
import Row from '../../../components/ui/Row';
import Col from '../../../components/ui/Col';
import Button from '../../../components/ui/Button';
import { setEvaluationReport } from '../../../services/api/endpoints/evaluations';

const style = {
  card: {
    height: '100%',
  },
};

interface IProps {
  data: any;
  onApprove: (uuid: string) => Promise<void>;
  onDecline: (uuid: string) => Promise<void>;
}

const EvaluationCard: React.FC<IProps> = ({ data, onDecline, onApprove }) => {
  const formRef = useRef<FormHandles>(null);
  const [filePath, setFilePath] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (option: 'approve' | 'decline') => {
    formRef.current.setErrors({});
    if (!filePath) {
      formRef.current.setErrors({ checklist: 'Campo obrigatório!' });
      return;
    }
    try {
      setLoading(true);
      await setEvaluationReport(data.id, filePath);
      if (option === 'approve') {
        await onApprove(data.id);
      } else if (option === 'decline') {
        await onDecline(data.id);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const openChecklist = async () => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', data?.checklist);
    anchor.setAttribute('target', '_blank');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <Card style={style.card}>
      <Container>
        <Paragraph>{`#${data.id}`}</Paragraph>
        <Paragraph>{data.name}</Paragraph>
        <Paragraph>{`Vendeu: ${data.userNameVendedor}`}</Paragraph>
        <Paragraph>{`Comprou: ${data.userNameComprador}`}</Paragraph>
        <Paragraph>{`Valor: R$ ${data.price / 100}`}</Paragraph>
        <Paragraph>{data.date}</Paragraph>
        <Paragraph>
          {data?.attributes?.map(item => (
            <span>
              {`${item}; `}
            </span>
          ))}
        </Paragraph>
        <Paragraph primary type="link" onClick={openChecklist}>
          Ver Checklist
        </Paragraph>
        <Divisor />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <SingleUpload
            uploadOnChange
            type="file"
            name="checklist"
            label="Laudo Técnico"
            uploadType="file"
            onUpload={setFilePath}
            accept=".pdf"
          />
          <Row colGap={16} rowGap={16}>
            <Col xs={12} sm={6}>
              <Button
                danger
                block
                onClick={() => {
                  handleSubmit('decline');
                }}
                disabled={loading}
              >
                Recusar
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              <Button
                block
                onClick={() => {
                  handleSubmit('approve');
                }}
                disabled={loading}
              >
                Aprovar
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Card>
  );
};

export default EvaluationCard;
