import React, { useEffect, useState } from 'react';
import Col from '../../ui/Col';
import Row from '../../ui/Row';
import NewExtraItem from './NewExtraItem';
import ExtraItemsList from './ExtraItemsList';
import { getExtraItems } from '../../../services/api/endpoints/productExtraItem';

interface IProps {
  uuid: string;
}

const ProductExtraItems: React.FC<IProps> = ({ uuid }) => {
  const [data, setData] = useState<any[]>([]);
  const getData = async () => {
    try {
      const response = await getExtraItems(uuid);
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Row colGap={16} rowGap={16}>
      <Col xs={12} sm={6} md={4}>
        <NewExtraItem uuid={uuid} getData={getData} />
      </Col>
      <Col xs={12} sm={6} md={8}>
        <ExtraItemsList items={data} getData={getData} />
      </Col>
    </Row>
  );
};

export default ProductExtraItems;
