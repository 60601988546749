import api from '../..';

const baseUrl = '/admin/checklist';

interface IGetChecklists {
  page?: number;
  limit?: number;
  filter?: string;
}

export const getChecklists = async ({
  page,
  filter,
  limit,
}: IGetChecklists) => {
  const { data } = await api.get('/admin/checklists', {
    params: {
      page,
      limit,
      filter,
    },
  });
  return data;
};

export const createChecklists = async (body: any) => {
  const { data } = await api.post(baseUrl, body);
  return data;
};

export const getChecklist = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl}/${uuid}`);
  return data;
};

export const deleteChecklist = async (uuid: string) => {
  const { data } = await api.delete(`${baseUrl}/${uuid}`);
  return data;
};

export const updateChecklists = async (uuid: string, body: any) => {
  const { data } = await api.put(`${baseUrl}/${uuid}`, body);
  return data;
};
