/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Table from '../../components/ui/Table';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Paragraph from '../../components/ui/Paragraph';
import Paginate from '../../components/ui/Paginate';
import Input from '../../components/ui/Input';
import { getTickets } from '../../services/api/endpoints/tickets';
import useDebounce from '../../hooks/useDebounce';

const Labels: React.FC = () => {
  const [tickets, setTickets] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>('');
  const searchRef = useRef<FormHandles>(null);

  const handleFilter = () => {
    console.log('');
  };

  const openChecklist = (url: string) => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('target', '_blank');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const createRender = (url: string) => {
    return (
      <Paragraph primary type="link" onClick={() => openChecklist(url)}>
        Imprimir
      </Paragraph>
    );
  };

  const handlePage = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const getData = async (p: number, f: string) => {
    try {
      const { data, pagination } = await getTickets(p, f);
      setTotalPage(pagination?.totalPages);

      data.map((item: any) => {
        item.id = item.uuid;
        item.data = {
          order: {
            id: 0,
            label: item.uuid,
          },
          buyerId: {
            id: 1,
            label: item.first_send_code,
          },
          ticketOne: {
            id: 2,
            render: createRender(item?.ticketOne),
          },
          sellerId: {
            id: 3,
            label: item.second_send_code,
          },
          ticketTwo: {
            id: 4,
            render: createRender(item?.ticketTwo),
          },
        };
        return true;
      });

      setTickets(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData(page, filter);
  }, [page, filter]);

  const getFilteredData = (value: string) => {
    setFilter(value);
    setPage(1);
  };

  const debouncedFilter = useDebounce(getFilteredData, 500);

  const handleSearch = (value: any) => {
    debouncedFilter(value);
  };

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12} md={8} lg={10}>
          <Title>Etiquetas</Title>
        </Col>

        <Col xs={12}>
          <Card>
            <Form onSubmit={handleFilter} ref={searchRef}>
              <Input
                placeholder="Buscar pedido"
                name="search"
                onChange={handleSearch}
              />
            </Form>
            <Table header={header} body={tickets} />
            <Paginate
              pageCount={totalPage}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              onPageChange={handlePage}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Labels;
