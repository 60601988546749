const createChartData = (xaxis, yaxis, label) => {
  return {
    xaxis: { categories: xaxis },
    series: [
      {
        name: label,
        data: yaxis,
      },
    ],
  };
};
export default createChartData;
