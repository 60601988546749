import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.blackOpacity(0.5)};
  z-index: 1000;
  cursor: pointer;
`;

export const Content = styled(motion.div)`
  background: ${({ theme }) => theme.white};
  position: relative;
  z-index: 1001;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  padding: 1rem;
  border-radius: 3px;
  overflow-y: auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.gray.mediumDark};

  &:hover {
    opacity: 0.5;
  }
`;
