import api from '../..';

const baseUrl = '/admin/biddi/community';

export const getCommunity = async () => {
  try {
    const { data } = await api.get(baseUrl);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const setCommunity = async (body: any) => {
  try {
    const { data } = await api.put(baseUrl, body);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
