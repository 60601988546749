import styled, { css } from 'styled-components';

interface IContainer {
  layout: 'vertical' | 'horizontal';
}
export const Container = styled.div<IContainer>`
  margin-bottom: 1rem;

  ${({ layout }) =>
    layout === 'vertical' &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

interface IStyledRadio {
  [string: string]: any;
}
export const StyledRadio = styled.input<IStyledRadio>`
  margin-right: 0.5rem;
  position: relative;

  &:checked:before {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    top: 2px;
    left: 2px;
    position: absolute;
    background-color: ${({ theme }) => theme.primary.base};
    content: '';
    display: inline-block;
    z-index: 1;
  }

  &:checked:after {
    width: 11px;
    height: 11px;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    position: relative;
    background-color: ${({ theme }) => theme.white};
    content: '';
    display: inline-block;
    visibility: visible;
    border: 1px solid ${({ theme }) => theme.primary.base};
    z-index: 0;
  }
`;
