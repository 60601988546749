/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import React, { useState } from 'react';
import { CSSProperties } from 'styled-components';
import BidsModal from '../../../pages/Bids/BidsModal';

import IF from '../IF';
import {
  ResponsiveWrapper,
  Container,
  THead,
  TBody,
  TFooter,
  TR,
  TH,
  TD,
} from './styles';

interface IProps {
  header: {
    id: string | number;
    label: string;
    key: string;
  }[];
  footer?: {
    id: string | number;
    label: string;
  }[];
  body: {
    id: string | number;
    data: {
      [key: string]: {
        id: string | number;
        label: string;
        render?: React.ReactNode;
      };
    }[];
  }[];
  style?: {
    header?: CSSProperties;
    body?: CSSProperties;
    footer?: CSSProperties;
  };
}

const Table: React.FC<IProps> = ({ header = [], footer = [], body, style }) => {
  const [visible, setVisible] = useState(false);
  const [bid, setBid] = useState<any>({});

  const renderLine = (item: any, head) => {
    return item.data[head?.key]?.render || item.data[head?.key]?.label;
  };

  return (
    <ResponsiveWrapper>
      <Container>
        <IF condition={!!header}>
          <THead style={style?.header}>
            <TR>
              {header.map(item => (
                <TH key={item.uuid}>{item.label}</TH>
              ))}
            </TR>
          </THead>
        </IF>
        <TBody style={style?.body}>
          {body.map(item => (
            <TR
              style={{ cursor: 'pointer' }}
              key={item.uuid}
              onClick={() => {
                setVisible(true);
                setBid(item);
              }}
            >
              {header.map(head => (
                <TD key={head?.key}>{renderLine(item, head)}</TD>
              ))}
            </TR>
          ))}
        </TBody>
        <IF condition={!!footer}>
          <TFooter style={style?.footer}>
            <TR>
              {footer.map(item => (
                <TD key={item.uuid}>{item.label}</TD>
              ))}
            </TR>
          </TFooter>
        </IF>
      </Container>
      {(bid.responsetype === 'bids' || bid.responsetype === 'offers') && (
        <BidsModal
          visible={visible}
          setVisible={setVisible}
          bids={bid}
          onClose={() => setVisible(false)}
        />
      )}
    </ResponsiveWrapper>
  );
};

export default Table;
