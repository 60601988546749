import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

const GlobalStyle = createGlobalStyle`


  *{
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    transition: .2s;
  }

  select{
    -webkit-appearance: none;
  }

  a{
    color: inherit;
    text-decoration: none;

    &:hover{
      opacity: .7;
    }
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: normal;
  }
`;

export default GlobalStyle;
