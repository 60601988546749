export const bankList = [
  {
    code: '001',
    name: 'BANCO DO BRASIL S.A. (Banco do Brasil)',
  },
  {
    code: '237',
    name: 'BANCO BRADESCO S.A. (Bradesco)',
  },
  {
    code: '341',
    name: 'BANCO ITAU S.A. (Itaú)',
  },
  {
    code: '356',
    name: 'BANCO ABN AMRO REAL S.A. (Real)',
  },
  {
    code: '409',
    name: 'UNIBANCO UNIAO DE BANCOS BRASILEIROS S.A. (Unibanco)',
  },
  {
    code: '041',
    name: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A. (Banrisul)',
  },
  {
    code: '037',
    name: 'BANCO DO ESTADO DO PARÁ S/A. (BANPARÁ)',
  },
  {
    code: '104',
    name: 'CAIXA ECONOMICA FEDERAL (Caixa Econômica Federal)',
  },
  {
    code: '033',
    name: 'BANCO SANTANDER S.A. (Santander Banespa)',
  },
  {
    code: '399',
    name: 'HSBC BANK BRASIL S.A.BANCO MULTIPLO (HSBC)',
  },
  {
    code: '745',
    name: 'BANCO CITIBANK S.A.',
  },
  {
    code: '151',
    name: 'BANCO NOSSA CAIXA S.A (Nossa Caixa)',
  },
  {
    code: '389',
    name: 'BANCO MERCANTIL DO BRASIL S.A. (Mercantil do Brasil)',
  },
  {
    code: '004',
    name: 'BANCO DO NORDESTE DO BRASIL S.A (Banco do Nordeste (BNB) )',
  },
  {
    code: '021',
    name: 'BANESTES S.A BANCO DO ESTADO DO ESPIRITO SANTO (Banestes)',
  },
  {
    code: '422',
    name: 'BANCO SAFRA S.A. (Safra)',
  },
  {
    code: '003',
    name: 'BANCO DA AMAZONIA S.A. (Banco da Amazônia (Basa))',
  },
  {
    code: '047',
    name: 'Banco do Estado de Sergipe S.A (Banese)',
  },
  {
    code: '070',
    name: 'Banco de Brasília S.A. (BRB)',
  },
  {
    code: '655',
    name: 'Banco Votorantim S.A (Votorantim)',
  },
  {
    code: '107',
    name: 'Banco BBM S.A (BBM)',
  },
  {
    code: '025',
    name: 'Banco Alfa S.A (Alfa)',
  },
  {
    code: '263',
    name: 'Banco Cacique S. A. (Cacique)',
  },
  {
    code: '229',
    name: 'BANCO CRUZEIRO DO SUL S.A. (Cruzeiro do Sul)',
  },
  {
    code: '252',
    name: 'BANCO FININVEST S.A. (Fininvest)',
  },
  {
    code: '063',
    name: 'BANCO IBI S.A - BANCO MULTIPLO (Banco IBI)',
  },
  {
    code: '623',
    name: 'BANCO PANAMERICANO S.A. (PanAmericano)',
  },
  {
    code: '633',
    name: 'BANCO RENDIMENTO S.A. (Banco Rendimento)',
  },
  {
    code: '749',
    name: 'BANCO SIMPLES S.A. (Banco Simples)',
  },
  {
    code: '215',
    name: 'BANCO ACOMERCIAL E DE INVESTIMENTO SUDAMERIS S.A. (Sudameris)',
  },
  {
    code: '756',
    name: 'BANCO COOPERATIVO DO BRASIL S.A. - (BANCOOB)',
  },
  {
    code: '748',
    name: 'BANCO COOPERATIVO SICREDI S.A. (SICREDI)',
  },
  {
    code: '065',
    name: 'LEMON BANK BANCO MÚLTIPLO S..A (Lemon Bank)',
  },
  {
    code: '069',
    name: 'BPN BRASIL BANCO MÚLTIPLO S.A. (BPN)',
  },
  {
    code: '719',
    name: 'BANIF - BANCO INTERNACIONAL DO FUNCHAL (BRASIL), S.A. (Banif)',
  },
  {
    code: '318',
    name: 'BANCO BMG S.A. (BMG)',
  },
  {
    code: '027',
    name: 'BANCO DO ESTADO DE SANTA CATARINA S.A.',
  },
  {
    code: '208',
    name: 'BANCO UBS PACTUAL S.A.',
  },
  {
    code: '479',
    name: 'BANCO ITAUBANK S.A.',
  },
  {
    code: '077',
    name: 'BANCO INTERMEDIUM S.A.',
  },
  {
    code: '136',
    name: 'BANCO UNICRED',
  },
  {
    code: '212',
    name: 'BANCO ORIGINAL',
  },
  {
    code: '085',
    name: 'CECRED-COOPERATIVA CENTRAL DE CREDITO URBANO',
  },
  {
    code: '655',
    name: 'NEON PAGAMENTOS S.A',
  },
  {
    code: '007',
    name: 'BNDES',
  },
  {
    code: '010',
    name: 'CREDICOAMO',
  },
  {
    code: '011',
    name: 'C.SUISSE HEDGING-GRIFFO CV S/A',
  },
  {
    code: '012',
    name: 'BANCO INBURSA',
  },
  {
    code: '014',
    name: 'NATIXIS BRASIL S.A. BM',
  },
  {
    code: '015',
    name: 'UBS BRASIL CCTVM S.A.',
  },
  {
    code: '016',
    name: 'CCM DESP TRÃ‚NS SC E RS',
  },
  {
    code: '017',
    name: 'BNY MELLON BCO S.A.',
  },
  {
    code: '018',
    name: 'BCO TRICURY S.A.',
  },
  {
    code: '024',
    name: 'BCO BANDEPE S.A.',
  },
  {
    code: '029',
    name: 'BANCO ITAÃš CONSIGNADO S.A.',
  },
  {
    code: '036',
    name: 'BCO BBI S.A.',
  },
  {
    code: '040',
    name: 'BCO CARGILL S.A.',
  },
  {
    code: '060',
    name: 'CONFIDENCE CC S.A.',
  },
  {
    code: '062',
    name: 'HIPERCARD BM S.A.',
  },
  {
    code: '064',
    name: 'GOLDMAN SACHS DO BRASIL BM S.A',
  },
  {
    code: '066',
    name: 'BCO MORGAN STANLEY S.A.',
  },
  {
    code: '074',
    name: 'BCO. J.SAFRA S.A.',
  },
  {
    code: '075',
    name: 'BCO ABN AMRO S.A.',
  },
  {
    code: '076',
    name: 'BCO KDB BRASIL S.A.',
  },
  {
    code: '078',
    name: 'HAITONG BI DO BRASIL S.A.',
  },
  {
    code: '079',
    name: 'BCO ORIGINAL DO AGRO S/A',
  },
  {
    code: '080',
    name: 'B&T CC LTDA.',
  },
  {
    code: '081',
    name: 'BBN BCO BRASILEIRO DE NEGOCIOS S.A.',
  },
  {
    code: '082',
    name: 'BANCO TOPÃZIO S.A.',
  },
  {
    code: '083',
    name: 'BCO DA CHINA BRASIL S.A.',
  },
  {
    code: '084',
    name: 'UNIPRIME NORTE DO PARANÃ - CC',
  },
  {
    code: '089',
    name: 'CCR REG MOGIANA',
  },
  {
    code: '091',
    name: 'CCCM UNICRED CENTRAL RS',
  },
  {
    code: '092',
    name: 'BRK S.A. CFI',
  },
  {
    code: '093',
    name: 'PÓLOCRED SCMEPP LTDA.',
  },
  {
    code: '094',
    name: 'BANCO FINAXIS',
  },
  {
    code: '095',
    name: 'TRAVELEX BANCO DE CÃ‚MBIO S.A.',
  },
  {
    code: '096',
    name: 'BCO B3 S.A.',
  },
  {
    code: '097',
    name: 'CCC NOROESTE BRASILEIRO LTDA.',
  },
  {
    code: '098',
    name: 'CREDIALIANÃ‡A CCR',
  },
  {
    code: '099',
    name: 'UNIPRIME CENTRAL CCC LTDA.',
  },
  {
    code: '100',
    name: 'PLANNER CV S.A.',
  },
  {
    code: '101',
    name: 'RENASCENCA DTVM LTDA',
  },
  {
    code: '102',
    name: 'XP INVESTIMENTOS CCTVM S/A',
  },
  {
    code: '105',
    name: 'LECCA CFI S.A.',
  },
  {
    code: '108',
    name: 'PORTOCRED S.A. - CFI',
  },
  {
    code: '111',
    name: 'OLIVEIRA TRUST DTVM S.A.',
  },
  {
    code: '113',
    name: 'MAGLIANO S.A. CCVM',
  },
  {
    code: '114',
    name: 'CENTRAL COOPERATIVA DE CRÃ‰DITO NO ESTADO DO ESPÃRITO SANTO',
  },
  {
    code: '117',
    name: 'ADVANCED CC LTDA',
  },
  {
    code: '118',
    name: 'STANDARD CHARTERED BI S.A.',
  },
  {
    code: '119',
    name: 'BCO WESTERN UNION',
  },
  {
    code: '120',
    name: 'BCO RODOBENS S.A.',
  },
  {
    code: '121',
    name: 'BCO AGIBANK S.A.',
  },
  {
    code: '122',
    name: 'BCO BRADESCO BERJ S.A.',
  },
  {
    code: '124',
    name: 'BCO WOORI BANK DO BRASIL S.A.',
  },
  {
    code: '125',
    name: 'BRASIL PLURAL S.A. BCO.',
  },
  {
    code: '126',
    name: 'BR PARTNERS BI',
  },
  {
    code: '127',
    name: 'CODEPE CVC S.A.',
  },
  {
    code: '128',
    name: 'MS BANK S.A. BCO DE CÂMBIO',
  },
  {
    code: '129',
    name: 'UBS BRASIL BI S.A.',
  },
  {
    code: '130',
    name: 'CARUANA SCFI',
  },
  {
    code: '131',
    name: 'TULLETT PREBON BRASIL CVC LTDA',
  },
  {
    code: '132',
    name: 'ICBC DO BRASIL BM S.A.',
  },
  {
    code: '133',
    name: 'CRESOL CONFEDERAÇÃO',
  },
  {
    code: '134',
    name: 'BGC LIQUIDEZ DTVM LTDA',
  },
  {
    code: '137',
    name: 'MULTIMONEY CC LTDA.',
  },
  {
    code: '138',
    name: 'GET MONEY CC LTDA',
  },
  {
    code: '139',
    name: 'INTESA SANPAOLO BRASIL S.A. BM',
  },
  {
    code: '140',
    name: 'EASYNVEST - TÃTULO CV SA',
  },
  {
    code: '142',
    name: 'BROKER BRASIL CC LTDA.',
  },
  {
    code: '143',
    name: 'TREVISO CC S.A.',
  },
  {
    code: '144',
    name: 'BEXS BCO DE CAMBIO S.A.',
  },
  {
    code: '145',
    name: 'LEVYCAM CCV LTDA',
  },
  {
    code: '146',
    name: 'GUITTA CC LTDA',
  },
  {
    code: '149',
    name: 'FACTA S.A. CFI',
  },
  {
    code: '157',
    name: 'ICAP DO BRASIL CTVM LTDA.',
  },
  {
    code: '159',
    name: 'CASA CREDITO S.A. SCM',
  },
  {
    code: '163',
    name: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO',
  },
  {
    code: '169',
    name: 'BCO OLÉ BONSUCESSO CONSIGNADO S.A.',
  },
  {
    code: '172',
    name: 'ALBATROSS CCV S.A',
  },
  {
    code: '173',
    name: 'BRL TRUST DTVM SA',
  },
  {
    code: '174',
    name: 'PERNAMBUCANAS FINANC S.A. CFI',
  },
  {
    code: '177',
    name: 'GUIDE',
  },
  {
    code: '180',
    name: 'CM CAPITAL MARKETS CCTVM LTDA',
  },
  {
    code: '182',
    name: 'DACASA FINANCEIRA S/A - SCFI',
  },
  {
    code: '183',
    name: 'SOCRED S.A. SCM',
  },
  {
    code: '184',
    name: 'BCO ITAÚ BBA S.A.',
  },
  {
    code: '188',
    name: 'ATIVA S.A. INVESTIMENTOS CCTVM',
  },
  {
    code: '189',
    name: 'HS FINANCEIRA',
  },
  {
    code: '190',
    name: 'SERVICOOP',
  },
  {
    code: '191',
    name: 'NOVA FUTURA CTVM LTDA',
  },
  {
    code: '194',
    name: 'PARMETAL DTVM LTDA',
  },
  {
    code: '196',
    name: 'FAIR CC S.A.',
  },
  {
    code: '197',
    name: 'STONE PAGAMENTOS S.A.',
  },
  {
    code: '204',
    name: 'BCO BRADESCO CARTOES S.A.',
  },
  {
    code: '213',
    name: 'BCO ARBI S.A.',
  },
  {
    code: '217',
    name: 'BANCO JOHN DEERE S.A.',
  },
  {
    code: '218',
    name: 'BCO BS2 S.A.',
  },
  {
    code: '222',
    name: 'BCO CRÉDIT AGRICOLE BR S.A.',
  },
  {
    code: '224',
    name: 'BCO FIBRA S.A.',
  },
  {
    code: '233',
    name: 'BANCO CIFRA',
  },
  {
    code: '241',
    name: 'BCO CLASSICO S.A.',
  },
  {
    code: '243',
    name: 'BCO MÁXIMA S.A.',
  },
  {
    code: '246',
    name: 'BCO ABC BRASIL S.A.',
  },
  {
    code: '249',
    name: 'BANCO INVESTCRED UNIBANCO S.A.',
  },
  {
    code: '250',
    name: 'BCV',
  },
  {
    code: '253',
    name: 'BEXS CC S.A.',
  },
  {
    code: '254',
    name: 'PARANA BCO S.A.',
  },
  {
    code: '260',
    name: 'NU PAGAMENTOS S.A. (Nubank)',
  },
  {
    code: '265',
    name: 'BCO FATOR S.A.',
  },
  {
    code: '266',
    name: 'BCO CEDULA S.A.',
  },
  {
    code: '268',
    name: 'BARIGUI CH',
  },
  {
    code: '269',
    name: 'HSBC BANCO DE INVESTIMENTO',
  },
  {
    code: '270',
    name: 'SAGITUR CC LTDA',
  },
  {
    code: '271',
    name: 'IB CCTVM LTDA',
  },
  {
    code: '272',
    name: 'AGK CC S.A.',
  },
  {
    code: '273',
    name: 'CCR DE SÃO MIGUEL DO OESTE',
  },
  {
    code: '276',
    name: 'SENFF S.A. - CFI',
  },
  {
    code: '278',
    name: 'GENIAL INVESTIMENTOS CVM S.A.',
  },
  {
    code: '279',
    name: 'CCR DE PRIMAVERA DO LESTE',
  },
  {
    code: '280',
    name: 'AVISTA S.A. CFI',
  },
  {
    code: '281',
    name: 'CCR COOPAVEL',
  },
  {
    code: '283',
    name: 'RB CAPITAL INVESTIMENTOS DTVM LTDA.',
  },
  {
    code: '285',
    name: 'FRENTE CC LTDA.',
  },
  {
    code: '286',
    name: 'CCR DE OURO',
  },
  {
    code: '288',
    name: 'CAROL DTVM LTDA.',
  },
  {
    code: '290',
    name: 'PAGSEGURO',
  },
  {
    code: '292',
    name: 'BS2 DTVM S.A.',
  },
  {
    code: '293',
    name: 'LASTRO RDV DTVM LTDA',
  },
  {
    code: '298',
    name: 'VIPS CC LTDA.',
  },
  {
    code: '299',
    name: 'SOROCRED CFI S.A.',
  },
  {
    code: '300',
    name: 'BCO LA NACION ARGENTINA',
  },
  {
    code: '301',
    name: 'BPP IP S.A.',
  },
  {
    code: '307',
    name: 'TERRA INVESTIMENTOS DTVM',
  },
  {
    code: '309',
    name: 'CAMBIONET CC LTDA',
  },
  {
    code: '310',
    name: 'VORTX DTVM LTDA.',
  },
  {
    code: '320',
    name: 'BCO CCB BRASIL S.A.',
  },
  {
    code: '366',
    name: 'BCO SOCIETE GENERALE BRASIL',
  },
  {
    code: '370',
    name: 'BCO MIZUHO S.A.',
  },
  {
    code: '376',
    name: 'BCO J.P. MORGAN S.A.',
  },
  {
    code: '394',
    name: 'BCO BRADESCO FINANC. S.A.',
  },
  {
    code: '412',
    name: 'BCO CAPITAL S.A.',
  },
  {
    code: '456',
    name: 'BCO MUFG BRASIL S.A.',
  },
  {
    code: '464',
    name: 'BCO SUMITOMO MITSUI BRASIL S.A.',
  },
  {
    code: '473',
    name: 'BCO CAIXA GERAL BRASIL S.A.',
  },
  {
    code: '477',
    name: 'CITIBANK N.A.',
  },
  {
    code: '487',
    name: 'DEUTSCHE BANK S.A.BCO ALEMAO',
  },
  {
    code: '488',
    name: 'JPMORGAN CHASE BANK',
  },
  {
    code: '492',
    name: 'ING BANK N.V.',
  },
  {
    code: '494',
    name: 'BCO REP ORIENTAL URUGUAY BCE',
  },
  {
    code: '495',
    name: 'BCO LA PROVINCIA B AIRES BCE',
  },
  {
    code: '505',
    name: 'BCO CREDIT SUISSE (BRL) S.A.',
  },
  {
    code: '545',
    name: 'SENSO CCVM S.A.',
  },
  {
    code: '600',
    name: 'BCO LUSO BRASILEIRO S.A.',
  },
  {
    code: '604',
    name: 'BCO INDUSTRIAL DO BRASIL S.A.',
  },
  {
    code: '610',
    name: 'BCO VR S.A.',
  },
  {
    code: '611',
    name: 'BCO PAULISTA S.A.',
  },
  {
    code: '612',
    name: 'BCO GUANABARA S.A.',
  },
  {
    code: '613',
    name: 'OMNI BANCO S.A.',
  },
  {
    code: '626',
    name: 'BCO FICSA S.A.',
  },
  {
    code: '630',
    name: 'BCO INTERCAP S.A.',
  },
  {
    code: '634',
    name: 'BCO TRIANGULO S.A.',
  },
  {
    code: '637',
    name: 'BCO SOFISA S.A.',
  },
  {
    code: '641',
    name: 'BCO ALVORADA S.A.',
  },
  {
    code: '643',
    name: 'BCO PINE S.A.',
  },
  {
    code: '652',
    name: 'ITAÚ UNIBANCO HOLDING S.A.',
  },
  {
    code: '653',
    name: 'BCO INDUSVAL S.A.',
  },
  {
    code: '654',
    name: 'BCO A.J. RENNER S.A.',
  },
  {
    code: '707',
    name: 'BCO DAYCOVAL S.A',
  },
  {
    code: '712',
    name: 'BCO OURINVEST S.A.',
  },
  {
    code: '739',
    name: 'BCO CETELEM S.A.',
  },
  {
    code: '741',
    name: 'BCO RIBEIRAO PRETO S.A.',
  },
  {
    code: '743',
    name: 'BANCO SEMEAR',
  },
  {
    code: '746',
    name: 'BCO MODAL S.A.',
  },
  {
    code: '747',
    name: 'BCO RABOBANK INTL BRASIL S.A.',
  },
  {
    code: '751',
    name: 'SCOTIABANK BRASIL',
  },
  {
    code: '752',
    name: 'BCO BNP PARIBAS BRASIL S A',
  },
  {
    code: '753',
    name: 'NOVO BCO CONTINENTAL S.A. - BM',
  },
  {
    code: '754',
    name: 'BANCO SISTEMA',
  },
  {
    code: '755',
    name: 'BOFA MERRILL LYNCH BM S.A.',
  },
  {
    code: '757',
    name: 'BCO KEB HANA DO BRASIL S.A.',
  },
];

export const bankListOptions = bankList.map(item => ({
  label: `${item.code} - ${item.name}`,
  value: item.code,
}));
