/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Button from '../../ui/Button';
import Divisor from '../../ui/Divisor';
import IF from '../../ui/IF';
import Paragraph from '../../ui/Paragraph';
import Title from '../../ui/Title';
import Param from './Param';

interface IProps {
  initialData?: any;
  formRef?: any;
  initialRadioValue?: 'max' | 'min';
  error: boolean;
}

const QuantityParams: React.FC<IProps> = ({
  initialData,
  formRef,
  initialRadioValue,
  error,
}) => {
  const [items, setItems] = useState<number>(0);
  const [deletedItems, setDeletedItems] = useState<any>();

  const addItem = () => {
    setItems(items + 1);
  };

  const deleteItem = (index: number) => {
    if (initialData) {
      setDeletedItems({ ...deletedItems, [index]: true });
    }
    setItems(items - 1);
  };

  useEffect(() => {
    if (initialData) {
      setItems(initialData?.length);
    }
  }, [initialData]);

  const getDefaultValue = (index: number) => {
    if (deletedItems?.[index]) {
      return;
    }
    if (initialData && initialData?.[index]) {
      const item = initialData?.[index];
      formRef?.current?.setFieldValue(
        `quantityParams[${index}].name`,
        item?.name,
      );
      formRef?.current?.setFieldValue(
        `quantityParams[${index}].description`,
        item?.description,
      );

      formRef?.current?.setFieldValue(
        `quantityParams[${index}].value`,
        item?.value,
      );
      formRef?.current?.setFieldValue(`quantityParams[${index}].id`, item?.id);
    }
  };

  const getInitialRadioValue = (index: number) => {
    if (initialRadioValue) {
      return initialRadioValue;
    }
    if (initialData && initialData?.[index]) {
      const item = initialData?.[index];
      return item?.type;
    }

    return 'max';
  };

  return (
    <>
      <Title level={3}>Parâmetros Quantitativos</Title>
      <Divisor />
      {[...Array(items)].map((item, i) => (
        <Param
          key={`${i}`}
          index={i}
          onDelete={() => deleteItem(i)}
          getDefaultValue={() => getDefaultValue(i)}
          initialRadioValue={(() => getInitialRadioValue(i))()}
          hasId={!!initialData}
        />
      ))}
      <IF condition={error}>
        <Paragraph danger>Informe ao menos um parâmetro</Paragraph>
      </IF>
      <Button type="button" ghost onClick={addItem}>
        Adicionar Parâmetro
      </Button>
    </>
  );
};

export default QuantityParams;
