export const header = [
  {
    id: 1,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 2,
    label: 'Editar',
    key: 'update',
  },
  {
    id: 3,
    label: 'Excluir',
    key: 'delete',
  },
];
