import api from '../..';

export interface ITermTypes {
  type: 'condition' | 'privacy' | 'purchase' | 'sale';
}

const baseUrl = '/admin/biddi/term';

export const getTerms = async ({ type }: ITermTypes) => {
  try {
    const { data } = await api.get(`${baseUrl}/${type}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const setTerms = async ({ type }: ITermTypes, file: string) => {
  try {
    const { data } = await api.put(`${baseUrl}/${type}`, { file });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
