import api from '../..';

const baseUrl = '/admin/brands';

interface IGetBrands {
  page?: number;
  limit?: number;
}

export const getBrands = async ({ page, limit }: IGetBrands) => {
  try {
    const { data } = await api.get(baseUrl, {
      params: {
        page,
        limit,
      },
    });
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const getBrand = async (uuid: string) => {
  try {
    const { data } = await api.get(`${baseUrl}/${uuid}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteBrand = async (uuid: string) => {
  try {
    const { data } = await api.delete(`${baseUrl}/${uuid}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateBrand = async (uuid: string, content: any) => {
  try {
    const { data } = await api.put(`${baseUrl}/${uuid}`, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const createBrand = async (content: any) => {
  try {
    const { data } = await api.post(baseUrl, content);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
