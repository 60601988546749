import api from '../..';

const baseUrl = '/admin/stats';

export const getStats = async () => {
  const { data } = await api.get(baseUrl);
  return data;
};

export const getProductOfferStats = async () => {
  const { data } = await api.get(`${baseUrl}/product/offers`);
  return data;
};

export const getProductBidsStats = async () => {
  const { data } = await api.get(`${baseUrl}/product/bids`);
  return data;
};

export const getCategoryOfferStats = async () => {
  const { data } = await api.get(`${baseUrl}/category/offers`);
  return data;
};

export const getCategoryBidsStats = async () => {
  const { data } = await api.get(`${baseUrl}/category/bids`);
  return data;
};
export const getBestSellers = async () => {
  const { data } = await api.get(`${baseUrl}/best-sellers`);
  return data;
};
