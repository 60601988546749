import api from '../..';
import { PAGE_LIMIT } from '../../../../utils/pagination';

const baseUrl = '/admin/orders';

export const getBids = async (page = 1, filter = '') => {
  const { data } = await api.get(baseUrl, {
    params: {
      page,
      limit: PAGE_LIMIT,
      filter,
    },
  });
  return data;
};
