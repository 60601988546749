import React, { useMemo, useRef } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import ReactMarkdown from 'react-markdown';
import { convertFromRaw } from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { ItemTypes } from './ItemTypes';
import {
  Container,
  ActionBox,
  DragIcon,
  UpdateIcon,
  DeleteIcon,
  Description,
} from './styles';
import IF from '../../IF';

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  editable?: boolean;
  deletable?: boolean;
  onEdit?: (id: string | number) => void;
  onDelete?: (id: string | number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DNDCard: React.FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  editable = false,
  deletable = false,
  onDelete,
  onEdit,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleEdit = () => {
    if (onEdit) onEdit(id);
  };
  const handleDelete = () => {
    if (onDelete) onDelete(id);
  };

  const parsedText = useMemo(() => {
    try {
      return stateToMarkdown(convertFromRaw(JSON.parse(text)));
    } catch (err) {
      return text;
    }
  }, [text]);

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Container ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <Description>
        <ReactMarkdown>{parsedText}</ReactMarkdown>
      </Description>
      <ActionBox>
        <IF condition={editable}>
          <UpdateIcon onClick={handleEdit} />
        </IF>
        <IF condition={deletable}>
          <DeleteIcon onClick={handleDelete} />
        </IF>
        <DragIcon />
      </ActionBox>
    </Container>
  );
};
