import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
`;

export const Wrapper = styled.section`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: 90px 2rem 50px;
  @media screen and (max-width: 500px) {
    padding: 90px 1rem 50px;
  }
`;
