import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import InputMask from '../../../components/ui/InputMask';
import {
  getAddress,
  updateAddress,
} from '../../../services/api/endpoints/address/index';
import { getZipcode } from '../../../services/api/endpoints/zipcode/index';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const AddressForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleZipcode = async (e: any) => {
    try {
      const {
        cep: zipCode,
        street,
        neighborhood,
        city,
        state,
      } = await getZipcode(e.target.value);
      formRef.current.setData({
        zipCode,
        street,
        neighborhood,
        city,
        state,
        number: null,
        complement: null,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const response = await getAddress();
      formRef.current.setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      await updateAddress(formData);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <InputMask
        name="zipCode"
        label="CEP (obrigatório)"
        placeholder="Informe o CEP"
        mask="99999-999"
        onBlur={handleZipcode}
      />
      <Input
        name="street"
        label="Rua"
        disabled
        placeholder="Preenchimento automático"
      />
      <Input
        name="number"
        label="Número (obrigatório)"
        placeholder="Informe o número"
      />
      <Input
        name="complement"
        label="Complemento"
        placeholder="Informe o complemento"
      />
      <Input
        name="neighborhood"
        label="Bairro"
        disabled
        placeholder="Preenchimento automático"
      />
      <Input
        name="city"
        label="Cidade"
        disabled
        placeholder="Preenchimento automático"
      />
      <Input
        name="state"
        label="Estado"
        disabled
        placeholder="Preenchimento automático"
      />
      <Button type="submit" loading={loading}>
        Salvar
      </Button>
    </Form>
  );
};

export default AddressForm;
