import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

export const StyledPaginate = styled(ReactPaginate)``;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  ul {
    list-style: none;
    display: flex;
    li {
      margin: 0 0.2rem;
      cursor: pointer;
      padding: 0.3rem 0.5rem;
      border-radius: 3px;
      color: ${({ theme }) => theme.gray.mediumDark};
      svg {
        stroke: ${({ theme }) => theme.gray.mediumDark};
      }
      &.selected {
        background: ${({ theme }) => theme.primary.base};
        color: ${({ theme }) => theme.white};
      }
    }
  }
`;
