import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/ui/Button';
import Input from '../../../components/ui/Input';
import Switch from '../../../components/ui/Switch/index';
import { createAttributes } from '../../../services/api/endpoints/attributes';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';

const style = {
  switch: {
    marginBottom: '1rem',
  },
};

interface IProps {
  getData: (page: number) => void;
}

const NewAttributeForm: React.FC<IProps> = ({ getData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<FormHandles>();

  const handleSubmit = async (e: any) => {
    try {
      setLoading(true);
      await createAttributes(e);
      ref.current.reset();
      getData(1);
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={ref} onSubmit={handleSubmit}>
      <Input
        name="name"
        label="Nome do Atributo"
        placeholder="Informe o nome do atributo"
      />
      <Switch name="isColor" label="Cor" style={style.switch} />
      <Button type="submit" loading={loading}>
        Cadastrar
      </Button>
    </Form>
  );
};

export default NewAttributeForm;
