import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../../components/ui/Button';
import TextEditor from '../../../components/ui/TextEditor';

import SingleUpload from '../../../components/ui/SingleUpload';
import Select from '../../../components/ui/Select';
import {
  getHighlight,
  setHighlight,
} from '../../../services/api/endpoints/highlight';
import IF from '../../../components/ui/IF';
import { fileUpload } from '../../../services/api/endpoints/fileUpload';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../utils/toastMessage';
import { getAllProducts } from '../../../services/api/endpoints/products/index';

const HighlightForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [data, setData] = useState<any>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getData = async () => {
    try {
      const response = await getHighlight();
      const products = await getAllProducts();

      console.log({ response });

      setOptions(
        products.data.map((item: any) => ({
          uuid: item.uuid,
          value: item.uuid,
          label: item.name,
        })),
      );
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (data) {
      formRef.current.setFieldValue('image', data?.imageurl);
      formRef.current.setFieldValue('title', data?.title);
      formRef.current.setFieldValue('product', data?.product?.uuid);
      formRef.current.setData({
        title: data?.title,
        product: data?.product?.uuid,
        image: data?.imageurl,
      });
    }
  }, [data]);

  useEffect(() => {
    if (formRef) getData();
  }, [formRef]);

  const handleSubmit = async (formData: any) => {
    try {
      setLoading(true);
      const { image } = formData;
      let content: any = {
        title: formData.title,
        product: formData.product,
      };

      if (image) {
        const { path: url } = await fileUpload('image', formData.image);
        content = {
          ...content,
          imageUrl: url,
        };
      } else {
        content = {
          ...content,
          imageUrl: data.imageUrl,
        };
      }

      await setHighlight(content);
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <IF condition={!!data}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          title: data?.title,
        }}
      >
        <SingleUpload label="Imagem" name="image" accept=".png, .jpg, .jpeg" />
        <TextEditor name="title" label="Texto" />

        <Select name="product" label="Selecionar produto" options={options} />

        <Button type="submit" loading={loading}>
          Salvar
        </Button>
      </Form>
    </IF>
  );
};

export default HighlightForm;
