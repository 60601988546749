import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { FormHandles } from '@unform/core';
import Input from '../../../components/ui/Input';
import Button from '../../../components/ui/Button';
import { withdrawMoney } from '../../../services/api/endpoints/balance';
import { SUCCESS_MESSAGE } from '../../../utils/toastMessage';

interface IProps {
  getData: () => void;
  balance: any;
}

const BalanceForm: React.FC<IProps> = ({ getData, balance }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const { amount } = values;
      formRef.current.setErrors({});
      if (!amount) {
        formRef.current.setFieldError('amount', 'Campo obrigatório!');
        return;
      }

      if (Number(amount * 100) > balance?.available) {
        formRef.current.setFieldError(
          'amount',
          'Valor indisponível para saque',
        );
        return;
      }
      await withdrawMoney(Number(amount * 100));
      getData();
      toast.success(SUCCESS_MESSAGE);
      formRef.current.reset();
    } catch (err) {
      toast.error('Ops! Não foi possível efetuar o saque!');
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Input prefix="R$" type="number" name="amount" label="Valor do Saque" />
      <Button block type="submit" loading={loading}>
        Efetuar Saque
      </Button>
    </Form>
  );
};

export default BalanceForm;
