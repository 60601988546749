import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  transition: 0.2s;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.gray.dark};

  &:hover {
    opacity: 0.8;
  }
`;
