import styled from 'styled-components';

export const ResponsiveWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 1rem;
  .update-bid {
    select {
      padding: 0 10px;
    }
    .form {
      padding-top: 32px;
    }
    .field {
      display: flex;
      gap: 16px;
      padding-bottom: 16px;
    }
    input {
      text-align: center;
    }
    input {
      max-width: 100px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
    .button {
      display: flex;
      justify-content: end;
      button {
        background-color: #11d608;
        color: #fff;
        font-weight: 600;
        padding: 8px 16px;
        border-radius: 4px;
        border: 0;
        cursor: pointer;
      }
    }
  }
`;

export const Container = styled.table`
  /* table-layout: fixed; */
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0.5rem;
`;

export const THead = styled.thead`
  text-align: left;
`;

export const TBody = styled.tbody``;

export const TFooter = styled.tfoot`
  border-top: 1px solid ${({ theme }) => theme.gray.mediumLight};
`;

export const TR = styled.tr`
  border-bottom: 1px dashed ${({ theme }) => theme.gray.mediumLight};

  &:last-child {
    border: 0;
  }
`;

export const TH = styled.th`
  padding: 0.7rem 0.5rem;
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.9rem !important;
`;

export const TD = styled.td`
  padding: 0.7rem 0.5rem;
  color: ${({ theme }) => theme.gray.dark};
  font-size: 0.8rem !important;
  * {
    font-size: 0.8rem !important;
  }
`;
