/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Table from '../../components/ui/Table/index';
import Paginate from '../../components/ui/Paginate';
import Link from '../../components/ui/Link';
import { theme } from '../../styles/theme';
import NewAttributeForm from './NewAttributeForm/index';
import Divisor from '../../components/ui/Divisor';
import { deleteAttribute, deletePermanentlyAttribute, getAttributes } from '../../services/api/endpoints/attributes/index';
import { PAGE_LIMIT } from '../../utils/pagination';
import Paragraph from '../../components/ui/Paragraph';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';

const style = {
  link: {
    color: theme.primary.base,
    cursor: 'pointer' as const
  },
  title: {
    marginBottom: '.5rem'
  }
};

const Attributes: React.FC = () => {
  const [attributes, setAttributes] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);

  const getData = async (p: number) => {
    try {
      const { data, pagination } = await getAttributes({ page:p, limit: PAGE_LIMIT });
      setTotalPage(pagination?.totalPages);
      data.map(item => {
        item.data = {
          name: {
            id: item.name,
            label: item.name,
          },
          edit: {
            render: renderEdit(item.uuid),
          },
          delete: {
            render: renderDelete(item.uuid),
          },
          permanentlyDelete: {
            render: renderPermantlyDelete(item.uuid),
          },
        }
        return true;
      })
      setAttributes(data);

    } catch (err) {
      console.log(err);
    }
  }

  const handlePage = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const renderEdit = (id: string | number) => {
    return (
      <Link style={style.link} to={`/attributes/edit/${id}`}>
        Editar
      </Link>
    );
  };

  const renderDelete = (id: string | number) => {
    const deleteItem = async () => {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm('Deseja excluir esse atributo?');
      if(!confirm) {
        return
      }
      try {
        await deleteAttribute(id);
        getData(page);
        toast.success(SUCCESS_MESSAGE);
      } catch (err) {
        console.log(err);
        toast.error(ERROR_MESSAGE);
      }
    }
    return (
      <Paragraph danger type="link" onClick={deleteItem} style={{ margin: 0 }}>
        Excluir
      </Paragraph>
    );
  };

  const renderPermantlyDelete = (id: string | number) => {
    const deleteItem = async () => {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm('Deseja excluir esse atributo PERMANENTEMENTE?');
      if(!confirm) {
        return
      }
      try {
        await deletePermanentlyAttribute(id);
        getData(page);
        toast.success(SUCCESS_MESSAGE);
      } catch (err) {
        console.log(err);
        toast.error(ERROR_MESSAGE);
      }
    }
    return (
      <Paragraph danger type="link" onClick={deleteItem} style={{ margin: 0 }}>
        Excluir Permanente
      </Paragraph>
    );
  };

  useEffect(() => {
    getData(page);
  }, [page])

  return (
    <Container>
      <Row colGap={16} rowGap={16}>
        <Col xs={12}>
          <Title style={style.title}>Atributos</Title>
        </Col>
        <Col xs={12} md={4}>
          <Card>
            <Title level={3}>Novo Atributo</Title>
            <Divisor />
            <NewAttributeForm getData={getData} />
          </Card>
        </Col>
        <Col xs={12} md={8}>
          <Card>
            <Table header={header} body={attributes} />
            <Paginate
              pageCount={totalPage}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
              initialPage={page - 1}
              onPageChange={handlePage}
            />
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Attributes;
