/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import { Container } from '../../components/ui/Container';
import Row from '../../components/ui/Row';
import Table from '../../components/ui/Table';
import Title from '../../components/ui/Title';
import { header } from './constants';
import Paragraph from '../../components/ui/Paragraph';
import Paginate from '../../components/ui/Paginate';
import Divisor from '../../components/ui/Divisor';
import Button from '../../components/ui/Button';
import CategoryModal from './CategoryModal';
import BrandModal from './BrandModal';
import {
  getCategories,
  deleteCategory,
} from '../../services/api/endpoints/categories';
import { getBrands, deleteBrand } from '../../services/api/endpoints/brands';
import IF from '../../components/ui/IF';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../utils/toastMessage';
import { PAGE_LIMIT } from '../../utils/pagination';

const Categories: React.FC = () => {
  const [brandData, setBrandData] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [category, setCategory] = useState<any>(null);
  const [brand, setBrand] = useState<any>(null);
  const [categoryModal, setCategoryModal] = useState<boolean>(false);
  const [brandModal, setBrandModal] = useState<boolean>(false);

  const [categoryTotalPage, setCategoryTotalPage] = useState<number>(1);
  const [categoryPage, setCategoryPage] = useState<number>(1);
  const [brandTotalPage, setBrandTotalPage] = useState<number>(1);
  const [brandPage, setBrandPage] = useState<number>(1);

  useEffect(() => {
    if (!brandModal) {
      setBrand(null);
    }
  }, [brandModal]);

  useEffect(() => {
    if (!categoryModal) {
      setCategory(null);
    }
  }, [categoryModal]);

  const getData = async (type: 'category' | 'brand', page) => {
    try {
      const { data, pagination } =
        type === 'category'
          ? await getCategories({ page, limit: PAGE_LIMIT })
          : await getBrands({ page, limit: PAGE_LIMIT });

      data.map((item: any) => {
        item.data = {
          ...item.data,
          edit: {
            render: renderEdit(item.uuid, type),
          },
          delete: {
            render: renderDelete(item.uuid, type),
          },
        };
        return true;
      });

      if (type === 'category') {
        setCategoryData(data);
        setCategoryTotalPage(pagination?.totalPages);
      } else {
        setBrandData(data);
        setBrandTotalPage(pagination?.totalPages);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData('category', categoryPage);
  }, [categoryPage]);

  useEffect(() => {
    getData('brand', brandPage);
  }, [brandPage]);

  const editItem = (type: 'brand' | 'category', param: string | number) => {
    handleModal(type, param);
  };

  const deleteItem = async (type: 'brand' | 'category', param: string) => {
    try {
      if (type === 'brand') {
        await deleteBrand(param);
        getData('brand', brandPage);
      } else {
        await deleteCategory(param);
        getData('category', categoryPage);
      }
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
  };

  const renderEdit = (id: string | number, type: 'brand' | 'category') => {
    return (
      <Paragraph primary type="link" onClick={() => editItem(type, id)}>
        Editar
      </Paragraph>
    );
  };

  const renderDelete = (id: string, type: 'brand' | 'category') => {
    return (
      <Paragraph danger type="link" onClick={() => deleteItem(type, id)}>
        Excluir
      </Paragraph>
    );
  };

  const handleCategoryPage = ({ selected }: { selected: number }) => {
    setCategoryPage(selected + 1);
  };

  const handleBrandPage = ({ selected }: { selected: number }) => {
    setBrandPage(selected + 1);
  };

  const handleModal = (type: 'brand' | 'category', item?: any) => {
    if (type === 'category') {
      setCategoryModal(true);
      setCategory(item);
    } else {
      setBrandModal(true);
      setBrand(item);
    }
  };

  return (
    <>
      <Container>
        <Row colGap={16} rowGap={16}>
          <Col xs={12}>
            <Title>Categorias e Marcas</Title>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <Row colGap={16} rowGap={16}>
                <Col xs={12} sm={6} md={7} lg={8} xl={9} xxl={10}>
                  <Title level={3}>Categorias</Title>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={2}>
                  <Button
                    block
                    onClick={() => {
                      handleModal('category');
                    }}
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>

              <Divisor />
              <Table header={header} body={categoryData} />
              <IF condition={categoryData.length > 0}>
                <Paginate
                  pageCount={categoryTotalPage}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  initialPage={0}
                  onPageChange={handleCategoryPage}
                />
              </IF>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card>
              <Row colGap={16} rowGap={16}>
                <Col xs={12} sm={6} md={7} lg={8} xl={9} xxl={10}>
                  <Title level={3}>Marcas</Title>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4} xl={3} xxl={2}>
                  <Button
                    block
                    onClick={() => {
                      handleModal('brand');
                    }}
                  >
                    Adicionar
                  </Button>
                </Col>
              </Row>

              <Divisor />
              <Table header={header} body={brandData} />
              <IF condition={brandData.length > 0}>
                <Paginate
                  pageCount={brandTotalPage}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  initialPage={0}
                  onPageChange={handleBrandPage}
                />
              </IF>
            </Card>
          </Col>
        </Row>
      </Container>
      <CategoryModal
        visible={categoryModal}
        setVisible={setCategoryModal}
        category={category}
        onClose={() => {
          getData('category', categoryPage);
        }}
      />
      <BrandModal
        visible={brandModal}
        setVisible={setBrandModal}
        brand={brand}
        onClose={() => {
          getData('brand', brandPage);
        }}
      />
    </>
  );
};

export default Categories;
