import api from '../..';

export const getAdminBalance = async () => {
  const { data } = await api.get('/admin/balance');
  return data;
};

export const getAdminBalanceHistory = async () => {
  const { data } = await api.get('/admin/draw/history');
  return data;
};

export const withdrawMoney = async (amount: number) => {
  const { data } = await api.post('/admin/draw', { amount });
  return data;
};
