import api from '../..';

const baseUrl = '/admin/product';

export const getExtraItems = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl}/${uuid}/extraItem`);

  return data;
};

export const createExtraItem = async (uuid: string, body: any) => {
  const { data } = await api.post(`${baseUrl}/${uuid}/extraItem`, body);
  return data;
};

export const deleteExtraItem = async (uuid: string) => {
  const { data } = await api.delete(`/admin/extraItem/${uuid}`);
  return data;
};
