import React, { useState } from 'react';
import Navbar from '../../../components/Navbar';

import { Wrapper, Container } from './styles';

interface IProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<IProps> = ({ children }) => {
  const [hide, setHide] = useState<boolean>(false);
  const toggleMenu = () => {
    setHide(!hide);
  };

  return (
    <Container>
      <Navbar hide={hide} toggleMenu={toggleMenu} />
      <Wrapper>{children}</Wrapper>
    </Container>
  );
};

export default DefaultLayout;
