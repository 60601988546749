import React from 'react';
import { Link } from 'react-router-dom';

export const header = [
  {
    id: 0,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 2,
    label: 'Email',
    key: 'email',
  },
  {
    id: 5,
    label: 'Excluir',
    key: 'delete',
  },
];
export const body: any[] = [
  {
    id: 0,
    data: {
      name: {
        id: 1,
        label: 'Gustavo da Silva',
      },
      email: {
        id: 2,
        label: 'gustavo.silva@email.com',
      },
      delete: {
        id: 5,
        label: 'Excluir',
      },
    },
  },
  {
    id: 1,
    data: {
      name: {
        id: 1,
        label: 'Gustavo da Silva',
      },
      email: {
        id: 2,
        label: 'gustavo.silva@email.com',
      },
      delete: {
        id: 5,
        label: 'Excluir',
      },
    },
  },
  {
    id: 2,
    data: {
      name: {
        id: 1,
        label: 'Gustavo da Silva',
      },
      email: {
        id: 2,
        label: 'gustavo.silva@email.com',
      },
      delete: {
        id: 5,
        label: 'Excluir',
      },
    },
  },
];
