import api from '../..';
import { PAGE_LIMIT } from '../../../../utils/pagination';

const baseUrl = '/admin/product';

export const getProducts = async (page = 1, filter = '') => {
  const { data } = await api.get('/admin/products', {
    params: {
      page,
      limit: PAGE_LIMIT,
      filter,
    },
  });
  return data;
};

export const getAllProducts = async () => {
  const { data } = await api.get('/admin/products');
  return data;
};

export const handleFeaturedProduct = async (
  uuid: string | number,
  type: 'primary' | 'secondary',
) => {
  const { data } = await api.post(`/admin/featured/${type}/product/${uuid}`);
  return data;
};

export const createProduct = async (body: any) => {
  const { data } = await api.post(baseUrl, body);
  return data;
};

export const updateProduct = async (uuid: string, body: any) => {
  const { data } = await api.put(`${baseUrl}/${uuid}`, body);
  return data;
};

export const getProduct = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl}/${uuid}`);
  return data;
};

export const deleteProduct = async (uuid: string) => {
  const { data } = await api.delete(`${baseUrl}/${uuid}`);
  return data;
};

export const addProductAttribute = async (
  uuid: string,
  attributeId: string,
) => {
  const { data } = await api.post(
    `${baseUrl}/${uuid}/attribute/${attributeId}`,
  );
  return data;
};

export const getProductAttributes = async (uuid: string) => {
  const { data } = await api.get(`${baseUrl}/${uuid}/attributes`);
  return data;
};
