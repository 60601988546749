import React from 'react';
import { Card } from '../../components/ui/Card';
import Col from '../../components/ui/Col';
import Divisor from '../../components/ui/Divisor';
import Row from '../../components/ui/Row/index';
import Title from '../../components/ui/Title';
import { theme } from '../../styles/theme';
import CommunityForm from './CommunityForm';
import TutorialForm from './TutorialForm/index';
import HighlightForm from './HighlightForm/index';
import BannerForm from './BannerForm';

const style = {
  title: {
    color: theme.gray.dark,
  },
  card: {
    height: '100%',
  },
};

const Platform: React.FC = () => {
  return (
    <Row colGap={16} rowGap={16}>
      <Col xs={12}>
        <Title style={style.title}>Configurações da Plataforma</Title>
      </Col>
      <Col xs={12} md={4}>
        <Card style={style.card}>
          <Title style={style.title} level={3}>
            Banner Home
          </Title>
          <Divisor />
          <BannerForm />
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card style={style.card}>
          <Title style={style.title} level={3}>
            Comprando na Biddi
          </Title>
          <Divisor />
          <TutorialForm type="buy" />
        </Card>
      </Col>
      <Col xs={12} md={4}>
        <Card style={style.card}>
          <Title style={style.title} level={3}>
            Vendendo na Biddi
          </Title>
          <Divisor />
          <TutorialForm type="sell" />
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card style={style.card}>
          <Title style={style.title} level={3}>
            Em Alta na Casa
          </Title>
          <Divisor />
          <HighlightForm />
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card style={style.card}>
          <Title style={style.title} level={3}>
            Comunidade Biddi
          </Title>
          <Divisor />
          <CommunityForm />
        </Card>
      </Col>
    </Row>
  );
};

export default Platform;
