import React from 'react';

import Divisor from '../../ui/Divisor';
import Input from '../../ui/Input';
import Title from '../../ui/Title';

const ChecklistDetail: React.FC = () => {
  return (
    <>
      <Title level={3}>Detalhes do Checklist</Title>
      <Divisor />
      <Input
        name="name"
        label="Nome do Checklist"
        placeholder="Informe o nome do checklist"
      />
    </>
  );
};

export default ChecklistDetail;
