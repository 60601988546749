import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { fileUpload } from '../../../../services/api/endpoints/fileUpload';
import { createSku } from '../../../../services/api/endpoints/skus';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../../utils/toastMessage';
import Button from '../../../ui/Button';
import Input from '../../../ui/Input';
import Divisor from '../../../ui/Divisor';
import SingleUpload from '../../../ui/SingleUpload';

import Title from '../../../ui/Title';
import Textarea from '../../../ui/Textarea';
import { createExtraItem } from '../../../../services/api/endpoints/productExtraItem';

interface IProps {
  uuid: string;
  getData: () => void;
}

const NewExtraItem: React.FC<IProps> = ({ uuid, getData }) => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (data: any) => {
    try {
      setLoading(true);
      const { path: image } = await fileUpload('image', data.image);
      await createExtraItem(uuid, {
        image,
        name: data.name,
        description: data.description,
        price: data.price * 100,
      });
      formRef.current.reset();
      getData();
      toast.success(SUCCESS_MESSAGE);
    } catch (err) {
      console.log(err);
      toast.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <Title level={3}>Novo Acessório</Title>
      <Divisor />
      <SingleUpload name="image" label="Imagem" />
      <Input
        name="name"
        label="Nome do Acessório"
        placeholder="Informe o nome do acessório"
      />
      <Textarea
        name="description"
        label="Descrição do Acessório"
        placeholder="Informe a descrição do acessório"
      />
      <Input
        name="price"
        label="Preço"
        placeholder="0,00"
        prefix="R$"
        type="number"
        step={0.01}
      />
      <Button type="submit" loading={loading}>
        Adicionar
      </Button>
    </Form>
  );
};

export default NewExtraItem;
