export const header = [
  {
    id: 1,
    label: 'Nome',
    key: 'name',
  },
  {
    id: 2,
    label: 'Cor',
    key: 'colorCode',
  },
  {
    id: 3,
    label: 'Excluir',
    key: 'delete',
  },
  {
    id: 4,
    label: 'Excluir Permanente',
    key: 'permanentlyDelete',
  },
];
